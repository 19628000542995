import React from 'react'
import { StyleSheet, View, ScrollView, Image } from 'react-native'

// libs
import { images } from 'app/src/libs/images'

// constants
import { isWeb } from 'app/src/constants/platform'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'

type ContainerProps = {}

type Props = {}

export const CompanyScreen: React.FC<ContainerProps> = () => {
  return <UI />
}

const UI: React.FC<Props & ContainerProps> = () => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.mainContent}>
            <Text type={'xxlarge'} align={'center'}>
              運営会社
            </Text>
            <Image style={styles.beeroLogo} source={images['beeroLogo']} />
            <View style={styles.info}>
              <View style={styles.row}>
                <Text type={'medium'} style={styles.title}>
                  会社名
                </Text>
                <Text type={'medium'} style={styles.data}>
                  株式会社beero
                </Text>
              </View>
              <View style={styles.row}>
                <Text type={'medium'} style={styles.title}>
                  役員
                </Text>
                <Text type={'medium'} style={styles.data}>
                  CEO 越島 悠介{'\n'}
                  COO 市村 昭宏{'\n'}CTO 為藤 アキラ
                </Text>
              </View>
              <View style={styles.row}>
                <Text type={'medium'} style={styles.title}>
                  所在地
                </Text>
                <Text type={'medium'} style={styles.data}>
                  〒107-0062{'\n'}
                  東京都港区南青山3丁目15-9{'\n'}MINOWA表参道3F{'\n'}03-6885-6140
                </Text>
              </View>
              <View style={styles.row}>
                <Text type={'medium'} style={styles.title}>
                  資本金
                </Text>
                <Text type={'medium'} style={styles.data}>
                  ¥1,000,000
                </Text>
              </View>
              <View style={styles.row}>
                <Text type={'medium'} style={styles.title}>
                  事業内容
                </Text>
                <Text type={'medium'} style={styles.data}>
                  サービス開発
                </Text>
              </View>
            </View>
          </View>
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  mainContent: {
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  beeroLogo: {
    marginTop: 38,
    marginBottom: 24,
    width: 189,
    height: 65,
  },
  info: {
    width: 250,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 29,
  },
  title: {
    width: 70,
    marginRight: 28,
    textAlign: 'right',
  },
  data: {
    width: 245,
  },
})
