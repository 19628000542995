import React, { useLayoutEffect, useRef } from 'react'
import { StyleSheet, View, Image } from 'react-native'
import Carousel, { Pagination } from 'react-native-x-carousel'

// libs
import { images } from 'app/src/libs/images'

// constants
import { black } from 'app/src/constants/colors'
import { windowWidth } from 'app/src/constants/layout'

// components
import { Text } from 'app/src/components/basics'

const item1 = [
  {
    id: 1,
    title: '自然のちからで、明日をひらく。',
    text: `人と自然がともに生き、赤ちゃんからお年寄りまでが美しく豊かな自然に囲まれ、笑顔で生活できる社会をつくることを目指して活動しているのが、日本自然保護協会です。\n
    1951年に設立され、日本の中では歴史あるNGO（Non Governmental Organization：非政府組織）となった私たちは、権力や勢力に左右されず、独立した組織として、透明性・公平性を維持し、科学的な根拠にもとづいて自然保護活動を行っています。`,
  },
  {
    id: 2,
    title: '注力している4つの活動',
    text: `そんな私達は以下の４つの活動に注力して活動しています。
    １．一度失うと二度と元には戻せないかけがえのない自然環境を守る活動
    ２．絶滅危惧種とその生息地を守る活動
    ３．自然の恵みを活かした地域づくりをすすめる活動
    ４．自然とのふれあいの機会や守り手を増やす活動`,
  },
  {
    id: 3,
    title: '絶滅危惧種の猛禽類「イヌワシ」の暮らす森を豊かにする活動',
    text: ` 日本自然保護協会では、群馬県みなかみ町に広がる「赤谷の森」を舞台に、絶滅危惧種の猛禽類イヌワシの保全活動や、手入れの行き届いていない人工林の森を生物多様性豊かな自然の森に戻していく活動などを実施しています。
    →詳しくは以下のサイトからご確認ください。
    ・イヌワシの保全活動
    https://www.nacsj.or.jp/sp-topics06/`,
  },
]

const item2 = [
  {
    id: 4,
    title: '豊かな森林のイメージ',
    text: `なぜ森林を保全するのか
    森林は、世界の陸地の約30％を占め、陸の生物の種の約80％が生息しています。このように、森林は生物多様性の保全に大きな役割を果しますが、加えて、雨水から表土を守ることによる土壌の流失の防止、土壌に水を貯えることによる洪水の緩和と水質の浄化、大気中の二酸化炭素の吸収と貯蔵による地球温暖化防止など、様々な働きを通じて人々の生活を支えています。木材や薪などの生活必需品を提供し、建材加工や家具製造など地域産業への原料供給も重要な役割です。この貴重な森林を将来の世代に引き継いでいくためには、国の内外を問わず、森林の保全や造成などに様々な取組が必要となっています。「緑の募金」はそのような取組を支援しています。`,
  },
  {
    id: 5,
    title: '途上国の人々のための植林',
    text: `世界では、年平均で300万ha（岩手県の面積の2倍程度）以上の森林が減少しています。減少の比率は減速傾向にありますが、2019年には世界各地の森林や草地などで大規模な火災が発生し、森林生態系への被害が懸念されています。また、森林の減少は、貧困問題などとも関係します。世界では16億人（全人口の2割以上）が森林に生計を依存しているといわれ、森林の保全とともに適切な利用が重要です。そこで、「緑の募金」は海外での森林保全や植林を支援しています。特に、地域住民に森林の重要性や植林の目的を理解いただき、植林には利用価値の高い樹種を選ぶなど、森林保全や植林とその後の適切な管理に住民が積極的に参加できる取組を推進しています。`,
  },
  {
    id: 6,
    title: '自然災害被災地の復旧支援',
    text: `我が国は、地形、地質及び気象などの自然条件から、台風、豪雨などによる災害が発生しやすい国土になっています。特に、戦中・戦後の森林の大量伐採の結果、森林は大きく荒廃し、台風等による大規模な山地災害が発生しました。そこで、幅広い国民の参加を得て森林の回復を図ることとなり、「緑の募金」もその時（1950年）に開始され、多くの皆様のご協力により、森林は回復しつつあります。しかし、近年の巨大台風や集中豪雨、さらには大規模な地震やそれに伴う津波により、森林を含め大きな被害を受けています。「緑の募金」は、自然災害被災地の森林の復旧や生活環境の改善、災害に強い健全な森林づくりなどの取組を支援しています。`,
  },
  {
    id: 7,
    title: 'こどもたちに森林の未来を託して',
    text: `樹木の成長には長い時間がかかります。例えば、海岸林の主要な樹種であるクロマツの場合、防災林としてある程度の機能が期待きるまでに20年は必要といわれ、いわゆる成木となるには50年以上必要です。しかし、適切な管理を継続すれば、数百年にわたって成長し持続的に機能を発揮します。このように、森林の保全や造成には、世代超えた努力が必要となります。そこで、次世代の担い手となるこどもたちに、森林の役割やその管理を学んでもらう取組が重要となり、「緑の募金」も支援しています。また、そのような取組で、森林の持続的な管理と利用を学んだこどもたちは、持続可能な開発目標（SDGs）の達成にも大きく貢献してくれるものと期待されます。`,
  },
]

const item3 = [
  {
    id: 8,
    title: '自然のちからで、明日をひらく。',
    text: `人と自然がともに生き、赤ちゃんからお年寄りまでが美しく豊かな自然に囲まれ、笑顔で生活できる社会をつくることを目指して活動しているのが、日本自然保護協会です。\n
    1951年に設立され、日本の中では歴史あるNGO（Non Governmental Organization：非政府組織）となった私たちは、権力や勢力に左右されず、独立した組織として、透明性・公平性を維持し、科学的な根拠にもとづいて自然保護活動を行っています。`,
  },
  {
    id: 9,
    title: '注力している4つの活動',
    text: `そんな私達は以下の４つの活動に注力して活動しています。
    １．一度失うと二度と元には戻せないかけがえのない自然環境を守る活動
    ２．絶滅危惧種とその生息地を守る活動
    ３．自然の恵みを活かした地域づくりをすすめる活動
    ４．自然とのふれあいの機会や守り手を増やす活動`,
  },
  {
    id: 10,
    title: '日本の海辺を自然を守る活動',
    text: `地球温暖化、砂浜のコンクリート化、大量の海ごみ、マイクロプラスチック…。島国日本の海にも危機が迫っています。日本自然保護協会では、日本の美しい砂浜や豊かな海を次の世代に残すため、様々な取り組みを実施しています。
    →詳しくはこちら
    ・全国砂浜ムーブメント
    https://www.nacsj.or.jp/sunahama_movement/
    ・ウミガメが産卵しやすい砂浜を守る活動
    https://www.nacsj.or.jp/2017/11/7001/`,
  },
]

const item4 = [
  {
    id: 11,
    title: '医療の届かないところに医療を届ける',
    text: `ジャパンハートは2004年、小児外科医の吉岡秀人によって国際医療ボランティア団体として設立された、日本発祥の国際医療NGOです。
    「医療の届かないところに医療を届ける」をミッションに掲げ、国、地域、人種、政治、宗教、境遇を問わず、すべての人たちが「生まれてきてよかった」と思える社会を実現するため、日本国内外で医療活動をはじめ教育、福祉、国際緊急救援など幅広い分野で活動を続けています。
    私たちの活動は、多くのボランティアの方、そして皆様からのご寄付によって支えられています。`,
  },
  {
    id: 12,
    title: '海外にて、無償で医療を提供し続けています',
    text: `海外では、貧困のため十分な治療が受けられない、十分な医療設備が整っていないなど様々な理由で継続的な治療を断念せざるを得ず、日本であれば助かるはずの命が失われている現実があります。
    そんな子どもたちの命と心を救うため、私たちは無償で治療や手術を行っています。私たちの海外活動地であるミャンマー・カンボジア・ラオスで実施する治療・手術の件数は年間約35,000件（2019年度実績）、活動16年では230,000件以上に上ります。
    医療者はもちろん、学生や社会人など多くの方がボランティアとして海外に渡航しているほか、日本の大学病院の医療チームが定期的に病院を訪れ、高度医療を提供してくれています。`,
  },
  {
    id: 13,
    title: 'カンボジアでは小児がんの治療に取り組んでいます',
    text: `私たちはカンボジアで長らく現地の病院と協力しながら医療活動を行ってきましたが、2016年に自前の病院「ジャパンハートこども医療センター」を建設し、2018年より小児がんの治療、手術を開始しました。
    2018年から現在までの2年間で81人（2020年8月時点）の小児がんの子どもたちを受け入れて手術や治療を行っており、今までカンボジアでは救うことができなかった多くの子どもたちの命を救うことができるようになりました。
    カンボジアだけにとどまらず、将来的にはアジア全土から子どもたちを受け入れ、今まで治療が叶わず命を落としていた子どもたちの命を救い、子どもたちの未来を支える活動を目指しています。`,
  },
  {
    id: 14,
    title: '多くの子どもたちの「命」と「心」を救うために',
    text: `ミャンマーやラオスでも、様々な疾患で治療に訪れる患者に無償で医療を提供し、治療に日々取り組んでいます。
    奇形や火傷などが原因で人前に出ることをためらい、目向きな気持ちで生活することができなかった患者や家族が、ジャパンハートの病院で治療を受けることで笑顔を取り戻し、人生を前向きに歩んでいけるようになる姿は、私たちにとって何にも代えがたい喜びです。
    治療や手術によって「命」を救うことはもちろん、患者や家族の「心」を救う医療を提供することを、私たちは何よりも大切にしています。`,
  },
  {
    id: 15,
    title: '多くの子どもたちの「命」と「心」を救うために',
    text: `ミャンマーやラオスでも、様々な疾患で治療に訪れる患者に無償で医療を提供し、治療に日々取り組んでいます。
    奇形や火傷などが原因で人前に出ることをためらい、目向きな気持ちで生活することができなかった患者や家族が、ジャパンハートの病院で治療を受けることで笑顔を取り戻し、人生を前向きに歩んでいけるようになる姿は、私たちにとって何にも代えがたい喜びです。
    治療や手術によって「命」を救うことはもちろん、患者や家族の「心」を救う医療を提供することを、私たちは何よりも大切にしています。`,
  },
]

const item5 = [
  {
    id: 16,
    title: '団体概要',
    text: `「uminari = 海の声」の代弁者として、Z世代を中心とする若者２９人（２０２０年１１月時点）で海洋プラスチックごみ問題に取り組むNPO法人です。
    消費者と生産者、上の世代と下の世代など、あらゆるセクターの間に立ち、ポジティブな対話と解決に向けた共創を生み出しています。
    一つの問題の解決が別の問題を生み出す「イシュー・リンケージ」を起こさないよう、短絡的なキャンペーンではなく、他の環境問題やサステナビリティ領域全般、またマクロ経済や人の心など、幅広く「全体性と関係性」を捉えた上での本質的なアプローチを目指しています。
    海外のトレンドをそのまま輸入するのではなく、「日本性」を大切にし、日本に合った解決を組み立てることを常に意識しています。`,
  },
  {
    id: 17,
    title: 'ビーチクリーン事業',
    text: `「海の囁きを人々の生活に」をコンセプトに、海の声を肌で感じ、海を想う気持ちや仲間の結目(ゆいめ)、そして行動を創り出すことを目指します。
    問題を頭で理解するだけでなく、海の声を肌で感じ、生まれた価値観を仲間と共有し、陸での生活に馴染ませられるようなコミュニティ創りを行います。
    これまで、神奈川、千葉、大分、沖縄の四箇所で「拾う以上のクリーンアップ活動」を行ってきました。２０２１年度はさらに地域を展開し、年間１００回のビーチクリーン活動を目指します。
    また、海を想い寄り添う価値観、「umiomoi（海想い）」を醸成・発信し、海でゴミを拾うだけでない根本的なアプローチを促進します。`,
  },
  {
    id: 18,
    title: 'エデュケーション事業',
    text: `「学び・語り・紡ぐ。」をコンセプトに、幅広い世代に海の問題への解決者を生み出すことを目指します。
    短絡的な答えを押し付けるのではなく、学生から企業、自治体やその他のセクターまでが共に本質を追求するプラットフォームとして、解決者を生み出し、繋ぎ、共創を促進しています。
    １　学生から企業、その他のセクターまでが参加する相互性のあるゼミ（毎月開催）、 『u-semi.』、
    ２　小学校から大学まで幅広い世代に対し正規の授業を用いて行う教育プロジェクト、『出張授業』、
    ３　企業や自治体などを中心とするセクターに対し行う啓発プロジェクト、『講演』
    の３つのプロジェクトを中心に行っています。
    `,
  },
  {
    id: 19,
    title: 'ライフスタイルデザイン事業',
    text: `「探求・可視化・共有。」をコンセプトに、自然を傷つけず、かつ心満たされる豊かさとライフスタイルをデザインすることを目指します。
    一つの答えや世界のトレンドを押し付けるのではなく、日本の生活・文化・習慣に合う解決策をみんなで探求し、可視化し、共有できるような場を創り上げます。
    主に『生活塾』というプロジェクトを通し、一般的にはエコやサステナビリティといった枠組みでは括られていないような価値観や文化、習慣なども含め、新たなライフスタイルをデザインし得るヒントを丁寧に汲み上げオンラインで配信します。また、配信したテーマに沿ったワークショップを定期的に開催し、実際に一人一人の生活に落とし込める形をみんなで探求します。`,
  },
  {
    id: 20,
    title: '実績等',
    text: `　・UNEP Forum ２０１９ 登壇（２０１９年５月）
    ・サステナブルブランド国際会議２０２０横浜　オープニングステージ登壇（２０２０年２月）
    ・エコプロ２０１９　ステージコーディネーター担当・登壇（２０１９年１２月）
    ・Peace Day ２０２０　登壇（２０２０年９月）
    ・国連機関、UNEP―NOWPAPとの協働事業実施（２０１８年１０月）
    
    他
    `,
  },
]

type Props = {
  type: number
  setRef: (type: number, ref: React.RefObject<any>) => void
}

export const NpoCarousel: React.FC<Props> = ({ type, setRef }) => {
  const ref = useRef(null)

  useLayoutEffect(() => {
    //特定の提携団体へスクロール
    setRef(type, ref)
  }, [setRef, type, ref])

  const areaItem = () => {
    //森林保護
    if (type === 1) return item1
    if (type === 2) return item2
    //海洋保護
    if (type === 3) return item3
    if (type === 5) return item5
    //医療・災害
    if (type === 4) return item4
  }

  const renderItem = (data, index) => {
    return (
      <View key={index} style={styles.contents} ref={ref}>
        <Image style={styles.npoHeaderImage} source={images[`npoHeaderImage${data.id}`]} />
        <Text style={styles.count} type={'small'}>
          {index + 1} / {areaItem()?.length}
        </Text>
        <Text style={styles.title}>{data.title}</Text>
        <Text style={styles.text} type={'small'}>
          {data.text}
        </Text>
      </View>
    )
  }

  return <Carousel pagination={Pagination} renderItem={renderItem} data={areaItem()} />
}

const styles = StyleSheet.create({
  contents: {
    alignItems: 'center',
  },
  npoHeaderImage: {
    width: windowWidth,
    height: windowWidth * 1.2,
    resizeMode: 'contain',
  },
  count: {
    width: 312,
    marginBottom: 10,
    color: black,
  },
  title: {
    width: 312,
    fontWeight: 'bold',
  },
  text: {
    width: 312,
    marginTop: 14,
    marginBottom: 46,
    color: black,
  },
})
