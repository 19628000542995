import React from 'react'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'
import { BlurView } from 'expo-blur'

// libs
import { images } from 'app/src/libs/images'
import { goToActionDetail } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { white } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'

// models
import { ActionCard as ActionCardModel } from 'app/src/models/action-card'

type Props = {
  action: ActionCardModel
  isHorizontal?: boolean
  boxShadow?: boolean
}

export const ActionCard: React.FC<Props> = ({ action, isHorizontal = false, boxShadow = false }) => {
  const navigation = useNavigation()
  const { id, title, deliveryDate, deliveryTime, possibleViewerNumber, entryAmount, profitAmount, imageFileUrl } = action

  return (
    <TouchableOpacity onPress={() => goToActionDetail(navigation, id)}>
      <View style={styles.actionCardContainer}>
        {!isHorizontal ? (
          <View style={styles.actionCardImage} pointerEvents="box-none">
            <Image style={boxShadow ? styles.cardImageBackShadow : styles.cardImageBack} source={{ uri: imageFileUrl }} />
            <BlurView tint="dark" intensity={50} style={styles.blurView} />
            <View style={styles.blurImage} pointerEvents="box-none">
              <Image style={styles.cardImageFront} source={{ uri: imageFileUrl }} />
            </View>
            <View style={styles.cardInfo}>
              {/* 聖奈さん */}
              {title === 'オンライン食事指導🍽 【8週間コース】' && <Image style={styles.userIcon} source={images['userIconSeina']} />}
              {/* Sahoさん */}
              {(title === 'オンラインメニュー指導（3ヶ月）' || title === 'ランニングコーチ（パーソナルレッスン）') && (
                <Image style={styles.userIcon} source={images['userIconSaho']} />
              )}
              <Text style={styles.actionTitle}>{title ? title : 'タイトル'}</Text>
              <Text style={styles.actionDate}>
                {deliveryDate ? deliveryDate : '2020/00/00（月）12:00〜'} {deliveryTime}
              </Text>
              <View style={styles.other}>
                <View>
                  <Text type={'small'} style={styles.title}>
                    人数
                  </Text>
                  <Text type={'large'} font={'quick-r'} style={styles.data}>
                    {possibleViewerNumber === '制限なし' ? '∞' : possibleViewerNumber}
                  </Text>
                </View>
                <View>
                  <Text type={'small'} style={styles.title}>
                    参加費
                  </Text>
                  <View style={styles.amountWrapper}>
                    <Text type={'large'} font={'quick-r'} style={styles.data}>
                      ¥{entryAmount && entryAmount.toLocaleString()}
                    </Text>
                    <Text font={'quick-r'} style={styles.data}>
                      （内、支援¥{profitAmount && profitAmount.toLocaleString()}）
                    </Text>
                  </View>
                </View>
                {/* <View>
                  <Text type={'small'} style={styles.title}>
                    フリー視聴
                  </Text>
                  <Text type={'large'} font={'quick-r'} style={styles.data}>
                    5min
                  </Text>
                </View>
                <View>
                  <Text type={'small'} style={styles.title}>
                    参加費
                  </Text>
                  <Text type={'large'} font={'quick-r'} style={styles.data}>
                    {entryAmount ? `¥${entryAmount}` : '¥0'}
                  </Text>
                </View>
                <View>
                  <Text type={'small'} style={styles.title}>
                    リターン
                  </Text>
                  <Text type={'large'} font={'quick-r'} style={styles.data}>
                    {profitAmount ? `¥${profitAmount}` : '¥0'}
                  </Text>
                </View>
                <View>
                  <Text type={'small'} style={styles.title}>
                    人数
                  </Text>
                  <Text type={'large'} font={'quick-r'} style={styles.data}>
                    {possibleViewerNumber ? possibleViewerNumber : 0}
                  </Text>
                </View> */}
              </View>
            </View>
          </View>
        ) : (
          <View style={styles.actionCardImageSide} pointerEvents="box-none">
            <Image style={styles.cardImageSideBack} source={{ uri: imageFileUrl }} />
            <BlurView tint="dark" intensity={50} style={styles.blurSideView} />
            <View style={styles.blurSideImage} pointerEvents="box-none">
              <Image style={styles.cardImageSideFront} source={{ uri: imageFileUrl }} />
            </View>
            <View style={styles.cardInfoSide}>
              {/* 聖奈さん */}
              {title === 'オンライン食事指導🍽 【8週間コース】' && <Image style={styles.userIconSide} source={images['userIconSeina']} />}
              {/* Sahoさん */}
              {(title === 'オンラインメニュー指導（3ヶ月）' || title === 'ランニングコーチ（パーソナルレッスン）') && (
                <Image style={styles.userIconSide} source={images['userIconSaho']} />
              )}
              <Text type={'small'} style={styles.actionTitleSide}>
                {title ? title : 'タイトル'}
              </Text>
              <View style={styles.otherSide}>
                <View style={styles.actionDates}>
                  <Text type={'small'} style={styles.actionDateSide} align="center">
                    {deliveryDate ? deliveryDate : '2020/00/00（月）12:00〜'}
                  </Text>
                  <Text type={'small'} style={styles.actionTimeSide}>
                    {deliveryTime}
                  </Text>
                </View>
                <View style={styles.borderRight}></View>
                <View style={styles.otherSideContents}>
                  <View style={styles.otherSideitem}>
                    <Text type={'small'} style={styles.titleSide}>
                      参加費
                    </Text>
                    <Text type={'medium'} font={'quick-r'} style={styles.data}>
                      ¥{entryAmount && entryAmount.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.otherSideitem}>
                    <Text type={'small'} style={styles.titleSide}>
                      支援金
                    </Text>
                    <Text type={'medium'} font={'quick-r'} style={styles.data}>
                      ¥{profitAmount && profitAmount.toLocaleString()}
                    </Text>
                  </View>
                  <View style={styles.otherSideitem}>
                    <Text type={'small'} style={styles.titleSide}>
                      人数
                    </Text>
                    <Text type={'medium'} font={'quick-r'} style={styles.dataSide}>
                      {possibleViewerNumber === '制限なし' ? '∞' : possibleViewerNumber}
                    </Text>
                  </View>
                </View>
                {/* <View style={styles.otherSideitem}>
                  <Text type={'small'} style={styles.titleSide}>
                    フリー視聴
                  </Text>
                  <Text type={'medium'} font={'quick-r'} style={styles.dataSide}>
                    5min
                  </Text>
                </View>
                <View style={styles.otherSideitem}>
                  <Text type={'small'} style={styles.titleSide}>
                    参加費
                  </Text>
                  <Text type={'medium'} font={'quick-r'} style={styles.dataSide}>
                    {entryAmount ? `¥${entryAmount}` : '¥0'}
                  </Text>
                </View>
                <View style={styles.otherSideitem}>
                  <Text type={'small'} style={styles.titleSide}>
                    リターン
                  </Text>
                  <Text type={'medium'} font={'quick-r'} style={styles.dataSide}>
                    {profitAmount ? `¥${profitAmount}` : '¥0'}
                  </Text>
                </View>
                <View style={styles.otherSideitem}>
                  <Text type={'small'} style={styles.titleSide}>
                    人数
                  </Text>
                  <Text type={'medium'} font={'quick-r'} style={styles.dataSide}>
                    {possibleViewerNumber ? possibleViewerNumber : 0}
                  </Text>
                </View> */}
              </View>
            </View>
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  actionCardContainer: {
    justifyContent: 'center',
  },
  actionCardImage: {
    width: 250,
    height: 450,
    marginBottom: 45,
    position: 'relative',
  },
  cardImageBack: {
    position: 'absolute',
    width: 250,
    height: 450,
    borderRadius: 20,
  },
  cardImageBackShadow: {
    position: 'absolute',
    width: 250,
    height: 450,
    borderRadius: 20,
    boxShadow: '3px 8px 20px rgba(0, 0, 0, 0.2)',
  },
  blurImage: {
    position: 'absolute',
    top: 0,
    left: -220,
    width: 560,
    height: 328,
    borderBottomLeftRadius: 285,
    borderBottomRightRadius: 285,
    overflow: 'hidden',
  },
  cardImageFront: {
    borderRadius: 20,
    position: 'absolute',
    width: 250,
    height: 450,
    top: 0,
    right: 90,
  },
  blurView: {
    position: 'absolute',
    width: 250,
    height: 350,
    bottom: 0,
    borderRadius: 20,
  },
  cardInfo: {
    width: 250,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  userIcon: {
    position: 'absolute',
    right: 13,
    bottom: 130,
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  actionTitle: {
    marginLeft: 14,
    color: white,
    fontWeight: 'bold',
  },
  actionDate: {
    color: white,
    marginBottom: 8,
    marginLeft: 14,
  },
  other: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: 14,
    marginBottom: 17,
    width: 200,
  },
  title: {
    textAlign: 'center',
    fontSize: 9,
    color: white,
  },
  data: {
    color: white,
    textAlign: 'center',
  },
  amountWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },

  // 横バージョンのアクションカード
  actionCardImageSide: {
    marginTop: 10,
    width: 325,
    height: 210,
    marginBottom: 5,
    position: 'relative',
  },
  cardImageSideBack: {
    position: 'absolute',
    width: 325,
    height: 210,
    borderRadius: 20,
  },
  blurSideView: {
    position: 'absolute',
    width: 325,
    height: 180,
    bottom: 0,
    borderRadius: 20,
  },
  blurSideImage: {
    position: 'absolute',
    bottom: 80,
    left: -300,
    width: 800,
    height: 380,
    borderBottomLeftRadius: 400,
    borderBottomRightRadius: 400,
    overflow: 'hidden',
    // backgroundColor: 'blue',
  },
  cardImageSideFront: {
    borderRadius: 20,
    position: 'absolute',
    width: 325,
    height: 210,
    bottom: -70,
    left: 300,
  },
  cardInfoSide: {
    height: 70,
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  userIconSide: {
    position: 'absolute',
    right: -55,
    bottom: 83,
    width: 50,
    height: 50,
    resizeMode: 'contain',
  },
  actionTitleSide: {
    marginLeft: 8,
    color: white,
    fontWeight: 'bold',
  },
  actionDates: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 13,
    marginTop: 5,
  },
  actionDateSide: {
    color: white,
    marginBottom: -5,
    width: 91,
  },
  actionTimeSide: {
    color: white,
  },
  borderRight: {
    marginTop: 3,
    marginRight: 13,
    borderRightColor: white,
    borderRightWidth: 1,
    height: 32,
  },
  otherSide: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // marginTop: 8,
    marginLeft: 13,
  },
  otherSideContents: {
    width: '100%',
    flexDirection: 'row',
    // justifyContent: 'space-around',
    maxWidth: 150,
  },
  otherSideitem: {
    marginRight: 13,
  },
  titleSide: {
    textAlign: 'center',
    fontSize: 8,
    color: white,
    marginTop: 3,
    marginBottom: -3,
  },
  dataSide: {
    color: white,
    textAlign: 'center',
    fontWeight: '500',
  },
})
