import React, { useState, SetStateAction } from 'react'

import { View, StyleSheet, Image, TouchableOpacity } from 'react-native'
import { BlurView } from 'expo-blur'

// libs
import { images } from 'app/src/libs/images'

// constants
import { white, borderLightGrayColor } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Button } from 'app/src/components/basics'
import { TextInput } from 'app/src/components/basics/text-input'
import { SelectBox } from 'app/src/components/basics/selectbox'

interface Props {
  setOpen: React.Dispatch<SetStateAction<boolean>>
}

const amountItems = [10, 100, 1000, 10000]

export const PortfolioModal: React.FC<Props> = ({ setOpen }) => {
  const [validation, setValidation] = useState('')
  const [donation, setDonation] = useState<number>()
  const [selectedValue, setSelectedValue] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const donationValidation = (donation: string): string => {
    const regex = new RegExp(/^[0-9]+$/)
    if (!regex.test(donation)) return '半角数字で入力してください。'
    return ''
  }

  const handleChange = (value: string): void => {
    setValidation(donationValidation(value))
    setDonation(parseInt(value))
  }

  const handleAmount = (amount: number): void => {
    setDonation(prevDonation => {
      if (prevDonation) {
        const newDonation = prevDonation + amount
        return newDonation
      } else {
        return amount
      }
    })
  }

  const onChange = (value: any): void => {
    setSelectedValue(value)
  }

  return (
    <BlurView style={styles.blurView} tint={'default'} intensity={20}>
      <View style={styles.container}>
        <TouchableOpacity style={styles.closeBtn} onPress={handleClose}>
          <Image style={styles.closeIcon} source={images['iconClose']} />
        </TouchableOpacity>
        <View style={styles.titleWrapper}>
          <Text type={'xxlarge'}>いくら贈りますか？</Text>
        </View>
        <TextInput
          label={'ドネイト金額'}
          onChangeText={text => handleChange(text)}
          value={donation}
          placeholder={'¥0'}
          validation={validation}
          keyboardType={'numeric'}
        />
        <View style={styles.itemBtns}>
          {amountItems.map((amount, index) => (
            <Text type={'xlarge'} onPress={() => handleAmount(amount)} key={index} style={styles.item}>
              +{amount.toLocaleString()}
            </Text>
          ))}
        </View>
        <View style={styles.selectWrapper}>
          <SelectBox
            label={'送付先'}
            value={selectedValue}
            onValueChange={text => onChange(text)}
            placeholder={{ label: '選択してください', value: undefined }}
            items={[
              { value: undefined, label: '森林保護' },
              { value: 'Forest Stewardship Council', label: 'Forest Stewardship Council' },
              { value: '日本自然保護協会', label: '日本自然保護協会' },
              { value: '日本砂漠緑化実践協会', label: '日本砂漠緑化実践協会' },
              { value: undefined, label: '海洋保護' },
              { value: 'The Ocean Cleanup', label: 'The Ocean Cleanup' },
              { value: 'Ocean Conservancy', label: 'Ocean Conservancy' },
              { value: '美ら海振興会', label: '美ら海振興会' },
              { value: undefined, label: '動物保護' },
              { value: 'WWF (世界自然保護基金)', label: 'WWF (世界自然保護基金)' },
            ]}
          />
        </View>
        <View style={styles.line} />
        <Button buttonType={4} onPress={() => {}}>
          ドネイトする
        </Button>
      </View>
    </BlurView>
  )
}

const styles = StyleSheet.create({
  blurView: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    alignItems: 'center',
    zIndex: 1,
  },
  container: {
    flex: 1,
    position: 'absolute',
    top: 34,
    width: 355,
    height: 560,
    backgroundColor: white,
    // webのみ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
  },
  closeBtn: {
    position: 'absolute',
    top: 18,
    right: 14,
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  titleWrapper: {
    marginTop: 50,
    marginBottom: 27,
  },
  selectWrapper: {
    marginTop: 23,
  },
  item: {
    borderWidth: 1,
    borderColor: borderLightGrayColor,
    borderRadius: 10,
    marginVertical: 10,
    marginHorizontal: 5,
    padding: 15,
  },
  itemBtns: {
    flexDirection: 'row',
  },
  line: {
    height: 1,
    width: 345,
    backgroundColor: borderLightGrayColor,
    marginVertical: 20,
  },
})
