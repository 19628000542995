import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Image, ScrollView } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

// libs
import withdrawalHistoryData from 'app/src/static/withdrawalHistoryList.json'

// constants
import { borderLightGrayColor } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Footer } from 'app/src/components/advanced/footer'

type Props = {
  history: any
}

type withdrawalHistory = {
  bankName: string
  amount: number
  date: string
}

export const PortfolioWithdrawalHistoryList: React.FC<Props> = () => {
  const [historyData, setHistoryData] = useState<withdrawalHistory[]>([])
  useEffect(() => {
    setHistoryData(withdrawalHistoryData)
  }, [])

  return (
    <LinearGradient colors={['#FAFAFA', '#F4F4F4']} style={styles.portfolioListContainer}>
      {historyData.map((item, index) => {
        return (
          <View key={index}>
            <View style={styles.contents}>
              <Text type={'medium'} style={styles.bankName}>
                {item.bankName}
              </Text>
              <Text type={'medium'} style={styles.amount}>
                ¥{item.amount}
              </Text>
              <Text type={'small'} style={styles.date}>
                {item.date}
              </Text>
            </View>
          </View>
        )
      })}
      <Footer />
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  portfolioListContainer: {
    paddingTop: 15,
    alignItems: 'center',
  },
  contents: {
    width: 355,
    padding: 10,
    borderBottomWidth: 2,
    borderBottomColor: borderLightGrayColor,
  },
  thumbnail: {
    width: 60,
    height: 60,
    marginRight: 12,
  },
  bankName: {
    width: 260,
    lineHeight: 15,
    marginBottom: 3,
    fontWeight: '700',
  },
  amount: {
    marginBottom: 13,
    lineHeight: 15,
  },
  date: {
    fontWeight: '700',
    lineHeight: 15,
  },
})
