import React from 'react'
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native'

// constants
import { white } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { LiveUserList } from 'app/src/components/advanced/live/live-user-list'

//libs
import { images } from 'app/src/libs/images'

type Props = {
  setVisibleShareModal: (boolean) => void
}

// ユーザーリストでindexを使用するためのサンプルデータ
const liveUsersSample = [{ userImage: 'userImage' }, { userImage: 'userImage' }, { userImage: 'userImage' }]

export const ActionLive: React.FC<Props> = ({ setVisibleShareModal }) => {
  return (
    <>
      <View style={styles.actionLiveHeader}>
        <Text style={styles.actionTitleText}>オンラインピラティスクラス by 横田桃子！</Text>
        <LiveUserList liveUsers={liveUsersSample} />
        <View style={styles.LiveviewCountBar}>
          <Image style={styles.liveviewIconImage} source={images['iconLiveviewCount']} />
          <Text style={styles.liveviewCountText}>0</Text>
        </View>
      </View>
      <TouchableOpacity style={styles.shareButton} onPress={() => setVisibleShareModal(true)}>
        <Image style={styles.shareImage} source={images['iconShare']} />
      </TouchableOpacity>
    </>
  )
}

const styles = StyleSheet.create({
  actionLiveHeader: {
    paddingVertical: 5,
    paddingHorizontal: 10,
    position: 'absolute',
    top: 0,
  },
  actionTitleText: {
    color: '#656565',
    fontWeight: 'bold',
  },
  LiveviewCountBar: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderRadius: 14,
  },
  liveviewIconImage: {
    width: 14,
    height: 14,
    resizeMode: 'contain',
    marginLeft: 8,
    marginRight: 7.5,
  },
  liveviewCountText: {
    color: '#656565',
    marginRight: 10,
  },
  shareButton: {
    backgroundColor: 'transparent',
    width: 39,
    height: 39,
    borderRadius: 39 / 2,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 14,
    right: 11,
    zIndex: 10,
  },
  shareImage: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
})
