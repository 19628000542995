import React from 'react'
import { StyleSheet, ImageBackground } from 'react-native'

// libs
import { images } from 'app/src/libs/images'

export const CountdownTimer: React.FC = ({ children }) => {
  return (
    <ImageBackground style={styles.countdownTimerContainer} source={images['liveEllipse']}>
      {children}
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  countdownTimerContainer: {
    width: 175,
    height: 175,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
