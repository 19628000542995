import React, { useState, useEffect } from 'react'
import { View, StyleSheet, Image, ScrollView } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

// libs
import returnHistoryData from 'app/src/static/return-history-data.json'

// constants
import { borderLightGrayColor } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Footer } from 'app/src/components/advanced/footer'

type Props = {
  history: any
}

type returnHistory = {
  title: string
  entryAmount: number
  deliveryDate: string
  deliveryTime: string
  imageFileUrl: string
}

export const PortfolioReturnHistoryList: React.FC<Props> = () => {
  const [historyData, setHistoryData] = useState<returnHistory[]>([])
  useEffect(() => {
    setHistoryData(returnHistoryData)
  }, [])

  return (
    <LinearGradient colors={['#FAFAFA', '#F4F4F4']} style={styles.portfolioListContainer}>
      {historyData.map((item, index) => {
        return (
          <View key={index}>
            <View style={styles.container}>
              {/* <Image style={styles.thumbnail} source={require(`../../../assets/images/${item.imageFileUrl}.png`)} /> */}
              <View style={styles.contents}>
                <Text type={'medium'} style={styles.title}>
                  {item.title}
                </Text>
                <Text type={'medium'} style={styles.entryAmount}>
                  参加費から - ¥{item.entryAmount}
                </Text>
                <View style={styles.dateDelivery}>
                  <Text type={'small'} style={styles.deliveryDate}>
                    {item.deliveryDate}
                  </Text>
                  <Text type={'small'} style={styles.deliveryTime}>
                    {item.deliveryTime}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )
      })}
      <Footer />
    </LinearGradient>
  )
}

const styles = StyleSheet.create({
  portfolioListContainer: {
    paddingTop: 15,
    alignItems: 'center',
  },
  container: {
    width: 355,
    flexDirection: 'row',
    padding: 10,
    borderBottomWidth: 2,
    borderBottomColor: borderLightGrayColor,
  },
  thumbnail: {
    width: 60,
    height: 60,
    marginRight: 12,
  },
  contents: {},
  title: {
    width: 260,
    lineHeight: 15,
    marginBottom: 3,
  },
  entryAmount: {
    fontWeight: '700',
    marginBottom: 13,
    lineHeight: 15,
  },
  dateDelivery: {
    flexDirection: 'row',
  },
  deliveryDate: {
    fontWeight: '700',
    lineHeight: 15,
  },
  deliveryTime: {
    fontWeight: '700',
    lineHeight: 15,
  },
})
