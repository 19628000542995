import React, { useEffect, useState } from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'
import { useParams } from 'app/src/hooks/use-params'

// constants
import { isWeb } from 'app/src/constants/platform'
import { black } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { Footer } from 'app/src/components/advanced/footer'
import { ShareList } from 'app/src/components/advanced/share-list'
import { ActionCard } from 'app/src/components/advanced/action-card'

//models
import { ActionCard as ActionCardModel } from 'app/src/models/action-card'
import firebase from 'firebase'

type ContainerProps = {}

type Props = {
  navigation: any
  action: ActionCardModel
  actionId: string
}

export const PaymentFinishScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()
  const params = useParams()

  const [action, setAction] = useState<any>([])
  const { id: actionId } = params

  const _fetchActions = async () => {
    const actions = await firebase
      .firestore()
      .collection(`actions`)
      .get()
      .then(qs =>
        qs.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        }),
      )
    actions.map((item: ActionCardModel) => {
      if (item.id === params.actionId) setAction(item)
    })
  }

  useEffect(() => {
    _fetchActions()
  }, [])

  return <UI navigation={navigation} action={action} actionId={actionId} />
}

const UI: React.FC<Props & ContainerProps> = ({ navigation, action, actionId }) => {
  return (
    <View style={styles.container}>
      {isWeb && <Header />}
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <Text type={'xlarge'} style={styles.pageName} align={'center'}>
          決済完了です！
        </Text>
        <View style={styles.actionImage}>
          <ActionCard action={action} />
        </View>
        <Text>当日、アクション10分前になりましたら、メールにてお知らせいたします。それではアクション開始まで楽しみに、お待ち下さい！</Text>
        <View style={styles.shareList}>
          <ShareList isShownDownload={false} />
        </View>
        <Text type={'small'} align={'center'}>
          このアクションをシェア
        </Text>
      </ScrollView>
      <Footer />
      {/* {isWeb && <TabBar />} */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  scrollContainer: {
    flex: 1,
    marginBottom: 110,
  },
  scrollContents: {
    justifyContent: 'center',
    marginHorizontal: 28,
  },
  pageName: {
    fontSize: 16,
    color: black,
    marginTop: 72,
    marginBottom: 24,
  },
  actionImage: {
    width: 225,
    height: 420,
    marginHorizontal: 25,
    marginTop: 20,
    marginBottom: 50,
  },
  shareList: {
    marginTop: 30,
    marginBottom: 12,
  },
})
