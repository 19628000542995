import React from 'react'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'

// libs
import { images } from 'app/src/libs/images'

// components
import { Text } from 'app/src/components/basics'

type Props = {
  isShowUserName?: boolean
  userInfo: any
  preImageUrl?: string
  onPress?: () => void
  editIconImage?: string
}

export const UserIconEdit: React.FC<Props> = ({ isShowUserName = true, userInfo, preImageUrl, onPress, editIconImage = 'iconEdit' }) => {
  return (
    <View style={styles.userInfoContainer}>
      {userInfo && (
        <>
          <View style={styles.iconContainer}>
            {userInfo.imageUrl || preImageUrl ? (
              <Image style={styles.userIcon} source={{ uri: preImageUrl ? preImageUrl : userInfo.imageUrl }} />
            ) : (
              <Image style={styles.userIconDefault} source={images['userIconDefault']} />
            )}
            <TouchableOpacity onPress={onPress}>
              <Image style={styles.editIcon} source={images[editIconImage]} />
            </TouchableOpacity>
          </View>
          {isShowUserName && (
            <View style={styles.nameContainer}>
              <Text type={'medium'}>{userInfo.nickname}</Text>
            </View>
          )}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  userInfoContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 230,
  },
  iconContainer: {
    width: 130,
    height: 130,
    position: 'relative',
  },
  userIcon: {
    width: 120,
    height: 120,
    borderRadius: 120 / 2,
  },
  userIconDefault: {
    width: 120,
    height: 120,
    resizeMode: 'contain',
  },
  editIcon: {
    width: 24,
    height: 24,
    position: 'absolute',
    right: 7,
    bottom: 14,
    resizeMode: 'contain',
  },
  nameContainer: {
    marginTop: 10,
  },
})
