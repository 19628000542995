import React from 'react'
import { StyleSheet, View, TouchableOpacity, Image, ScrollView, Clipboard } from 'react-native'
import Modal from 'react-native-modal'

// libs
import { images } from 'app/src/libs/images'
import { LinkingOpenURL } from 'app/src/libs/linking'
import { HeadTag } from 'app/src/components/advanced/head-tag'

// components
import { isWeb } from 'app/src/constants/platform'
import { white } from 'app/src/constants/colors'

// components
import { ScrollToTop } from 'app/src/components/apps/scroll-to-top'

type Props = {
  visibleShareModal: boolean
  setVisibleShareModal: (boolean) => void
  title: string
  actionId: string
  description: string
  imageFileUrl: string
}

const ShareModalContent = (setVisibleShareModal, imageFileUrl, facebookSharingUrl, twitterSharingUrl, lineSharingUrl, copyShareUrl) => (
  <View style={styles.modalContent}>
    <TouchableOpacity style={styles.closeContent} onPress={() => setVisibleShareModal(false)}>
      <Image style={styles.closeImage} source={images['iconClose']} />
    </TouchableOpacity>
    <View style={styles.shareTextContent}>
      <Image style={styles.shareImage} source={images['ttlShare']} />
    </View>

    <Image style={styles.liveShareImage} source={{ uri: imageFileUrl }} />

    <View style={styles.snsBar}>
      <TouchableOpacity onPress={() => LinkingOpenURL(facebookSharingUrl)}>
        <Image style={styles.snsImage} source={images['iconFb']} />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => LinkingOpenURL(twitterSharingUrl)}>
        <Image style={styles.snsImage} source={images['iconTwL']} />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => LinkingOpenURL(lineSharingUrl)}>
        <Image style={styles.snsImage} source={images['iconLine']} />
      </TouchableOpacity>
    </View>

    <TouchableOpacity onPress={() => copyShareUrl()}>
      <Image style={styles.urlCopyImage} source={images['btnUrlCopy']} />
    </TouchableOpacity>
  </View>
)

export const ShareModal: React.FC<Props> = ({ visibleShareModal, setVisibleShareModal, title, actionId, imageFileUrl, description }) => {
  const actionDetailUrl = `https://megu.green/action/detail?id=${actionId}`

  const facebookSharingUrl = `https://www.facebook.com/sharer/sharer.php?u=${actionDetailUrl}`
  const twitterSharingUrl = `https://twitter.com/intent/tweet?text=${title}%0a&url=${actionDetailUrl}`
  const lineSharingUrl = `http://line.me/R/msg/text/?${title}%0D%0A${actionDetailUrl}`
  const copyShareUrl = () => {
    Clipboard.setString(actionDetailUrl)
    alert('URLをコピーしました')
  }

  return visibleShareModal ? (
    <>
      {isWeb ? (
        <View style={styles.webModalContainer}>
          <HeadTag title={`megu | ${title}`} description={description.replace(/\\n/g, '').slice(0, 120)} imageUrl={imageFileUrl} />
          <ScrollToTop />
          <ScrollView>
            <View style={styles.webModalContentMargin}>
              {ShareModalContent(setVisibleShareModal, imageFileUrl, facebookSharingUrl, twitterSharingUrl, lineSharingUrl, copyShareUrl)}
            </View>
          </ScrollView>
        </View>
      ) : (
        <ScrollView>
          <Modal isVisible={visibleShareModal}>
            {ShareModalContent(setVisibleShareModal, imageFileUrl, facebookSharingUrl, twitterSharingUrl, lineSharingUrl, copyShareUrl)}
          </Modal>
        </ScrollView>
      )}
    </>
  ) : null
}

const styles = StyleSheet.create({
  webModalContainer: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  webModalContentMargin: {
    marginTop: 40,
    marginBottom: 40,
    marginLeft: 20,
    marginRight: 20,
  },
  modalContent: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: white,
    height: 590,
    padding: 30,
    borderRadius: 10,
  },
  closeContent: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  closeImage: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
  },
  shareTextContent: {
    marginBottom: 10,
  },
  shareImage: {
    width: 79,
    height: 20,
    resizeMode: 'contain',
  },
  liveShareImage: {
    width: 270,
    height: 400,
  },
  snsBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 207,
    // width: 110,
    marginTop: 20,
    marginBottom: 16,
  },
  snsImage: {
    width: 42,
    height: 42,
  },
  urlCopyImage: {
    width: 162,
    height: 42,
  },
})
