import firebase from 'firebase/app'

export const getFunctionsRef = () => {
  if (process.env.APP_ENV === 'local') return firebase.functions()
  return firebase.app().functions('asia-northeast1')
}

// ユーザー情報の取得
export const fetchUserInfo = async (uid: string) => {
  const doc = await firebase
    .firestore()
    .collection(`users`)
    .doc(uid)
    .get()
    .then(doc => ({ id: doc.id, ...doc.data() }))
  return doc
}

// ユーザークレジットカード情報の取得
export const fetchUserCreditCards = async (uid: string) => {
  const creditCards = await firebase
    .firestore()
    .collection(`billing/v1/users/${uid}/paymentMethodList`)
    .get()
    .then(qs =>
      qs.docs.map(doc => {
        return { id: doc.id, ...doc.data().card }
      }),
    )
  return creditCards
}

// ニックネーム存在確認
export const isExistsNickname = async (nickname: string, uid?: string): Promise<boolean> => {
  const docs = await firebase
    .firestore()
    .collection(`users`)
    .get()
    .then(qs =>
      qs.docs.map(doc => {
        return { id: doc.id, nickname: doc.data().nickname }
      }),
    )
  // 大文字と小文字の区別をなくして確認
  const sameNicknameIndex = docs.findIndex(doc => doc.nickname?.toLowerCase() === nickname?.toLowerCase())
  if (sameNicknameIndex === -1) {
    return false
  } else if (docs[sameNicknameIndex].id === uid) {
    return false
  } else {
    return true
  }
}

export const firebaseAuthError = (err = {}) => {
  console.warn(err)
  // @ts-ignore
  switch (err.code) {
    case 'auth/user-not-found':
      return 'ユーザーが登録されていません。'
    case 'auth/invalid-email':
      return '不正なメールアドレスです。'
    case 'auth/email-already-in-use':
      return 'すでに登録されているメールアドレスです。'
    case 'auth/weak-password':
      return 'パスワードは最低６文字必要です。'
    case 'auth/wrong-password':
      return 'パスワードが違うか、パスワードが登録されていないアカウントです。'
    case 'auth/credential-already-in-use':
      return 'このアカウントはすでに登録済です。'
    default:
      return 'エラーが発生しました。'
  }
}

// 問い合わせメール
export const sendMailForContact = async data => {
  const sendMailForContactOnCall = getFunctionsRef().httpsCallable('sendMailForContact')
  await sendMailForContactOnCall(data).then(res => {
    console.log('res', res)
  })
}

// 決済完了メール
export const sendMailForPayment = async (data: { email: string }) => {
  const sendMailForPaymentOnCall = getFunctionsRef().httpsCallable('sendMailForPayment')
  await sendMailForPaymentOnCall(data)
    .then(res => console.log('res', res))
    .catch(err => console.log(err))
}

// 顧客情報の作成
export const stripeCreateCustomer = async () => {
  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {
      await getFunctionsRef().httpsCallable('stripeCreateCustomer', { timeout: timeoutMillisec })()
      resolve({ success: true })
    } catch (err) {
      console.log('stripeCreateCustomer err:', err?.details?.code)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}

// 顧客のシークレットキー作成
export const stripeSetupIntent = async (): Promise<{
  success: boolean
  data: string
}> => {
  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {
      const intent = await getFunctionsRef().httpsCallable('stripeSetupIntent', { timeout: timeoutMillisec })()
      resolve({ success: true, data: intent.data })
    } catch (err) {
      console.log('stripeSetupIntent err:', err?.details?.code)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}

export const stripePaymentIntent = async (
  paymentMethod,
  totalAmount,
): Promise<{
  success: boolean
  data: string
  orderId: string
}> => {
  const timeoutMillisec = 8000
  return new Promise(async (resolve, reject) => {
    try {
      const orderId = generateOrderId('stripe-card', getNewOrderId())

      const intent = await getFunctionsRef().httpsCallable('stripePaymentIntent', { timeout: timeoutMillisec })({
        paymentMethod,
        amount: totalAmount,
        orderId,
      })
      resolve({ success: true, data: intent.data, orderId })
    } catch (err) {
      console.log('stripePaymentIntent err:', err?.details?.code)
      switch (err?.details?.code) {
        case 'auth/verifiedlogin':
        case 'auth/nologin':
          reject('ログインが必要です')
          break
        default:
          reject('ただ今混み合っているため、処理に失敗しました。時間を置いて再度お試しください。')
          break
      }
    }
  })
}

// 決済情報の保存
export const savePaymentInfo = async (paymentInfo, orderId, actionId, userInfo) => {
  const user = firebase.auth().currentUser
  console.log('savePaymentOrder:data ', paymentInfo)
  console.log('savePaymentOrder:orderId ', orderId)

  // 注文情報の保存
  getBillingUserRef(user.uid)
    .collection('orders')
    .doc(orderId)
    .set(paymentInfo)

  // アクションにユーザーの決済情報を追加
  await firebase
    .firestore()
    .collection(`actions`)
    .doc(actionId)
    .collection(`users`)
    .doc(user.uid)
    .set(
      {
        paymented: true,
        orderId,
        paymentDate: firebase.firestore.FieldValue.serverTimestamp(),
      },
      { merge: true },
    )

  // ユーザーに参加アクション情報を追加
  const joinActions = userInfo?.joinActions ? userInfo?.joinActions : []
  joinActions.push(actionId)
  await getUserRef(user.uid).set(
    {
      joinActions,
    },
    { merge: true },
  )
}

// カード番号を保存する
export const savePaymentMethod = async (paymentMethodId: string) => {
  const stripeSavePaymentMethod = getFunctionsRef().httpsCallable('stripeSavePaymentMethod')
  try {
    await stripeSavePaymentMethod({ paymentMethodId })
  } catch (err) {
    console.log(err.code, err.details)
  }
}

export const generateOrderId = (type: string, origOrderId: string) => {
  return `${type}_${origOrderId}`
}

export const getNewOrderId = (): string => {
  const user = firebase.auth().currentUser
  const newOrderDoc = getBillingUserRef(user.uid)
    .collection('orders')
    .doc()
  return newOrderDoc.id
}

export const getUserRef = (uid: string) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(uid)
}

export const getBillingRef = () => {
  return firebase
    .firestore()
    .collection('billing')
    .doc('v1')
}

export const getBillingUserRef = (uid: string) => {
  return getBillingRef()
    .collection('users')
    .doc(uid)
}

// 決済情報の削除
export const deleteCreditCard = async (uid: string, id: string) => {
  try {
    await getBillingUserRef(uid)
      .collection('paymentMethodList')
      .doc(id)
      .delete()
  } catch (err) {
    console.log(err.code, err.details)
  }
}
