export const Config = env => {
  // if (env === 'local') {
  //   return {
  //     FIREBASE_API_KEY: 'AIzaSyBLcRP027Q0B9MOT6ybYUV1n87KfAcmNDo',
  //     FIREBASE_AUTH_DOMAIN: 'megu-prototype-dev.firebaseapp.com',
  //     FIREBASE_DATABASE_URL: 'https://megu-prototype-dev.firebaseio.com',
  //     FIREBASE_PROJECT_ID: 'megu-prototype-dev',
  //     FIREBASE_STORAGE_BUCKET: 'megu-prototype-dev.appspot.com',
  //     FIREBASE_MESSAGING_SENDER_ID: 44149355966,
  //     FIREBASE_APP_ID: '1:44149355966:web:bfac87b370e64251a875ef',
  //     STRIPE_PUBLIC_KEY: 'pk_test_9fhJqML1PujCjngSkOta9sXi00Gsv6i7LB',
  //   }
  // }

  // if (env === 'development') {
  return {
    FIREBASE_API_KEY: 'AIzaSyBLcRP027Q0B9MOT6ybYUV1n87KfAcmNDo',
    FIREBASE_AUTH_DOMAIN: 'megu-prototype-dev.firebaseapp.com',
    FIREBASE_DATABASE_URL: 'https://megu-prototype-dev.firebaseio.com',
    FIREBASE_PROJECT_ID: 'megu-prototype-dev',
    FIREBASE_STORAGE_BUCKET: 'megu-prototype-dev.appspot.com',
    FIREBASE_MESSAGING_SENDER_ID: 44149355966,
    FIREBASE_APP_ID: '1:44149355966:web:bfac87b370e64251a875ef',
    STRIPE_PUBLIC_KEY: 'pk_test_9fhJqML1PujCjngSkOta9sXi00Gsv6i7LB',
  }
  // }

  // if (env === 'production') {
  // return {
  //   FIREBASE_API_KEY: 'AIzaSyB-nF-iZ53gb0Ue0hrFnd9uwouH95cj8mg',
  //   FIREBASE_AUTH_DOMAIN: 'megu-prototype.firebaseapp.com',
  //   FIREBASE_DATABASE_URL: 'https://megu-prototype.firebaseio.com',
  //   FIREBASE_PROJECT_ID: 'megu-prototype',
  //   FIREBASE_STORAGE_BUCKET: 'megu-prototype.appspot.com',
  //   FIREBASE_MESSAGING_SENDER_ID: 579984781378,
  //   FIREBASE_APP_ID: '1:579984781378:web:605057617e1d231c553ddb',
  //   STRIPE_PUBLIC_KEY: 'pk_live_KXPDKpKYgUEAgdKauCIORvuT000Bl1lr2B',
  // }
  // }
}
