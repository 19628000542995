import React, { useState } from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'

// libs
import { sendMailForContact } from 'app/src/libs/firebase'
import { goToContactFinish } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'

// components
import { Text } from 'app/src/components/basics'
import { TextInput } from 'app/src/components/basics/text-input'
import { Button } from 'app/src/components/basics/button'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { SelectBox } from 'app/src/components/basics/selectbox'

type ContainerProps = {}

type Props = {
  subject: string
  name: string
  email: string
  message: string
  setSubject: (subject: string) => void
  setName: (name: string) => void
  setEmail: (email: string) => void
  setMessage: (message: string) => void
  submitContact: () => void
}

export const ContactScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  const [subject, setSubject] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')

  const _submitContact = () => {
    if (!subject || !name || !email || !message) {
      alert('未入力の項目があります。')
      return
    }

    const data = {
      subject,
      name,
      email,
      message,
    }
    sendMailForContact(data)
    setSubject('')
    setName('')
    setEmail('')
    setMessage('')
    goToContactFinish(navigation, subject, message)
  }

  return (
    <UI
      subject={subject}
      setSubject={setSubject}
      name={name}
      setName={setName}
      email={email}
      setEmail={setEmail}
      message={message}
      setMessage={setMessage}
      submitContact={_submitContact}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  subject,
  setSubject,
  name,
  setName,
  email,
  setEmail,
  message,
  setMessage,
  submitContact,
}) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.headerContent}>
            <Text type={'xxlarge'} align={'center'}>
              お問い合わせ
            </Text>
          </View>

          <View style={styles.selectBox}>
            <SelectBox
              label={'お問い合わせ内容'}
              value={subject}
              onValueChange={v => setSubject(v)}
              placeholder={{ label: '選択してください', value: undefined }}
              items={[
                { label: 'サービスの利用方法がわからない', value: 'サービスの利用方法がわからない' },
                { label: 'アクションの主催リクエスト', value: 'アクションの主催リクエスト' },
                { label: '寄付について', value: '寄付について' },
                { label: 'アクションに規約違反を報告する', value: 'アクションに規約違反を報告する' },
                { label: '運営会社への取材依頼', value: '運営会社への取材依頼' },
                { label: 'その他', value: 'その他' },
              ]}
            />
          </View>
          <View style={styles.input}>
            <TextInput label={'お名前'} onChangeText={v => setName(v)} value={name} />
          </View>
          <View style={styles.input}>
            <TextInput
              label={'返信メールアドレス'}
              onChangeText={v => setEmail(v)}
              value={email}
              keyboardType={'email-address'}
              translucentGrayBackground
            />
          </View>
          <View style={styles.input}>
            <TextInput
              label={'本文'}
              numberOfLines={20}
              placeholder={'問い合わせ内容をお書きください'}
              onChangeText={v => setMessage(v)}
              value={message}
              translucentGrayBackground
            />
          </View>

          <View style={styles.btnWrapper}>
            <Button buttonType={3} onPress={() => submitContact()}>
              送信する
            </Button>
          </View>
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  headerContent: {
    marginTop: 20,
    marginBottom: 28,
  },
  selectBox: {
    width: '100%',
    alignItems: 'center',
    marginBottom: 20,
  },
  input: {
    width: '100%',
    alignItems: 'center',
  },
  btnWrapper: {
    marginTop: 20,
    marginBottom: 80,
  },
})
