import { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'
import { useDimensions } from '@react-native-community/hooks'

export let windowWidth = Dimensions.get('window').width
export const windowHeight = Dimensions.get('window').height

// const initialState = { width: 0, height: 0 }
// export const windowWidth = () => {
//   const [window, setWidth] = useState(initialState)
//   const dimensions = useDimensions()
//   useEffect(() => {
//     console.log('dimensions', dimensions.window)
//     setWidth(window)
//   }, [dimensions.window])

//   return window.width
// }
