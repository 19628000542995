import React from 'react'
import { StyleSheet, View, ScrollView, Image } from 'react-native'
import { RouteComponentProps } from 'react-router'
import { useLocation } from 'react-router-dom'
import ReactPlayer from 'react-player'

// libs
import { images } from 'app/src/libs/images'
import { goToContact } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'
import { black, white } from 'app/src/constants/colors'
import { windowWidth } from 'app/src/constants/layout'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { NpoCarousel } from 'app/src/components/advanced/area/npo-carousel'

type ContainerProps = {}

type Props = {
  navigation: any
  areaId: number
  setRef: (type: number, ref: React.RefObject<any>) => void
}

export const AreaDetailScreen: React.FC<ContainerProps & RouteComponentProps<{ id: string }>> = props => {
  const { state } = useLocation<any>()
  const navigation = useNavigation()

  const setScrollToElement = (type: number, ref: React.RefObject<any>) => {
    //クリックしたときにパラメータに保存したカルーセルのタイプと一致するカルーセルまでスクロール
    if (type === state.type) {
      window.scrollTo(0, ref.current.getBoundingClientRect().top + window.pageYOffset)
    }
  }

  return <UI navigation={navigation} areaId={Number(props.match.params.id)} setRef={setScrollToElement} />
}

const UI: React.FC<Props & ContainerProps> = ({ navigation, areaId, setRef }) => {
  const areaName = () => {
    if (areaId === 1) return '森林保護'
    if (areaId === 2) return '海洋保護'
    if (areaId === 3) return '動物保護'
    if (areaId === 4) return '貧困問題'
    if (areaId === 5) return '教育支援'
    if (areaId === 6) return '医療・災害'
    if (areaId === 7) return 'クリーンエネルギー'
    if (areaId === 8) return '研究開発'
  }

  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
          <Image style={styles.areaHeaderImage} source={images[`areaHeaderImage${areaId}`]} />
          <Text style={styles.mainTitle} type={'xxlarge'}>
            {areaName()}
          </Text>
          <View style={styles.contentContainer}>
            <Text style={styles.smallText} type={'small'}>
              ※地球温暖化を含む多くの社会課題は複合的な要因が複雑に絡み合っていることを理解した上で、本ページは現在の社会課題の大枠をシンプルにわかりやすくまとめることを目的にしております。より多くの人に、そして初めて寄付を検討しているという方に課題を大きく理解してもらい寄付先を考えるきっかけにしてもらえるよう構成しておりますが、ご意見・ご指摘等がありましたら、
              <Text style={[styles.linkText, styles.smallText]} onPress={() => goToContact(navigation)}>
                お問い合わせ
              </Text>
              より是非ご連絡いただけますと幸いです。
            </Text>
          </View>
          {areaId === 1 && (
            <>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>長きに渡り壊された自然環境</Text>
                <Image style={styles.image} source={images['area2Image1']} />
                <Text style={styles.text} type={'small'}>
                  森林には空気や水の浄化作用、気候変動や自然災害の抑制といった役割があります。特に南米のアマゾンやアフリカのジャングルを中心に広がる熱帯雨林は「地球の緑の肺」とも呼ばれ、年々増加している二酸化炭素の多くを吸収し、酸素を出しています。地球温暖化の様々な影響が問題視されている今、熱帯雨林の存在は非常に重要となっています。
                </Text>
                <Image style={styles.image} source={images['area2Image2']} />
                <Text style={styles.text} type={'small'}>
                  それにも関わらず現在世界の森林は急速に減少しており、1990年以降、約4億2000万ヘクタールの森林が消失したと推定されます。その主な原因としてあげられているのが、農業・畜産といった産業の拡大や資源調達のための過度な森林伐採、そして地球温暖化・気候変動による干ばつや森林火災です。この状態が続くと森林はその役割を果たすことが困難となり、環境や生態系に大きな影響が出ると考えられます。私たちの生活がどのような影響を及ぼすのかを理解し、課題の解決に取り組んでいくことが求められています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>経済発展とともに、森林が失われてきた</Text>
                <Image style={styles.image} source={images['area2Image3']} />
                <Text style={styles.text} type={'small'}>
                  森林が減少する要因の１つとなっているのが過度な森林伐採です。私たちは生活のために農業や畜産といった産業を拡大し、森林の資源を使って様々な製品を作ってきました。そして土地の確保や製品の原料となる安価な木材などを得るために伐採を行っています。本来は森林の再生速度などを考慮して行われる必要がありますが、それを上回る速度で伐採が進み、木々の再生が追い付いていないのが現状です。
                </Text>
                <Image style={styles.image} source={images['area2Image4']} />
                <Text style={styles.text} type={'small'}>
                  また世界で３番目に大きいジャングルを有するインドネシアでは、紙の原料になるアカシアやユーカリ、パーム油生産のためのアブラヤシといった単一種のみに広大な土地を植え替えるプランテーションが行われています。そこはかつての熱帯林のように緑に覆われてはいますが、元の自然環境とはまったく異なり、そこに住む野生動物が居場所を追われるといったことも起きています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>地球温暖化によって起こる森林減少</Text>
                <Image style={styles.image} source={images['area2Image5']} />
                <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                  火災から救助されるコアラ
                </Text>
                <Text style={styles.smallText} type={'small'} align={'center'}>
                  【出典】Australia fires: A visual guide to the bushfire crisis : https://www.bbc.com/news/world-australia-50951043
                </Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    2019年世界で大きな話題となったオーストラリアの森林火災では、19年7月から20年2月の間にオーストラリア南部から東部で合計37,500平方マイル（約97,000平方キロメートル）のエリアが被害にあいました。
                  </Text>
                </View>
                <View style={styles.figureContainer}>
                  <Image style={styles.verticalFigure} source={images['area2Image6']} />
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    オーストラリアの火災がどれだけの範囲であったか
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】Australia fires: A visual guide to the bushfire crisis : https://www.bbc.com/news/world-australia-50951043
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  これはポルトガルの国土より広い面積で、カリフォルニア州でも発生していた過去最大の山火事から受けた被害と比べ50倍の規模になる。それだけでなくコアラをはじめ多くの野生動物の命が失われ、周辺地域には深刻な煙害をもたらしました。近年こうした大規模な森林火災が各地で発生しています。また様々な原因によって土地が劣化することで起こる砂漠化も大きな問題となっています。土地が痩せ農作物や食糧は育たなくなり、水不足や水質の変化、それらによる生態系への影響などが懸念されます。森林火災や砂漠化には大きく分けて2つの要因があり、1つは人為的な要因、そしてもう1つが気候的な要因による影響です。地球温暖化が引き起こす気温の上昇、異常少雨や干ばつによって乾燥化が進み、森林火災が発生しやすくなり、土地の砂漠化も進んでいると考えられます。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>森林によって支えられている生物多様性の崩壊</Text>
                <Image style={styles.image} source={images['area2Image7']} />
                <Text style={styles.text} type={'small'}>
                  経済発展や地球温暖化による森林減少の影響は、そこに住む野生動物や樹木、その周辺の生物の多様性をも脅かしています。森林には陸上の動植物の80％が生息しているともいわれ、森林の保護が動植物を保護しその生態系を維持することにも繋がります。例えば過度な森林伐採が行われているスマトラ島では島固有のトラやゾウ、サイなどが生息しており、どれも絶滅危惧種に指定されています。ですが伐採によって住処を追われ、食糧も確保できなくなり絶滅の危機がさらに高まっています。温暖化の影響による乾燥や土地の劣化は、樹木の成長や繁殖を妨げ、それを餌とする野生動物はもちろん人間にとっても、農作物が育たなくなり食糧が不足するといった影響が懸念されます。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>この先も豊かな地球と生物多様性を支えるために出来ること</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    こうした問題には様々な要因が複雑に関係しているため、改善・解決に向けた取り組みは個人の生活の見直しも社会全体の変革も必要となってきます。個人レベルでいうと、フードロスや過剰消費の影響を理解し、その抑制に取り組むことで環境への負担を減らしていくといったことがあげられます。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area2Image8']} />
                <Text style={styles.text} type={'small'}>
                  環境保護団体などが取り組んでいる植林は、森林の再生を目的として行われるものと、現存の森林を傷つけることなく私たちが生活で必要とする食糧や資源を確保するための産業植林があります。近い将来直面するとされる、人口増加による食糧確保の問題の回避策としても植林を行い砂漠を緑化することで、食糧の確保を目指すことも複数の面で課題解決に繋がっています。
                </Text>
                <Image style={styles.image} source={images['area2Image9']} />
                <Text style={styles.text} type={'small'}>
                  他にも熱帯雨林などに保護区を設け、そこにある生態系も含め、定期的な調査を実施し保護活動に取り組むといったことや、逆に人間が林業を行うために元々あった森を伐採し商業用に植林したことで生態系が壊れてしまったところを元に戻すことで生態系を元に戻すための整備なども行われています。
                </Text>
                <Image style={styles.image} source={images['area2Image10']} />
                <Text style={styles.text} type={'small'}>
                  以下で紹介している団体は、すでにこうした問題の解決に乗り出し、活動に取り組んでいる団体です。meguでのアクションを通じて、彼らに少し力添えすることから始めてみるのはどうでしょうか？
                </Text>
              </View>
              <Text type={'xxlarge'}>{areaName()}で活動している団体</Text>
              <NpoCarousel type={1} setRef={setRef} />
              <NpoCarousel type={2} setRef={setRef} />
            </>
          )}
          {areaId === 2 && (
            <>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>私たちの生活を支える海が抱えている課題</Text>
                <Image style={styles.image} source={images['area1Image1']} />
                <Text style={styles.text} type={'small'}>
                  地球表面の約7割を占める海は、森林と同様、地球環境の保全や気候の安定、資源の供給といった点で様々な役割を担っています。人間を含むすべての生物がその恩恵を受けており、私たちが生きていくうえで欠かせない存在といえます。
                  ですが現在、年々悪化する地球温暖化や海洋汚染などによって引き起こされる海洋環境の変化と、それによる海洋生物への影響が大きな課題となっています。この状況が続けば、海洋環境・海洋生物だけでなく地球全体にさらなる悪影響を及ぼしかねません。そのため、こうした問題の改善・解決に向けた取り組みが緊急に求められています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>地球温暖化による海の変化</Text>
                <Image style={styles.image} source={images['area1Image2']} />
                <Text style={styles.text} type={'small'}>
                  かつて珊瑚で埋め尽くされ多種多様な魚が生活していた海。現在では珊瑚も魚も全て死滅してしまい、「海の砂漠」と呼ばれる状態になっています。（上記写真）
                  {'\n\n'}
                  海洋環境に変化をもたらしている大きな要因と言えるのが、地球温暖化による海の酸化と海水温の上昇です。海には、地球温暖化の原因である二酸化炭素（CO2）や熱エネルギーを吸収してくれる役割があります。これにより温暖化や大気中の二酸化炭素濃度の上昇は抑えられますが、一方で海水中の二酸化炭素濃度が高まることにより海の酸化が起きています。
                </Text>
                <View style={styles.figureContainer}>
                  <View style={styles.imageContainer}>
                    <Image style={styles.image} source={images['area1Image3']} />
                  </View>
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    大気中の二酸化炭素濃度と比例して上昇する海中の二酸化炭素濃度
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】海洋の二酸化炭素濃度の長期変化 - 気象庁
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  酸化が進むとプランクトンやサンゴ、貝類などの成長・繁殖に影響が及ぶため、海洋生態系全体への影響が懸念されます。また海水温の上昇による海藻の死滅や海水の性質が変わることで「二酸化炭素の吸収能力が低下する」とも言われ、大気中の二酸化炭素の割合が増え、結果的に地球温暖化を加速させることに繋がってしまいます。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>海に流れ込むごみや有害物質の影響</Text>
                <Image style={styles.image} source={images['area1Image4']} />
                <Text style={styles.text} type={'small'}>
                  私たちの生活や経済活動によって出るごみや有害物質は、海に流れ込み海洋汚染の原因となっています。世界の海には年間約800万トンものごみが新たに流れ出していると推定され、このままごみが増え続けると2050年頃には魚とごみの量が同等になるとも考えられています。海洋ごみの半数以上である65.8%を占めているのはプラスチックごみで、特にマイクロプラスチックと呼ばれるサイズが5mm以下のプラスチックによる影響が問題視されています。
                </Text>
                <View style={styles.figureContainer}>
                  <View style={styles.imageContainer}>
                    <Image style={styles.image} source={images['area1Image5']} />
                  </View>
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    太平洋上の海流の流れとゴミの分布
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】The Great Pacific Garbage Patch – Mapped
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  潮の流れによって最終的に海洋ごみが集積するエリアの問題も深刻化しており、中でも最大となる「Great Pacific garbage
                  patch」は推定表面積が160万平方キロメートル、そこに含まれるプラスチックごみは8万トンと推定され、約1.8兆個ものプラスチック片が浮いていると考えられます。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>海洋生物が受けている深刻な被害と人間への影響</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    地球温暖化やごみなどによる海洋汚染によって、海の生物たちも様々な被害を受けています。海の酸化による住環境の破壊、エサであるプランクトンや海藻の減少とそれに伴う食物連鎖の崩壊、さらにプラスチックごみの誤飲や漁業用の網などに絡まって受ける外傷と、その影響は多岐に渡ります。
                  </Text>
                </View>
                <View style={styles.figureContainer}>
                  <View style={styles.imageContainer}>
                    <Image style={styles.image} source={images['area1Image6']} />
                  </View>
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    日本は一人当辺りのプラスチック排出量がアメリカに次いで世界2位
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】UNEP Report on Single Use Plastic
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  また人為的被害はごみなどの問題だけに止まらず、人口増加や経済発展のために規制を超えた乱獲が行われたり、密漁によって海洋生物が減少しているといった点もあります。こうした海の生物への影響は当然人間にも及ぶと考えられ、食生活の変化であったり、プラスチックを体内に取り込んだ魚などを摂取することによる身体への影響などもあげられています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>今後も海を守り、生態系を維持するために求められること</Text>
                <Image style={styles.image} source={images['area1Image7']} />
                <Text style={styles.text} type={'small'}>
                  現状の改善・解決のために求められる行動は様々ですが、例えば海洋ごみの問題においてはすでに流出してしまったごみの回収やそもそもの流出量の削減などがあります。海に流れ出てしまっているごみが劣化し、マイクロプラスチックとなってしまうと回収は困難になるため、そうなる前に対策することが必要です。そしてそもそも海にごみが流れ込まないよう取り組むことが大切で、代替品の普及や利用量を減らしたり、繰り返し使う、リサイクルするといったことを促す
                  {'\n'}
                  3R（Reduce、Reuse、Recycle）などの実施で排出量を減らしていくことが重要となります。海洋生物の保護の面では、生態系を支えるサンゴや海藻などが生存し続けられる環境の保全と、密漁や乱獲といった被害をこれ以上増やさないような取り組みが求められます。
                  {'\n\n'}
                  （現状の課題が3分程度にまとめられた非常に分かりやすい動画がありますので、お時間のある際に是非ご覧ください。内容は全編英語となります。）
                </Text>
                <View style={styles.figureContainer}>
                  <ReactPlayer width={windowWidth - 31 * 2} height={windowWidth / 2} url="https://www.youtube.com/embed/uOibha0WwIU" />
                </View>
                <Text style={styles.text} type={'small'}>
                  今こうしている間にも問題は深刻化し、状況は刻一刻と変化しています。この先も地球環境や綺麗な海を守っていくためにも、ここにあげているような問題は私たち1人ひとりが意識を変え、改善・解決に取り組むべき課題と言えます。以下で紹介している団体は、すでにこうした問題の解決に乗り出し、活動に取り組んでいる団体です。meguでのアクションを通じて、彼らに少し力添えすることから始めてみるのはどうでしょうか？
                </Text>
              </View>
              <Text type={'xxlarge'}>{areaName()}で活動している団体</Text>
              <NpoCarousel type={3} setRef={setRef} />
              <NpoCarousel type={5} setRef={setRef} />
            </>
          )}
          {areaId === 3 && (
            <>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>世界の野生動物の個体数はすでに大幅減少しているというが、、</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    2019年5月6日、国連が支援する「生物多様性及び生態系サービスに関する政府間科学・政策プラットフォーム」（IPBES）が発表した調査報告書によると、現在100万種類以上の生物が絶滅の危機にあると言います。アフリカゾウやクロサイ、トラ、そしてジャイアントパンダといった私たち我々がよく知っている動物も、21世紀中には絶滅すると言われています。
                  </Text>
                </View>
                <View style={styles.figureContainer}>
                  <Image style={styles.image} source={images['area3Image1']} />
                </View>
                <Text style={styles.text} type={'small'}>
                  私たちの暮らす地球の生態系を守るため、そして未来のためにいま何ができるのでしょうか。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>地球環境の変化、森林伐採等からくる動物の絶滅</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    1970年以来、世界の人口は倍増し2020年の世界人口は77億9500万人で、昨年に比べ8000万人増加しました。そして世界経済の規模は4倍に、国際貿易の量は10倍に増加しました。人間の経済活動を支えるため、そして人類に十分な食料と衣類とエネルギーを与えるため、熱帯地域の森林が非常に早いスピードで伐採されてきました。1980年から2000年の20年間に失われた熱帯林の面積は、1億ヘクタールに及びます。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area3Image2']} />
                <Text style={styles.text} type={'small'}>
                  また、1992年以降都市部は2倍に拡大しました。地球上の陸地全体の4分の3が農地やコンクリート、ダム貯水池などに変わり、海洋全体の3分の2は漁業、航路等に利用されています。人間の経済活動によって多くのスペースが占領された結果、50万種以上の生物が生息地を失い、絶滅に繋がったと報告されています。
                  {'\n\n'}
                  IPBESによるとこうした影響はさらに広がっており、現在でも動植物の25％の種が絶滅の危機に瀕しているといいます。これらの現状を踏まえ、今後約100万種の動植物が数十年のうちに絶滅すると警告しています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>レッドリストの始まり</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    レッドリストとは、絶滅のおそれのある野生生物の名称、カテゴリー等の最低限の情報のみのリストです。国際自然保護連合
                    (IUCN)が中心となり最初のリストは1966年にレッドブックとして出版されました。第１版の表紙が赤い色をしていたことから、それを一覧形式でまとめたものを「絶滅のおそれのある種のレッドリスト」と呼ばれるようになりました。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area3Image3']} />
                <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                  飢えに苦しむシロクマ
                </Text>
                <Text style={styles.smallText} type={'small'} align={'center'}>
                  【出典】new York Times - Video of Starving Polar Bear ‘Rips Your Heart Out of Your Chest’
                </Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    日本国内においては、環境省はもちろんのこと、地方公共団体やNGOなども作成しています。動物については、哺乳類、鳥類、両生類、爬虫類、汽水・淡水魚類、昆虫類、陸・淡水産貝類、その他無脊椎動物の分類群ごとに、植物については、維管束植物、蘚苔類、藻類、地衣類、菌類の分類群ごとに作成されています。また、2017年3月には新たなリストである海洋生物レッドリストが作成されています。
                  </Text>
                </View>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>世界の野生動物保護の動き</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    レッドブックの出版を機に、徐々に野生動物保護の世界的なコンセンサス形成が進み、1972年には国連人間環境会議が開催され、国連に環境問題を扱うＵＮＥＰ（ユネップ：国連環境計画）が誕生しました。その後、世界遺産条約やワシントン条約、ラムサール条約、ボン条約といった自然環境を保全する主要条約が次々に締結され、世界的な生物保護の動きが加速し始めました。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area3Image4']} />
                <Text style={styles.text} type={'small'}>
                  では、レッドリストに掲載された動物は、どのように保護されているのでしょうか。実は、レッドリストに掲載されること自体は、法的な保護の対象となることを意味するわけではありません。実際の保護に関する法律やルールを定めたりといった活動は、その動物が生息する国や地域が行います。
                  野生動物の保護を始め、現在の地球においては1国や1地域では解決できない問題も数多くあります。こうした地球規模の問題は、各国や地域の抱える政治的、経済的な課題を乗り越え、国際的な協力のもと解決に取り組んでいく必要があります。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>アニマルフリーの衣服</Text>
                <Image style={styles.image} source={images['area3Image5']} />
                <Text style={styles.text} type={'small'}>
                  世界では、動物保護につながる身近な行動、消費を選択する活動が広がっています。例として、アニマルフリーの商品を選択することがあげられます。アニマルフリーとは、動物の毛皮や革、羽毛などを一切使用しないことです。様々なアパレルメーカーが動物素材を使用しない、アニマルフリーなアイテムの製造、販売に乗り出しています。リサイクル可能な生地や素材を利用し、地球環境にもやさしい服を作っているアパレルメーカーも増えてきました。人、動物、環境、すべてにやさしい商品を選択することで、動物保護の取り組みの第一歩につながります。こうしたアニマルフリーの取り組みはアパレル業界に限らず、食品、化粧品と様々な業界に広がっています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>未来はどうなるのか</Text>
                <Image style={styles.image} source={images['area3Image6']} />
                <Text style={styles.text} type={'small'}>
                  未来は今の私たちの行動にかかっています。動物保護や生態系の保護には、「抜本的な改革」が求められています。ライフスタイルを大きく変える行動が必要だとも言われています。国や政府、企業といった大きな団体が、環境破壊に繋がる行動を改めたり、一個人が日々の行動を少し変化させることで、動物保護、生態系の保護に貢献することができます。
                  {'\n\n'}
                  以下で紹介している団体は、すでにこうした問題の解決に乗り出し、活動に取り組んでいる団体です。meguでのアクションを通じて、彼らに少し力添えすることから始めてみるのはどうでしょうか？
                </Text>
              </View>
              <Text type={'xxlarge'}>{areaName()}で活動している団体</Text>
            </>
          )}
          {areaId === 4 && (
            <>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>改善方向ではあるものの、まだまだ支援を必要とする貧困問題</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    貧困に関する問題は、森林や海洋破壊、野生動物の絶滅などの状況が日に日に悪化している一方で、実は改善傾向にあります。
                    {'\n\n'}
                    貧困層の基準は世界銀行が2015年に定義しており、1日$1.9以下の収入しか得られていない人が該当します。現在の為替相場だと、日本円でおよそ200円です（2020年10月時点）。1日に$1.9以下の収入しか得られていない人が2015年には世界の人口の10%を締めていましたが、これは1990年当時36%だったことから考えると大幅に改善されていると考えられるでしょう。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area4Image1']} />
                <Text style={styles.text} type={'small'}>
                  しかしこの2015年の世界人口の10%というのは、人数にして7億3600万人。それだけの人々が1日の収入が200円以下で生活しているのです。そして、この日本の総人口の6倍にも匹敵する貧困層が存在するということが引き起こしているもう一つの問題が、子供の死亡率です。2017年に国連が発表した「死亡率推計に関する機関間グループ（IGME）」という報告書によると15歳未満の子供は累計で630万人、日別でおよそ1万7000人が亡くなっていることになります。つまり、毎秒5人弱の子供が亡くなっているのです。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>貧困が起きる主な原因</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    こういった貧困問題はなぜ起き、それによりどういった影響が起きているのでしょうか。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area4Image2']} />
                <Text style={styles.text} type={'small'}>
                  多くの複合的な要因が絡み合っているものの、主な貧困の原因は以下の3点と言われています。
                  {'\n'}１．政治や経済など情勢問題
                  {'\n'}
                  {'  '}
                  歴史的背景により差別問題などで十分な収入を得られない層が依然として多かったり、地政学的背景から産業が育たないなどの理由で、貧困地域では十分な教育が得られない状況が続いています。
                  {'\n\n'}２．宗教・人種・民族・食糧不足・資源不足等で起きる紛争
                  {'\n'}
                  {'  '}
                  紛争、内戦がおきると住居や職を失い、更に紛争が続くと緊張感の高まりから身の安全を確保するため国外に避難する人が続出します。国連UNHCR協会が2019年6月に発表した年間統計報告書「グローバル・トレンズ・レポート
                  2018」によると、2018年末時点で7080万人が難民となっています。
                  {'\n\n'}３．自然災害
                  {'\n'}
                  {'  '}
                  地球温暖化の影響により、異常気象から農作物が収穫できず貧困状態を誘発するケースがあります。インドなどの南アジアの方では、これまでは１ヶ月で降っていた降水量が１日で降るなどの現象が起こり、田畑の水没や洪水により作物が流れてしまい、農家や地域住民が貧困に陥るというようなことが起きています。世界銀行によると、このような自然災害によって年間約2600万人が貧困状態に陥っています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>貧困問題は新興国だけの問題ではない</Text>
                <Image style={styles.image} source={images['area4Image3']} />
                <Text style={styles.text} type={'small'}>
                  先ほどあげたように、貧困は様々な理由から起きています。それに加え、教育を受けられない、医療を受けられないなど貧困であるが故に、本来誰もが受けられるはずの利益を得られないということも起きています。これは貧困層が多いとされていた国の子どもたちだけではなく、世界のGDPランキングでも上位にあるアメリカや日本でも満足な教育が受けられないといったような貧困層が増加傾向にあり、問題視されています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>痛み止め的な対応と根治させるための対応が必要</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    貧困問題には度合いがあり、生活水準があまりに低く衛生的な生活水へのアクセスすら危うい子どもたちもいれば、経済発展した社会の中で取り残されたように苦しんでる子どもたちもいます。そして彼らを救うために必要な対策としては、短期的に必要な対応と長期的に必要な対応があると考えられます。短期的な対応としては、物資の支援や生活環境の整備など、今現在必要としているニーズを直接的に満たしてあげることで危機的状況を回避するなどがあげられます。ただ、こういった短期的な対応のみを永続的に続けるのは限界があります。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area4Image4']} />
                <Text style={styles.text} type={'small'}>
                  そのため長期的に必要な対応として、そこに住む人々が自立して自らの生活圏の社会発展をしていけるよう、次世代を担う子どもたちに対し質の高い教育を提供していく必要があります。meguでは、この貧困問題のエリアでは今現在、緊急性の高いニーズに対して支援を行っている方々を対象とし、長期的対応として区分させていただいた教育の支援については、教育のエリアの対象とします。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>複雑化しているが故にさらなる支援が必要</Text>
                <Image style={styles.image} source={images['area4Image5']} />
                <Text style={styles.text} type={'small'}>
                  これまで極度の貧困に苦しむ人達が多いエリアとそうでないエリアの線引は明確に切り分けることが可能でしたが、今日ではそれぞれの社会の中に貧困問題が点在するようになっています。従来通りの経済未発達による貧困もあれば、格差拡大から来る貧困もあり、それぞれに支援を必要としています。
                </Text>
              </View>
              <Text type={'xxlarge'}>{areaName()}で活動している団体</Text>
            </>
          )}
          {areaId === 5 && (
            <>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>教育が満足に得られない子どもたちがいる</Text>
                <Image style={styles.image} source={images['area5Image1']} />
                <Text style={styles.text} type={'small'}>
                  教育問題に関しては、近年様々な支援団体、ボランティア等が学校建設や教科書の配布を行う等、現状の改善を目指し活動を行っています。ですが、いまだ世界には自分の名前が書けない、本が読めない、注意書きがあっても意味が分からない、病気になっても薬の飲み方がわからないという人が多くいる状況が続いています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>小学校に通えない児童数</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    UNESCOの調査によると、2018年時点で小学校に通えない児童は5,900万人にのぼり、多くはアフリカ及び南アジア地域に暮らしています。そのうちの半分は、紛争の影響で学校が壊されたり、教師が戦闘員として導入されたことにより学校に通う機会を失っています。その他にも、家族の生活を助けるため過酷な労働を強いられていたり、学校が遠いことから通学を諦めるといったケースも発生しています。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area5Image2']} />
                <Text style={styles.text} type={'small'}>
                  また、小学校に通えたとしても人数に対して教室が小さく座れない、教科書が足りない、教師の質が悪く良質な教育コンテンツを提供できない事も喫緊で対策が必要な状況です。
                  {'\n'}
                  ※出典：New methodology shows that 258 million children, adolescents and youth are out of school, UNESCO Institute for
                  Statistics, 2019
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>女性への教育格差</Text>
                <Image style={styles.image} source={images['area5Image3']} />
                <Text style={styles.text} type={'small'}>
                  先ほど触れた小学校に通えない児童数のうち、半分以上は女性であることが報告されています。学校に通えない女性の多くは、親から女性は結婚し家庭に入るから教育を受ける必要が無いとされ、家庭を助けるため家事を手伝う事を強要されています。
                  世界銀行によると、女性が教育を受けていないことによる潜在的な経済及び社会的損失費用は大きく、人的資本の富（労働力の生涯収益）の損失を15兆ドルから30兆ドルと見積もっています。
                  {'\n'}
                  ※出典：Missed Opportunities : The High Cost of Not Educating Girls, WORLD BANK GROUP, 2018
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>幼児教育の重要性</Text>
                <Image style={styles.image} source={images['area5Image4']} />
                <Text style={styles.text} type={'small'}>
                  小学校に入学できたとしても幼児教育を受けていない為、授業に追いつけず学校を辞めてしまうという事も新たな問題として浮き彫りになっております。世界の約半分の幼児たちは、幼児教育を受けることができずにいます。その数は最低でも1億7500万人とも言われており、先進国の子供達も例外ではありません。
                  {'\n'}
                  ※出典：UNICEFホームページ &apos;A World Ready to Learn: Prioritizing quality early childhood education&apos;, 2019
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>幼児教育の重要性</Text>
                <Image style={styles.image} source={images['area5Image5']} />
                <Text style={styles.text} type={'small'}>
                  これからの未来は今の私たちの行動にかかっています。SDGsに掲げられている「4.質の高い教育をみんなに」及び「5.ジェンダー平等を実現しよう」の実現には、質の高いアクションが継続的に行われる必要があります。
                  {'\n\n'}
                  以下で紹介している団体は、すでにこうした問題の解決に乗り出し、活動に取り組んでいる団体です。meguでのアクションを通じて、彼らに少し力添えすることから始めてみるのはどうでしょうか？
                </Text>
              </View>
              <Text type={'xxlarge'}>{areaName()}で活動している団体</Text>
            </>
          )}
          {areaId === 6 && (
            <>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>災害により増加する医療ニーズ</Text>
                <Image style={styles.image} source={images['area8Image1']} />
                <Text style={styles.text} type={'small'}>
                  医療支援というと、戦争や貧困等によって医療サービスを受けられない人々に対して支援を行うイメージが強いかもしれませんが、気候変動が原因となった災害によって医療支援が必要なケースが近年急増しています。このエリアでは慢性的原因（戦争や貧困等）における医療支援の必要性と、有事の際に突発的に必要とされる医療支援の必要性について触れていきます。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>医療支援の現状</Text>
                <Image style={styles.image} source={images['area8Image2']} />
                <Text style={styles.text} type={'small'}>
                  近年、世界各国の支援団体やボランティアの活動により、5歳未満児死亡数は大幅に改善されております。ユニセフによると、1990年当時は100万人あたり死亡率が世界全体で12.5%であったが、2019年では5.2%まで減少しており、改善の大きな要因は発展途上国の死亡率が大きく改善された事が大きく寄与したと報告されております。しかしながら、2015年に国連で採択されたSDGsの中に、5歳未満児死亡数を1,000人あたり25人以下まで減らす事を目標を達成するには、サハラ以南のアフリカ地域をはじめとする複数のエリアで更なる継続的な支援が必要です。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>深刻な医師不足</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    総務省統計局のデータによると、世界各国の人口1,000人当たりの医師数（2018年）は、ギリシャやオーストリアなどのヨーロッパの国々では4人を越え高い水準にあり、日本でも2.3人となっています。一方アフリカの国々の中には、人口1,000人当たりの医師数が0.1人に満たない国が多くあり、深刻な医師不足が問題となっています。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area8Image3']} />
                <Text style={styles.text} type={'small'}>
                  ユニセフでは、それぞれの地域で地元出身の人々に数週間から数ヵ月程度の医療研修を行い、薬や器材を託して村々を巡回する地域保健員を年間数万人育成する活動を行っています。しかし、その規模でサポートを行っていても、今後アフリカだけで約10年間で420万人の地域保健員が不足するといわれており、深刻な状況は続いています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>世界の災害状況</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    国連が1995年～2015年の20年間に起きた気候に関連した災害について調査した報告書によると、災害が発生する回数は増え続けており、特に増えているのは洪水と、台風やハリケーン、サイクロンです。洪水は1995年～2015年に起きた気候に関連した災害の47％を占め、23億人が影響を受けました。そのうち95%はアジアに住む人々です。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area8Image4']} />
                <Text style={styles.text} type={'small'}>
                  一方、洪水よりも発生する回数は少ないものの、気候に関連した災害の中で一番命の危険が高いのは台風やハリケーン、サイクロンなどの“嵐”です。実際にこうした災害で亡くなる人のうち、40%は嵐によって命を落としています。特に低所得国の人びとにとって嵐は、命にかかわる大きな影響をもたらすもので、命を失った人の89%が低所得国に暮らす人となっています。
                </Text>
                <Image style={styles.image} source={images['area8Image5']} />
                <Text style={styles.text} type={'small'}>
                  熱波や寒波も、一回起きると、多くの人が亡くなります。先進国を調査したデータでは、気候に関連した災害で亡くなる人の76%は、異常な気温によるものでした。
                  {'\n'}
                  ※出典：The human cost of weather-related disasters 1995-2015, United Nations Office for Disaster Risk Reduction, 2015
                  　引用：ユニセフ　各国の気候に関連した災害が起きた件数
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>日本の状況</Text>
                <Image style={styles.image} source={images['area8Image6']} />
                <Text style={styles.text} type={'small'}>
                  最近、一昔前と比べて猛暑日が多く、大雨が頻繁に発生していると感じている方は多いのではないでしょうか。
                </Text>
                <View style={styles.figureContainer}>
                  <Image style={styles.image} source={images['area8Image8']} />
                </View>
                <Text style={styles.text} type={'small'}>
                  気象庁の報告によると、猛暑日は最近30年間（1990～2019年）の平均年間日数（約2.3日）となっておりますが、統計期間の最初の30年間（1910～1939
                  年）の平均年間日数（約0.8日）と比較すると約2.9倍に増加しており、日降水量400mm以上の年間日数（1年間で400mm以上の雨が降った日の合計数）は、最近10年間（2010～2019年）の平均年間日数（約16日）は、統計期間の最初の10年間（1976～1985
                  年）の平均年間日数（約6日）と比べて約2.7倍に増加しています。
                  {'\n'}
                  ※出典：気象庁「大雨や猛暑日など（極端現象）のこれまでの変化」
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>今後の医療支援</Text>
                <Image style={styles.image} source={images['area8Image7']} />
                <Text style={styles.text} type={'small'}>
                  これからの医療支援活動は発展途上国での支援だけではなく、災害時にも必要となり、今後益々増える事が予想されます。以下の支援団体は、すでにこうした問題の解決に乗り出し、活動に取り組んでいる団体です。meguでのアクションを通じて、彼らに少し力添えすることから始めてみるのはどうでしょうか？
                </Text>
              </View>
              <Text type={'xxlarge'}>{areaName()}で活動している団体</Text>
              <NpoCarousel type={4} setRef={setRef} />
            </>
          )}
          {areaId === 7 && (
            <>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>エネルギーの需要を補う方法の見直しが必要となっている現代社会</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    私たちの毎日の生活は電力によって支えられています。衣食住で使用している様々な家電製品しかり、コロナの影響で拡大したテレワークを支えてるパソコンやスマホも、私たちは電力なくしての生活がままならなくなっています。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area7Image1']} />
                <Text style={styles.text} type={'small'}>
                  そして、これまではその大半の電力を火力発電に頼ってきましたが、地球温暖化の主原因が温室効果ガスにあるということが確実視されるようになって以降、私たちが日々使っている電力の生産方法が見直され始めています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>温室効果ガスの排出量を実質０に</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    30年ほど前は地球温暖化と温室効果ガスの関連性を疑う声がありましたが、温室効果ガスの排出量が飛躍的に増えるようになってからは温度上昇が比例して温度上昇も急激に進んでおり、今日では温室効果ガスは地球温暖化の主要因とされています。
                  </Text>
                </View>
                <View style={styles.figureContainer}>
                  <Image style={styles.image} source={images['area7Image2']} />
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    世界の二酸化炭素排出量の推移
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】IPCC第5次評価報告書特設ページ
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  温室効果ガスの排出量の増加は、中国やインドを始めとした新興国が（世界中で）急激な経済発展を遂げていることにより、勢いを増すばかりです。
                </Text>
                <View style={styles.figureContainer}>
                  <Image style={styles.verticalFigure} source={images['area7Image3']} />
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    世界の平均地上気温の推移と変化
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】IPCC第5次評価報告書 - 気象庁訳
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  このままのペースで温室効果ガスが排出されていくと、2050年には1年の内の半年が35℃を超える猛暑日になると言われています。この温度上昇を1.5℃以内に収めるには2050年、2℃以内に収めるには2100年までに、温室効果ガスの排出量を実質０もしくはマイナスにしていかなくてはならないとされています。これは日本に限った話ではなく「全世界で０及びマイナス」にする必要があるため容易なことではなく、待ったなしの状況です。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>温暖化を食い止めるためにすべき４つの改善</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    この状況を打破するために、国際社会が1992年に地球サミットを開催し「気候変動枠組条約」というものが作られました。この内容を協議して定めたのが「京都議定書」と「パリ協定」です。2015年に定められたパリ協定では、世界の温室効果ガス排出量の約86%を排出している159ヵ国が賛同することとなり、「
                    世界の平均気温上昇を産業革命以前に比べて2℃より低く保ち、1.5℃に抑える努力をする」という長期目標を掲げています。この目標を実現させるために各国がそれぞれの目標と取り組みを掲げており、その達成方法としては大きく４つに分類できます。
                    {'\n\n'}
                    １．消費エネルギーの絶対量を減らす省エネルギー化
                    {'\n'}
                    ２．太陽光発電、風力発電等の再生可能エネルギーの普及
                    {'\n'}
                    ３．原子力発電
                    {'\n'}
                    ４．水素エネルギー社会の実現
                  </Text>
                </View>
                <View style={styles.figureContainer}>
                  <Image style={styles.image} source={images['area7Image4']} />
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    発電電力量に占める再生可能エネルギー比率の比較
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】2018―日本が抱えているエネルギー問題
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  経済発展と共に必要となるエネルギー量に比例して、増加すると想定される温室効果ガスの排出量をまず減らすため、各種機械や工場の省エネルギー化をした上で、化石燃料を使用する発電方法から切り替えていくことで温室効果ガスの排出量を減らそうというものです。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>求められる技術革新とエネルギーミックス</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    日本は、2010年までは化石燃料の使用量を減らす方向に進んでいました。しかし3.11を機に原発を停止したことと、その後太陽光発電を推し進めすぎたことにより、資源の価格としてもっとも安く電気料金としても抑えられる石炭火力発電に頼ることとなっていました。このため石炭火力発電所の建設・建替えも増え、それが非難の対象となっています。
                  </Text>
                </View>
                <View style={styles.figureContainer}>
                  <Image style={styles.image} source={images['area7Image5']} />
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    一次エネルギー国内供給構成および自給率の推移
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】2018―日本が抱えているエネルギー問題
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  しかし、現在新しく建設・建て替えが行われている石炭火力発電所は、これまでの煙が立ち上る火力発電所のイメージとは異なるものになっています。クリーンコール技術と呼ばれる技術が導入され、温室効果ガスや大気汚染物質の排出を大幅に減らしています。
                </Text>
                <View style={styles.figureContainer}>
                  <Image style={styles.image} source={images['area7Image6']} />
                  <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                    リプレースされ新しくなった磯子火力発電所とその効果
                  </Text>
                  <Text style={styles.smallText} type={'small'} align={'center'}>
                    【出典】なぜ、日本は石炭火力発電の活用をつづけているのか？～2030年度のエネルギーミックスとCO2削減を達成するための取り組み
                  </Text>
                </View>
                <Text style={styles.text} type={'small'}>
                  太陽光発電も発電量が天候に左右されて安定しなかったり、30年〜40年でパネルの寿命が来ると大量に廃棄物が出てしまったり、電気料金が高額になってしまったりと、各種発電方法はどれも良し悪しがあり理想の発電方法はまだありません。ですが、技術開発を進めていき、すでに建造された施設・設備をきちんと管理していくことで、よりクリーンに私達の生活を支える電力をまかなうように変えていくことは可能です。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>日本は温室効果ガスを削減し汚名返上できるか</Text>
                <Image style={styles.image} source={images['area7Image7']} />
                <Text style={[styles.smallText, styles.black]} type={'small'} align={'center'}>
                  COP25で演説をする小泉進次郎環境大臣
                </Text>
                <Text style={styles.smallText} type={'small'} align={'center'}>
                  【出典】小泉環境相、脱炭素化に向け新たな決意　COP25閣僚級会合
                </Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    2019年末にあったCOP25（気候変動枠組条約第25回締約国会議）では、小泉進次郎環境大臣が「石炭火力発電に関する新たな政策をこの場で共有することは残念ながらできない」と演説し、日本が石炭火力発電を増築していっていることに対し温暖化対策に消極的だと判断され、世界の約1300の環境NGOから「化石賞」という不名誉な評価を受けました。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area7Image8']} />
                <Text style={styles.text} type={'small'}>
                  今後10年、30年の間に地球/世界全体で多くの努力を必要とする現代社会であり地球環境ですが、上記のように技術の発展とすでに作られた施設・設備の管理をしていくことで、今のこの地球を次の世代のために保っていかなくてはなりません。そのための取り組みを以下のような団体が行っています。そんな彼らに少し力添えすることで、地球が壊れるのを食い止める手助けをしてみてはどうでしょうか？
                </Text>
              </View>
              <Text type={'xxlarge'}>{areaName()}で活動している団体</Text>
            </>
          )}
          {areaId === 8 && (
            <>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>人々の生活は維持しつつも、持続可能な社会を作るために</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    人々の生活、経済、そして技術発展は密接に絡み合っており、持続可能な社会を構築していく上で技術発展は必要不可欠と言えるでしょう。環境保全のために人々の生活に負荷をかけるという手段、ガソリンの利用を禁止するであるとか、牛肉の販売を禁止するといったような手段は結局受け入れられないため根本的な問題解決は実現が難しい状況が続いています。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area6Image1']} />
                <Text style={styles.text} type={'small'}>
                  こうした中で、人々が求める商品、サービス、ビジネスでありながらも環境保全につながったり、技術発展もしくは既存技術の組み合わせで社会課題を解決しつつビジネスとして成立するような事業の転換が必要となってきています。ここではいくつか技術発展が求められる領域や技術の組み合わせにより改善された事例を紹介し、研究開発や技術発展の社会課題の解決に向けた重要性を理解いただければと思います。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>生分解性プラスチック</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    技術革新が求められる製品の一つとして挙げられるのが、プラスチックです。海洋保護のページでも触れている通り、年間800万トンのプラスチックが海に流れ込んでるとされ、新興国の発展によりさらに増加していくと考えられます。現状ではこれまで排出されたプラスチックのほとんどは自然環境で分解されません。燃やしても有害ガスが出るため埋め立てていくしか方法がないのですが、先進国ではプラスチックを廃棄するだけの土地がないため受け入れてくれる国に輸出しています。そして輸入した国々でも廃棄物で国土を埋めていくわけにもいかず、海に廃棄しているケースや意図的でなく洪水や水害などで海洋に流出してしまっているケースもあります。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area6Image2']} />
                <Text style={styles.text} type={'small'}>
                  こうして海に流れ込んだプラスチックが、年々自然環境を汚染しています。これを解決するために研究されているのが、生分解性プラスチックと呼ばれる植物由来のもので作られた環境に還るプラスチックです。ただ開発はが進んでいるものの、現段階では広く一般的に普及されるまでには至っていません。コカ・コーラが2023年までにオランダのAvantium社が作るとうもろこしや小麦から抽出された砂糖で作ったペットボトルに切り替えていくことが発表されていますが、その他の企業においてもこうしたプラスチックの技術革新が急務で求められています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>牛が発生させるメタンガス</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    地球温暖化の中で問題視されているのが牛肉や乳製品の供給のために大量繁殖される牛が餌を消化する過程で発生させるメタンガスです。メタンガスの温室効果は二酸化炭素に比べて25倍とも言われているため、牛のメタンガスの排出の抑制は非常に重要な要素の一つとされています。ただ、牛肉は非常に人気な食材であるために、流通や提供を生業としている人も多く、温暖化対策として牛肉の消費を禁止するということはできません。そのため、根幹にあるメタンガスの排出自体を食い止めることができないかという研究が進められています。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area6Image3']} />
                <Text style={styles.text} type={'small'}>
                  現在もっとも有効とされているのが、紅藻を活用したサプリを牛の飼料に含める方法です。最新の研究成果としてはカリフォルニア大学デービス校が開発したサプリを与えた結果、家畜の体調や肉の質を変えることもなく、牛の消化プロセスで生じるメタンの量が80%以上減少するという結果がでました。まだ広く普及はしておりませんが、環境問題に対する技術革新での対応策として、現在注目さている研究分野の一つです。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>ミドリムシから生成されるバイオ燃料</Text>
                <View style={styles.textContainer}>
                  <Text style={styles.text} type={'small'}>
                    化石燃料の使用も地球温暖化の大きな要因とされていますが、今の社会は化石燃料の上に成り立っているといえます。この化石燃料を代替する燃料として注目されているのがバイオ燃料です。
                  </Text>
                </View>
                <Image style={styles.image} source={images['area6Image4']} />
                <Text style={styles.text} type={'small'}>
                  日本でもバイオ燃料の導入が進められているものの、これまでバイオ燃料を安価に仕入れられる調達先はブラジルなどかなり遠い国で船に乗せて輸入していました。環境を考慮したバイオ燃料を化石燃料を使って船で輸送してきていてはあまり意味がありません。そんな中、日本のユーグレナ社という世界で始めてミドリムシの大量培養に成功した企業が、ミドリムシから抽出できる油からバイオ燃料の開発を始めました。すでにバスやフェリーなどでの導入が進んでおり、2021年あたりには飛行機への導入も認可される予定となっています。
                </Text>
              </View>
              <View style={styles.contentContainer}>
                <Text style={styles.title}>短期的に必要な対応と、技術革新により永続的な課題解決の両側面が必要</Text>
                <Image style={styles.image} source={images['area6Image5']} />
                <Text style={styles.text} type={'small'}>
                  meguで取り上げている他のエリアでは「今」起きてる課題を解決するために、多くの人が弛まぬ努力をしています。ただ現状起きてる問題には根本解決をしなくてはならないものも多く、それを可能にするために多くの研究者が研究を重ねています。持続的な社会活動を実現するために、上記のような技術を研究し開発している研究者を支援することで、社会の深い課題解決へ貢献をしてみませんか？
                </Text>
              </View>
              <Text type={'xxlarge'}>{areaName()}で活動している団体</Text>
            </>
          )}
          <View style={styles.contentContainer}>
            <Text style={styles.text} type={'small'}>
              皆様の想いが届くよう、日々努力しておりますが、寄付先についてご意見・ご要望がある場合は
              <Text style={styles.linkText} onPress={() => goToContact(navigation)}>
                お問い合わせ
              </Text>
              よりご連絡ください。
            </Text>
          </View>
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: white,
    marginBottom: 50,
  },
  scrollContents: {
    alignItems: 'center',
  },
  areaHeaderImage: {
    width: windowWidth,
    height: windowWidth * 1.28,
    resizeMode: 'cover',
  },
  contentContainer: {
    marginBottom: 46,
    marginHorizontal: 31,
    alignItems: 'center',
  },
  figureContainer: {
    marginVertical: 20,
    alignItems: 'center',
  },
  mainTitle: {
    zIndex: 20,
    marginTop: -40,
    marginBottom: 40,
  },
  title: {
    width: 312,
    fontWeight: 'bold',
  },
  imageContainer: {
    marginBottom: 5,
  },
  image: {
    width: windowWidth - 31 * 2,
    height: windowWidth / 2,
    resizeMode: 'contain',
  },
  verticalFigure: {
    width: windowWidth - 31 * 2,
    height: windowWidth * 1.28,
    resizeMode: 'contain',
  },
  smallText: {
    fontSize: 9,
    lineHeight: 16,
    width: 312,
  },
  black: {
    color: black,
  },
  textContainer: {
    marginTop: 16,
  },
  text: {
    width: 312,
    color: black,
  },
  linkText: {
    color: '#2CA4DE',
  },
})
