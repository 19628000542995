// constants
import { isWeb } from 'app/src/constants/platform'

// ホーム
export const goToHome = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/`,
    })
  }

  if (!isWeb) {
    navigation.navigate('HomeScreen')
  }
}

// アクション一覧
export const goToActionList = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/action/list`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionListScreen')
  }
}

// アクション詳細
export const goToActionDetail = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/action/detail`,
      search: id ? `?id=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionDetailScreen', {
      id,
    })
  }
}

// アクション作成
export const goToActionCreate = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/action/create`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionCreateScreen')
  }
}

// アクション編集
export const goToActionEdit = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/action/edit`,
      search: `?id=${id}`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionEditScreen', {
      id,
    })
  }
}

// マイページ
export const goToMypage = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/mypage`,
    })
  }

  if (!isWeb) {
    navigation.navigate('MyPageScreen')
  }
}

// 設定リスト
export const goToSettingList = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/settings/list`,
    })
  }

  if (!isWeb) {
    navigation.navigate('SettingListScreen')
  }
}

// アカウント設定
export const goToAccountSetting = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/settings/account`,
    })
  }

  if (!isWeb) {
    navigation.navigate('AccountSettingScreen')
  }
}

// 決済設定
export const goToPaymentSetting = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/settings/payment`,
      search: id ? `?actionId=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('PaymentSettingScreen')
  }
}

// 決済完了
export const goToPaymentFinish = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/payment/finish`,
      search: id ? `?actionId=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('PaymentFinishScreen')
  }
}

// 出金設定
export const goToBankAccount = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/settings/bank-account`,
    })
  }

  if (!isWeb) {
    navigation.navigate('BankAccountSettingScreen')
  }
}

// 支払い
export const goToPayment = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/payment`,
      search: id ? `?id=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('PaymentScreen')
  }
}

// サインイン
export const goToSignin = (navigation, id = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/signin`,
      search: id ? `?actionId=${id}` : '',
    })
  }

  if (!isWeb) {
    navigation.navigate('SigninScreen')
  }
}

// サインアップ
export const goToSignup = (navigation, id = '', fromPathname = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/signup`,
      state: { fromPathname: fromPathname, id: id },
    })
  }

  if (!isWeb) {
    navigation.navigate('SignupScreen')
  }
}

// サインアップ完了
export const goToSignupFinish = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/signup/finish`,
    })
  }

  if (!isWeb) {
    navigation.navigate('SignupFinishScreen')
  }
}

// ライブビューアー
export const goToLiveViewer = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/live/viewer`,
    })
  }

  if (!isWeb) {
    navigation.navigate('LiveViewerScreen')
  }
}

// ライブ配信側
export const goToLiveDelivery = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/live/delivery`,
    })
  }

  if (!isWeb) {
    navigation.navigate('LiveDeliveryScreen')
  }
}

// ポートフォリオ
export const goToPortfolio = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/portfolio`,
    })
  }

  if (!isWeb) {
    navigation.navigate('PortfolioScreen')
  }
}

// エリア詳細
export const goToAreaDetail = (navigation, area = 0, type = 0) => {
  if (isWeb) {
    navigation.push({
      pathname: `/area/detail/${area}`,
      state: { type: type },
    })
  }

  if (!isWeb) {
    navigation.navigate('ActionDetailScreen')
  }
}

// 規約
export const goToTerms = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/terms`,
    })
  }

  if (!isWeb) {
    navigation.navigate('TermScreen')
  }
}

// HowTo
export const goToHowTo = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/how-to`,
    })
  }

  if (!isWeb) {
    navigation.navigate('HowToScreen')
  }
}

// プライバシーポリシー
export const goToPrivacy = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/privacy`,
    })
  }

  if (!isWeb) {
    navigation.navigate('PrivacyScreen')
  }
}

// QA
export const goToQa = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/qa`,
    })
  }

  if (!isWeb) {
    navigation.navigate('QaScreen')
  }
}

// 会社概要
export const goToCompany = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/company`,
    })
  }

  if (!isWeb) {
    navigation.navigate('CompanyScreen')
  }
}

// お問い合わせ
export const goToContact = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/contact`,
    })
  }

  if (!isWeb) {
    navigation.navigate('ContactScreen')
  }
}

// お問い合わせ完了
export const goToContactFinish = (navigation, subject = '', message = '') => {
  if (isWeb) {
    navigation.push({
      pathname: `/contact/finish`,
      state: { subject: subject, message: message },
    })
  }

  if (!isWeb) {
    navigation.navigate('ContactFinishScreen')
  }
}

// 特定商取引法に基づく表記
export const goToTransactions = navigation => {
  if (isWeb) {
    navigation.push({
      pathname: `/transactions`,
    })
  }

  if (!isWeb) {
    navigation.navigate('TransactionsScreen')
  }
}
