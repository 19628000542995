import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, Image, ImageBackground, TouchableOpacity, TextInput, Platform } from 'react-native'
import firebase from 'firebase'
import { useLocation } from 'react-router-dom'

// libs
import { images } from 'app/src/libs/images'
import { isExistsNickname, firebaseAuthError } from 'app/src/libs/firebase'
import { goToSignin, goToSignupFinish } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'
import { useParams } from 'app/src/hooks/use-params'

// constants
import { isWeb } from 'app/src/constants/platform'
import { white } from 'app/src/constants/colors'
import { windowWidth, windowHeight } from 'app/src/constants/layout'

// components
import { Text, Button } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { TabBar } from 'app/src/components/advanced/tab-bar'

type ContainerProps = {}

type Props = {
  navigation: any
  setNickname: (string) => void
  setEmail: (string) => void
  setPassword: (string) => void
  setPasswordConfirm: (string) => void
  signup: () => void
}

export const SignupScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()
  const location = useLocation<any>()

  const [nickname, setNickname] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')

  const _signup = async () => {
    if (await _signupValidate()) {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(user => {
          const currentUser = firebase.auth().currentUser
          //遷移前がアクション詳細画面のとき
          if (user && location.state && location.state?.fromPathname?.pathname === '/action/detail') {
            _createUser(currentUser)
            _sendEmailFromPayment()
          } else if (user) {
            //遷移前がマイページのとき
            _createUser(currentUser)
            _sendEmailFromMypage()
          }
        })
        .catch(async err => {
          console.log(err)
          alert(firebaseAuthError(err))
        })
    }
  }

  const _signupValidate = async () => {
    if (!nickname || !email || !password || !passwordConfirm) {
      alert('未入力の項目があります。')
      return false
    }

    if (await isExistsNickname(nickname)) {
      alert('既に存在するニックネームです。')
      return false
    }

    if (password !== passwordConfirm) {
      alert('パスワードが一致していません。')
      return false
    }

    return true
  }

  const _sendEmailFromMypage = () => {
    const actionCodeSettings = {
      url: `http://${window.location.host}/mypage`,
      handleCodeInApp: true,
    }
    firebase
      .auth()
      .currentUser?.sendEmailVerification(actionCodeSettings)
      .then(() => {
        alert('利用ブラウザによっては完了情報を反映する為、ホーム画面に戻りリロードが必要な場合がございます。')
        goToSignupFinish(navigation)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _sendEmailFromPayment = () => {
    const actionCodeSettings = {
      url: `http://${window.location.host}/payment?id=${location.state.id}`,
      handleCodeInApp: true,
    }
    firebase
      .auth()
      .currentUser?.sendEmailVerification(actionCodeSettings)
      .then(() => {
        goToSignupFinish(navigation)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const _createUser = async currentUser => {
    const userRef = firebase
      .firestore()
      .collection('users')
      .doc(currentUser.uid)

    let userData = await userRef.get().then(docs => docs.data())
    if (!userData) {
      userData = {
        imageUrl: currentUser.photoURL,
        os: Platform.OS,
        email,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        nickname,
      }
      userRef
        .set(userData)
        .then(v => {
          goToSignupFinish(navigation)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }

  return (
    <UI
      navigation={navigation}
      setNickname={setNickname}
      setEmail={setEmail}
      setPassword={setPassword}
      setPasswordConfirm={setPasswordConfirm}
      signup={_signup}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({ navigation, setNickname, setEmail, setPassword, setPasswordConfirm, signup }) => {
  return (
    <View style={styles.container}>
      {isWeb && <Header />}
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <ImageBackground style={styles.bgSignin} source={images['bgSignup']} imageStyle={{ resizeMode: 'cover' }}>
          <Text type={'xlarge'} style={styles.pageName}>
            新規登録
          </Text>
          <View style={styles.textInputContainer}>
            <View style={styles.textInputView}>
              <TextInput
                style={styles.textInput}
                onChange={v => setNickname(v.nativeEvent.text)}
                placeholder={'ニックネーム'}
                placeholderTextColor={white}
              />
            </View>

            <View style={styles.textInputView}>
              <TextInput
                style={styles.textInput}
                onChange={v => setEmail(v.nativeEvent.text)}
                placeholder={'メールアドレス'}
                placeholderTextColor={white}
              />
            </View>

            <View style={styles.textInputView}>
              <TextInput
                style={styles.textInput}
                onChange={v => setPassword(v.nativeEvent.text)}
                secureTextEntry
                placeholder={'パスワード'}
                placeholderTextColor={white}
              />
            </View>

            <View style={styles.textInputView}>
              <TextInput
                style={styles.textInput}
                onChange={v => setPasswordConfirm(v.nativeEvent.text)}
                secureTextEntry
                placeholder={'パスワード確認'}
                placeholderTextColor={white}
              />
            </View>
            <View style={styles.signinButton}>
              <Button buttonType={4} onPress={() => signup()}>
                新規登録する
              </Button>
            </View>
          </View>
          <TouchableOpacity style={styles.linkBtn} onPress={() => goToSignin(navigation)}>
            <Text style={styles.link}>既に会員の方はこちら</Text>
          </TouchableOpacity>
          <Image style={styles.earthSigninImage} source={images['earthSignin']} />
        </ImageBackground>
      </ScrollView>
      {/* {isWeb && <TabBar />} */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  scrollContainer: {
    flex: 1,
  },
  scrollContents: {
    justifyContent: 'center',
  },
  bgSignin: {
    width: '100%',
    height: windowHeight,
    position: 'relative',
  },
  earthSigninImage: {
    width: windowWidth,
    height: windowWidth * 0.7,
  },
  pageName: {
    fontSize: 16,
    color: white,
    marginTop: 82,
    marginBottom: 40,
    textAlign: 'center',
  },
  textInputContainer: {
    marginHorizontal: 'auto',
  },
  textInputView: {
    backgroundColor: 'rgba(221, 221, 221, 0.4)',
    width: 325,
    height: 48,
    marginBottom: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    color: white,
    paddingLeft: 17,
    width: 325,
    height: 48,
    zIndex: 10,
  },
  signinButton: {
    marginTop: 50,
    marginBottom: 36,
    width: 260,
    height: 50,
    marginHorizontal: 'auto',
  },
  linkBtn: {
    zIndex: 10,
  },
  link: {
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
})
