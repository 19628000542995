import React, { createContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

type FirebaseContextValue = {
  firestore: firebase.firestore.Firestore | null
  storage: firebase.storage.Storage | null
  functions: firebase.functions.Functions | null
}

const FirebaseContext = createContext<FirebaseContextValue>({
  firestore: null,
  storage: null,
  functions: null,
})

export const FirebaseApp: React.FC = ({ children }) => {
  const firestore = firebase.firestore()
  const storage = firebase.storage()
  const functions = firebase.app().functions()
  if (process.env.APP_ENV === 'local') functions.useEmulator('localhost', 5001)
  return <FirebaseContext.Provider value={{ firestore, storage, functions }}>{children}</FirebaseContext.Provider>
}
