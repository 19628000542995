import React from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'

//libs
import { goToContact } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'
import { blue } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'

type ContainerProps = {}

type Props = {
  navigation: any
}

export const TransactionsScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  return <UI navigation={navigation} />
}

const UI: React.FC<Props & ContainerProps> = ({ navigation }) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.headerContent}>
            <Text type={'xxlarge'} align={'center'}>
              特定商取引法に基づく表記
            </Text>
          </View>
          <View style={styles.mainContent}>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                サービス名
              </Text>
              <Text type={'medium'} style={styles.text}>
                megu
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                販売事業者名（会社名）
              </Text>
              <Text type={'medium'} style={styles.text}>
                株式会社beero
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                代表者名
              </Text>
              <Text type={'medium'} style={styles.text}>
                代表取締役　越島悠介
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                所在地
              </Text>
              <Text type={'medium'} style={styles.text}>
                〒150－0011{'\n'}東京都渋谷区東1-13-1 ニュー常磐松マンション410
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                お問い合わせ
              </Text>
              <Text type={'medium'} style={styles.text}>
                こちら（contact@beero.co.jp）からお問い合わせください。{'\n'}
                ※弊社電話番号はお客様からのご要望に基づき遅滞なく電子メールにて通知いたします。 弊社電話番号が必要なお客様は
                <Text style={styles.linkText} onPress={() => goToContact(navigation)}>
                  お問い合わせフォーム
                </Text>
                よりお申し出ください。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                販売価格
              </Text>
              <Text type={'medium'} style={styles.text}>
                アクションの詳細ページ及び購入手続きの際に画面に表示されます。{'\n'}
                消費税は内税として表示しております。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                販売価格以外でお客様に{'\n'}発生する金銭
              </Text>
              <Text type={'medium'} style={styles.text}>
                当サイトのページの閲覧、コンテンツ購入、ソフトウェアのダウンロード等に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。また開催されるアクションがオンライン上での開催でなく、物理的に集る必要がある場合の交通費はお客様のご負担となります。また当サイト上で物品を購入された場合の送料が別途記載されている場合はお客様のご負担となります。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                お支払方法
              </Text>
              <Text type={'medium'} style={styles.text}>
                以下のいずれかのお支払方法をご利用いただけます。{'\n'}
                ・各種クレジットカード{'\n'}
                ・LINE Pay{'\n'}
                ・その他、購入にかかる決済を代行する会社が提供するお支払方法{'\n'}
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                商品購入方法
              </Text>
              <Text type={'medium'} style={styles.text}>
                当サイトのアクション詳細ページから「アクションに参加する」ボタンを押下し、各種お支払方法で決済していただきますと、コンテンツへのアクセス権が付与され、開始時間にアクセスいただけますと視聴可能となります。商品詳細ページから「購入する」ボタンを押下した場合は、各種お支払方法で決済していただきますと、商品の発送手続きが開始します。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                デジタルアイテム等の{'\n'}利用が可能となる時期
              </Text>
              <Text type={'medium'} style={styles.text}>
                特別な定めを置いている場合を除き、お支払い手続き完了後、アクションの配信時間を迎えますとコンテンツをご視聴いただけます。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                動作環境
              </Text>
              <Text type={'medium'} style={styles.text}>
                配信の視聴を快適に行うための推奨環境は以下のとおりです。{'\n'}
                ---------------------------{'\n'}
                スマートフォンアプリ{'\n'}
                ■推奨環境■{'\n'}
                Android OS10.0以上{'\n'}
                iOS 13.0以上{'\n'}
                ■必要環境■{'\n'}
                Android OS7.0以上{'\n'}
                iOS 12.4以上{'\n'}
                ---------------------------{'\n'}
                PCブラウザ{'\n'}
                ■推奨環境■{'\n'}
                【Windows】{'\n'}
                Microsoft Edge 最新ver{'\n'}
                Google Chrome 最新ver{'\n'}
                【Mac】{'\n'}
                Safari 最新ver{'\n'}
                Google Chrome 最新ver{'\n'}
                ---------------------------{'\n'}
                通信環境{'\n'}
                ■推奨環境■{'\n'}
                低画質視聴時：1Mbps以上{'\n'}
                オリジナル画質視聴時：2Mbps以上{'\n'}
                ※お客様側の環境で推奨とされる通信速度の数値となります。{'\n'}
                上記に満たない場合は低画質・低音質での再生となります。{'\n'}
                ---------------------------{'\n'}
                上記以外の環境はサポートの対象外となりますので、推奨環境でのご利用をお願い致します。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'medium'} style={styles.title}>
                返品・キャンセル
              </Text>
              <Text type={'medium'} style={styles.text}>
                １.お客様のご都合による返品・キャンセル{'\n'}
                サポーターはアクション開始時間の24時間前までアクションへの参加をキャンセルできるものとし、支払った参加費は当社より返金されます。商品を購入された場合は商品詳細ページ内に記載されている期間内にサイト上の手順に沿ってキャンセルの手続きを行ってください。返品可能期間内に実施され、返送された商品の状態が出荷時の状態から破損・欠品が見られない場合に限り返金いたします。ただし、この場合の送料はお客様のご負担となります。あらかじめ利用環境・対応機種および各アプリケーションの利用環境・対応機種をよくお確かめの上、お申込み、もしくはご購入願います。
                {'\n'}
                ２.配信者、出品者の都合による返品・キャンセル{'\n'}
                アクションが配信者によりキャンセルされた場合は支払った参加費は当社より返金されます。また商品の返送を販売者が求めた場合、もしくは商品詳細ページに記載されているものと届いたものの内容に著しい齟齬があった場合は、返品頂き次第、支払った代金は当社より返金されます。この場合の送料は当社負担となりますので着払いでの発送をお願いいたします。
                {'\n'}
                ３.サービスの瑕疵に基づく返品（キャンセル）{'\n'}
                アプリケーションに瑕疵が発見されたときは、瑕疵を修補したアプリケーションをアプリケーションのバージョンアップ又はその他適切な方法で提供いたします。
              </Text>
            </View>
          </View>
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  headerContent: {
    marginTop: 20,
    marginHorizontal: 20,
    marginBottom: 34,
  },
  mainContent: {
    marginHorizontal: 28,
    fontSize: 14,
  },
  content: {
    flexDirection: 'row',
    paddingBottom: 25,
  },
  title: {
    fontSize: 11,
    width: 121,
    textAlign: 'right',
    marginRight: 10,
  },
  text: {
    fontSize: 11,
    flex: 1,
  },
  linkText: {
    fontSize: 11,
    color: blue,
    borderBottomWidth: 1,
    borderBottomColor: blue,
  },
})
