import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { BasicLayout } from 'app/src/components/layouts/basic'
import { ScrollToTop } from 'app/src/components/apps/scroll-to-top'
import { HomeScreen } from 'app/src/components/pages/home/index'
import { ActionListScreen } from 'app/src/components/pages/action/list'
import { ActionDetailScreen } from 'app/src/components/pages/action/detail'
import { ActionCreateScreen } from 'app/src/components/pages/action/create'
import { ActionEditScreen } from 'app/src/components/pages/action/edit'
import { LiveDeliveryScreen } from 'app/src/components/pages/live/delivery'
import { LiveViewerScreen } from 'app/src/components/pages/live/viewer'
import { PortfolioScreen } from 'app/src/components/pages/portfolio/index'
import { AreaDetailScreen } from 'app/src/components/pages/area/detail'
import { MypageScreen } from 'app/src/components/pages/mypage'
import { SettingListScreen } from 'app/src/components/pages/settings/list'
import { AccountSettingScreen } from 'app/src/components/pages/settings/account'
import { PaymentSettingScreen } from 'app/src/components/pages/settings/payment'
import { TermsScreen } from 'app/src/components/pages/others/terms'
import { HowToScreen } from 'app/src/components/pages/others/how-to'
import { PrivacyScreen } from 'app/src/components/pages/others/privacy'
import { QaScreen } from 'app/src/components/pages/others/qa'
import { CompanyScreen } from 'app/src/components/pages/others/company'
import { ContactScreen } from 'app/src/components/pages/contact'
import { TransactionsScreen } from 'app/src/components/pages/others/transactions'
import { PaymentScreen } from 'app/src/components/pages/payment'
import { SigninScreen } from 'app/src/components/pages/signin'
import { SignupScreen } from 'app/src/components/pages/signup'
import { SignupFinishScreen } from 'app/src/components/pages/signup/finish'
import { RegisterScreen } from 'app/src/components/pages/mypage/register'
import { PaymentFinishScreen } from 'app/src/components/pages/payment/finish'
import { ContactFinishScreen } from 'app/src/components/pages/contact/finish'

export const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <BasicLayout>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/action/list" component={ActionListScreen} />
          <Route exact path="/action/detail" component={ActionDetailScreen} />
          <Route exact path="/action/create" component={ActionCreateScreen} />
          {/* <Route exact path="/action/edit" component={ActionEditScreen} />
          <Route exact path="/live/delivery" component={LiveDeliveryScreen} />*/}
          <Route exact path="/live/viewer" component={LiveViewerScreen} />
          <Route exact path="/live/delivery" component={LiveDeliveryScreen} />
          <Route exact path="/portfolio" component={PortfolioScreen} />
          <Route exact path="/area/detail/:id" component={AreaDetailScreen} />
          <Route exact path="/mypage" component={MypageScreen} />
          <Route exact path="/settings/list" component={SettingListScreen} />
          <Route exact path="/settings/account" component={AccountSettingScreen} />
          <Route exact path="/settings/payment" component={PaymentSettingScreen} />
          <Route exact path="/terms" component={TermsScreen} />
          <Route exact path="/how-to" component={HowToScreen} />
          <Route exact path="/privacy" component={PrivacyScreen} />
          <Route exact path="/qa" component={QaScreen} />
          <Route exact path="/company" component={CompanyScreen} />
          <Route exact path="/contact" component={ContactScreen} />
          <Route exact path="/contact/finish" component={ContactFinishScreen} />
          <Route exact path="/transactions" component={TransactionsScreen} />
          <Route exact path="/signin" component={SigninScreen} />
          <Route exact path="/signup" component={SignupScreen} />
          <Route exact path="/payment" component={PaymentScreen} />
          <Route exact path="/payment/finish" component={PaymentFinishScreen} />
          <Route exact path="/signup/finish" component={SignupFinishScreen} />
          <Route exact path="/register" component={RegisterScreen} />
        </Switch>
      </BasicLayout>
    </BrowserRouter>
  )
}
