import React from 'react'
import { StyleSheet, View, Image } from 'react-native'

//libs
import { images } from 'app/src/libs/images'

// constants
import { lightGray, black } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'

type Props = {
  createIndex: number
}

export const ActionCreateHeader: React.FC<Props> = ({ createIndex = 1 }) => {
  const subTitleText = () => {
    if (createIndex === 1) {
      return '全部で約１分で完成！'
    }
    if (createIndex === 2) {
      return '誰かと開催予定ですか？'
    }
    if (createIndex === 3) {
      return '参加費や寄付先の設定'
    }
    if (createIndex === 4) {
      return 'どんなアクションですか？'
    }
    if (createIndex === 5) {
      return '画像の設定'
    }
  }

  return (
    <>
      <Text style={styles.actionTitle}>{createIndex === 6 ? 'アクションが作成されました！' : 'アクションを作ろう！'}</Text>
      {createIndex !== 6 && (
        <>
          <Text style={styles.actionSubTitle}>{subTitleText}</Text>
          <View style={styles.createStepList}>
            <View style={styles.item}>
              <View>
                <Image style={styles.iconVertical} source={images['actionIcon1Active']} />
              </View>
              <View>
                <Text style={styles.titleActive}>基本情報</Text>
              </View>
            </View>
            <View style={styles.item}>
              <View>
                <Image style={styles.iconHorizontal} source={createIndex < 2 ? images['actionIcon2'] : images['actionIcon2Active']} />
              </View>
              <View>
                <Text style={createIndex < 2 ? styles.title : styles.titleActive}>共演者</Text>
              </View>
            </View>
            <View style={styles.item}>
              <View>
                <Image style={styles.iconVertical} source={createIndex < 3 ? images['actionIcon3'] : images['actionIcon3Active']} />
              </View>
              <View>
                <Text style={createIndex < 3 ? styles.title : styles.titleActive}>価格設定</Text>
              </View>
            </View>
            <View style={styles.item}>
              <View>
                <Image style={styles.iconHorizontal} source={createIndex < 4 ? images['actionIcon4'] : images['actionIcon4Active']} />
              </View>
              <View>
                <Text style={createIndex < 4 ? styles.title : styles.titleActive}>詳細</Text>
              </View>
            </View>
            <View style={styles.item}>
              <View>
                <Image style={styles.iconVertical} source={createIndex < 5 ? images['actionIcon5'] : images['actionIcon5Active']} />
              </View>
              <View>
                <Text style={createIndex < 5 ? styles.title : styles.titleActive}>画像</Text>
              </View>
            </View>
          </View>
        </>
      )}
    </>
  )
}

const styles = StyleSheet.create({
  actionTitle: {
    textAlign: 'center',
    marginTop: 72,
    fontSize: 20,
    color: black,
  },
  actionSubTitle: {
    textAlign: 'center',
    marginTop: 7,
    marginBottom: 45,
    fontSize: 12,
    color: lightGray,
  },
  // ステップリスト
  createStepList: {
    marginBottom: 40,
    marginHorizontal: 12,
    width: 390,
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingHorizontal: 32,
  },
  item: {
    alignItems: 'center',
  },
  iconVertical: {
    width: 32,
    height: 43,
    marginBottom: 9,
  },
  iconHorizontal: {
    width: 43,
    height: 32,
    marginBottom: 9,
    marginTop: 11,
  },
  title: {
    fontSize: 9,
    color: lightGray,
  },
  titleActive: {
    fontSize: 9,
    color: black,
  },
})
