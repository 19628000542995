import React from 'react'
import { StyleSheet, View, ScrollView, ImageBackground, ViewPagerAndroidOnPageSelectedEventData } from 'react-native'

// constants
import { isWeb } from 'app/src/constants/platform'
import { windowHeight, windowWidth } from 'app/src/constants/layout'

// components
import { Header } from 'app/src/components/advanced/header'
import { TabBar } from 'app/src/components/advanced/tab-bar'

type ContainerProps = {}

type Props = {}

export const LiveDeliveryScreen: React.FC<ContainerProps> = () => {
  return <UI />
}

const UI: React.FC<Props & ContainerProps> = () => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
          <iframe
            width={windowWidth}
            height={windowHeight - 48 - 56}
            src="https://dist.bambuser.net/broadcaster/?application_id=iz4XaqWLV5mW2n1LavPhXQ&ticket=domain%3Dmegu-web.netlify.app&da_signature_header=da_id%3Dc676d039-ebc7-fca4-5947-0290d3c33427%26da_timestamp%3D1597101048%26da_signature_method%3DHMAC-SHA256%26da_ttl%3D0%26da_static%3D1%26da_signature%3D19e928ad1283accdab6a10de818e69d0cf7cda03e7a4f3af0d730fab113fdb4b"
            allow="microphone; camera"
            frameBorder="0"
          ></iframe>
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  scrollContents: {
    justifyContent: 'center',
  },
  viewer: {
    width: windowWidth,
    height: windowHeight,
    position: 'relative',
  },
})
