import * as Linking from 'expo-linking'

// constants
import { isWeb } from 'app/src/constants/platform'

// ホーム
export const LinkingOpenURL = (url: string) => {
  if (isWeb) {
    window.open(url, '_blank')
  }

  if (!isWeb) {
    Linking.openURL(url).catch(err => console.error('URLを開けませんでした。', err))
  }
}
