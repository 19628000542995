import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView, TouchableOpacity } from 'react-native'
import firebase from 'firebase/app'
import { AntDesign } from '@expo/vector-icons'
import { useStripe, useElements } from '@stripe/react-stripe-js'

// libs
import { fetchUserInfo, stripeCreateCustomer, stripePaymentIntent, fetchUserCreditCards, savePaymentInfo } from 'app/src/libs/firebase'
import { goToSignin, goToSignupFinish, goToPaymentSetting, goToActionDetail, goToPaymentFinish } from 'app/src/libs/screen-transition'
import { sendMailForPayment } from 'app/src/libs/firebase'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'
import { useParams } from 'app/src/hooks/use-params'

// constants
import { isWeb } from 'app/src/constants/platform'
import { borderLightGrayColor, white, primaryColor } from 'app/src/constants/colors'

// components
import { Text, Button } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ActionCard } from 'app/src/components/advanced/action-card'
import { RadioButton } from 'react-native-paper'
import { CreditCard } from 'app/src/components/advanced/credit-card'
import { PaymentModal } from 'app/src/components/advanced/payment/payment-modal'

//models
import { ActionCard as ActionCardModel } from 'app/src/models/action-card'

type ContainerProps = {}

type Props = {
  navigation: any
  actions: ActionCardModel[]
  possibleViewerNumber: number
  entryAmount: number
  totalAmount: number
  actionId: string
  creditCards: any
  selectedCreditCard: any
  userInfo: any
  visiblePaymentModal: boolean
  setVisiblePaymentModal: (boolean) => void
  cardNumber: number | undefined
  goodThru: number | undefined
  securityCode: number | undefined
  email: string
  isUnderPayment: boolean
  setSelectedCreditCard: (creditCard: any) => void
  setCardNumber: (cardNumber: number) => void
  setEmail: (email: string) => void
  setGoodThru: (goodThru: number) => void
  setSecurityCode: (securityCode: number) => void
  submitPayment: () => void
}

export const PaymentScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()
  const params = useParams()
  const stripe = useStripe()
  const elements = useElements()

  const [actions, setActions] = useState<ActionCardModel[]>([])
  const [possibleViewerNumber, setPossibleViewerNumber] = useState<number>(0)
  const [entryAmount, setEntryAmount] = useState<number>(0)
  const [totalAmount, setTotalAmount] = useState<number>(0)
  const [userInfo, setUserInfo] = useState<any>()
  const [creditCards, setCreditCards] = useState<any>([])
  const [selectedCreditCard, setSelectedCreditCard] = useState<any>()
  const [visiblePaymentModal, setVisiblePaymentModal] = useState<boolean>(false)
  const [isUnderPayment, setIsUnderPayment] = useState<boolean>(false)
  const [cardNumber, setCardNumber] = useState<number>()
  const [email, setEmail] = useState<string>('')
  const [goodThru, setGoodThru] = useState<number>()
  const [securityCode, setSecurityCode] = useState<number>()
  const [uid, setUid] = useState<string>('')
  const { id: actionId } = params

  useEffect(() => {
    const setup = async () => {
      await firebase.auth().onAuthStateChanged(user => {
        if (user) {
          //会員登録後、メール認証したか
          if (!user.emailVerified) {
            goToSignupFinish(navigation)
          } else if (user) {
            setUid(user.uid)
            _fetchActions()
          }
        } else {
          goToSignin(navigation)
        }
      })
    }
    setup()
  }, [])

  useEffect(() => {
    if (uid) {
      firebase
        .auth()
        .currentUser?.getIdToken(true)
        .then(() => stripeCreateCustomer())
      _fetchUserInfo()
      _fetchUserCreditCards()
    }
  }, [uid])

  useEffect(() => {
    if (userInfo) {
      _isJoin()
    }
  }, [userInfo])

  const _isJoin = () => {
    const joinActions = userInfo.joinActions ? userInfo.joinActions : []
    if (joinActions.includes(actionId)) {
      goToActionDetail(navigation, actionId)
    }
  }

  const _fetchActions = async () => {
    const actions = await firebase
      .firestore()
      .collection(`actions`)
      .get()
      .then(qs =>
        qs.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        }),
      )

    setActions(actions)

    actions.map((item: ActionCardModel) => {
      if (item.id === actionId) {
        setEntryAmount(Number(item.entryAmount))
        setPossibleViewerNumber(1)
        // 合計金額の計算
        const total = Number(item.entryAmount) * 1
        setTotalAmount(total)
      }
    })
  }

  const _fetchUserInfo = async () => {
    const userInfo = await fetchUserInfo(uid)
    setUserInfo(userInfo)
  }

  const _fetchUserCreditCards = async () => {
    const creditCards = await fetchUserCreditCards(uid)
    setCreditCards(creditCards)
    setSelectedCreditCard(creditCards[0])
  }

  const _submitPayment = async () => {
    if (!stripe || !elements || creditCards.length === 0 || isUnderPayment) {
      return
    }

    setIsUnderPayment(true)
    const paymentResponse = await stripePaymentIntent(selectedCreditCard, totalAmount)
    if (paymentResponse.success) {
      alert('決済完了しました。')
      const actionTitle = actions.map((item: ActionCardModel) => {
        if (item.id === actionId) {
          return item.title
        }
      })
      const data = {
        email: userInfo.email,
        name: userInfo.name ? userInfo.name : userInfo.nickname,
        actionTitle,
        totalAmount,
      }
      sendMailForPayment(data)
      savePaymentInfo(paymentResponse.data, paymentResponse.orderId, actionId, userInfo).then(() => {
        setIsUnderPayment(false)
        goToPaymentFinish(navigation, actionId)
      })
      return
    }
    alert('決済失敗しました。時間を置いてから再度やり直してください。')
    return
  }

  return (
    <>
      <UI
        navigation={navigation}
        actions={actions}
        possibleViewerNumber={possibleViewerNumber}
        entryAmount={entryAmount}
        totalAmount={totalAmount}
        actionId={actionId}
        creditCards={creditCards}
        selectedCreditCard={selectedCreditCard}
        userInfo={userInfo}
        setSelectedCreditCard={setSelectedCreditCard}
        visiblePaymentModal={visiblePaymentModal}
        setVisiblePaymentModal={setVisiblePaymentModal}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
        email={email}
        setEmail={setEmail}
        goodThru={goodThru}
        setGoodThru={setGoodThru}
        securityCode={securityCode}
        setSecurityCode={setSecurityCode}
        submitPayment={_submitPayment}
        isUnderPayment={isUnderPayment}
      />
    </>
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  navigation,
  actions,
  possibleViewerNumber,
  entryAmount,
  totalAmount,
  actionId,
  creditCards,
  selectedCreditCard,
  userInfo,
  setSelectedCreditCard,
  visiblePaymentModal,
  setVisiblePaymentModal,
  setEmail,
  cardNumber,
  setCardNumber,
  goodThru,
  setGoodThru,
  securityCode,
  setSecurityCode,
  submitPayment,
  isUnderPayment,
}) => {
  return (
    <View>
      <Header />
      <Text type={'xxlarge'} style={styles.containerTitle}>
        決済方法の確認
      </Text>
      <View style={styles.paymentContainer}>
        <View style={styles.paymentInner}>
          <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
            <View style={styles.container}>
              <View style={styles.actionCard}>
                {actions &&
                  actions.map((item: ActionCardModel, index) => {
                    if (item.id === actionId) {
                      return <ActionCard action={item} key={index} />
                    }
                  })}
              </View>
              <View style={styles.paymentDetails}>
                <View style={styles.entryAmountContainer}>
                  <Text type={'small'}>参加費</Text>
                  <Text style={styles.entryAmount} type={'xxlarge'}>
                    ¥{entryAmount}
                  </Text>
                </View>
                <View style={styles.possibleViewerNumberContainer}>
                  <Text type={'small'}>参加人数</Text>
                  <Text style={styles.possibleViewerNumber} type={'xxlarge'}>
                    {possibleViewerNumber}
                  </Text>
                </View>
                <View style={styles.paymentDetailBorder} />
                <View style={styles.paymentAmountContainer}>
                  <Text type={'small'}>合計金額</Text>
                  <Text style={styles.paymentAmount} type={'xxlarge'}>
                    ¥{totalAmount}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.methodOfPaymentContents}>
              {creditCards.length !== 0 ? (
                <>
                  {creditCards.map(item => (
                    <View style={styles.creditPay} key={item.fingerprint}>
                      <View style={styles.radioBtn}>
                        <RadioButton
                          value={item}
                          status={selectedCreditCard && selectedCreditCard.fingerprint === item.fingerprint ? 'checked' : 'unchecked'}
                          color="#036EB8"
                          onPress={() => setSelectedCreditCard(item)}
                        />
                        <CreditCard last4={item.last4} />
                      </View>
                    </View>
                  ))}
                </>
              ) : (
                <TouchableOpacity style={styles.paymentSetting} onPress={() => goToPaymentSetting(navigation, actionId)}>
                  <Text type={'large'}>決済情報の登録</Text>
                  <View style={styles.arrow}>
                    <AntDesign name="right" size={15} color="black" />
                  </View>
                </TouchableOpacity>
              )}
            </View>
            <View style={styles.submitButtonContainer}>
              {creditCards.length === 0 ? (
                <Button onPress={submitPayment} buttonType={7}>
                  決済
                </Button>
              ) : (
                <Button onPress={submitPayment} buttonType={isUnderPayment ? 7 : 1}>
                  {isUnderPayment ? `決済中...` : `決済`}
                </Button>
              )}
            </View>
            <Footer />
          </ScrollView>
        </View>
      </View>
      {visiblePaymentModal && (
        <PaymentModal
          setVisiblePaymentModal={setVisiblePaymentModal}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
          email={userInfo.email}
          setEmail={setEmail}
          goodThru={goodThru}
          setGoodThru={setGoodThru}
          securityCode={securityCode}
          setSecurityCode={setSecurityCode}
        />
      )}
      {/* {isWeb && <TabBar />} */}
    </View>
  )
}

const styles = StyleSheet.create({
  paymentContainer: {
    backgroundColor: white,
    transform: [{ skewY: '-20deg' }],
    marginTop: 270,
  },
  paymentInner: {
    backgroundColor: '#FAFAFA',
  },
  scrollContainer: {
    marginTop: -270,
    marginBottom: -150,
    transform: [{ skewY: '20deg' }],
  },
  scrollContents: {},
  containerTitle: {
    textAlign: 'center',
    marginTop: 70,
    marginBottom: 25,
  },
  container: {
    flexDirection: 'row',
  },
  actionCard: {
    marginLeft: 15,
    width: 250,
    height: 450,
    zIndex: 1,
  },
  paymentDetails: {
    alignItems: 'flex-end',
    position: 'absolute',
    bottom: 10,
    right: 0,
    marginRight: 10,
  },
  entryAmountContainer: {
    alignItems: 'flex-end',
    marginBottom: 15,
  },
  entryAmount: {
    fontSize: 24,
  },
  possibleViewerNumberContainer: {
    alignItems: 'flex-end',
    marginBottom: 5,
  },
  possibleViewerNumber: {
    fontSize: 24,
  },
  paymentDetailBorder: {
    width: 350,
    borderBottomColor: borderLightGrayColor,
    borderBottomWidth: 2,
    marginBottom: 5,
  },
  paymentAmountContainer: {
    alignItems: 'flex-end',
  },
  paymentAmount: {
    fontSize: 24,
    color: primaryColor,
  },
  methodOfPaymentContents: {
    marginTop: 50,
  },
  creditPay: {
    height: 60,
    backgroundColor: white,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24,
  },
  linePay: {
    backgroundColor: white,
  },
  radioBtn: {
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 15,
  },
  label: {
    marginLeft: 10,
  },
  actionBtn: {
    marginTop: 45,
  },
  payLabel: {
    textAlign: 'center',
    marginTop: 7,
    marginBottom: 90,
  },
  paymentSetting: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
  },
  arrow: {
    marginLeft: 8,
    marginTop: 2,
  },
  submitButtonContainer: {
    marginBottom: 20,
  },
})
