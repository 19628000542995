import React from 'react'
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native'

// libs
import { images } from 'app/src/libs/images'
import { goToAreaDetail } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// components
import { Text } from 'app/src/components/basics'

type Props = {
  area: number
}

export const DonationSelectView: React.FC<Props> = ({ area }) => {
  const navigation = useNavigation()

  const areaName = () => {
    if (area === 1) return '森林保護'
    if (area === 2) return '海洋保護'
    if (area === 3) return '動物保護'
    if (area === 4) return '貧困問題'
    if (area === 5) return '教育'
    if (area === 6) return '医療・災害'
    if (area === 7) return 'クリーンエネルギー'
    if (area === 8) return '研究開発'
  }

  return (
    <View style={styles.donationSelectView}>
      <Image style={styles.areaIconImage} source={images[`areaIcon${area}`]} />
      <View style={styles.donationSelectViewContents}>
        <View style={styles.viewTop}>
          <Text>{areaName()}</Text>
          <TouchableOpacity onPress={() => goToAreaDetail(navigation, area)}>
            <Image style={styles.btnSmallDetailImage} source={images['btnSmallDetail']} />
          </TouchableOpacity>
        </View>
        <View style={styles.viewBottom}>
          <Text>スライダー</Text>
          <Text>68%</Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  donationSelectView: {
    width: 320,
    marginBottom: 22,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  donationSelectViewContents: {
    flexDirection: 'column',
  },
  areaIconImage: {
    width: 78,
    height: 78,
    marginRight: 10,
  },
  viewTop: {
    width: 230,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  btnSmallDetailImage: {
    width: 80,
    height: 28,
    resizeMode: 'contain',
  },
  viewBottom: {
    width: 230,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})
