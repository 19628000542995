import React, { useState } from 'react'
import { StyleSheet, View, ImageBackground } from 'react-native'

// constants
import { windowWidth, windowHeight } from 'app/src/constants/layout'
import { primaryColor } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { CountdownTimer } from 'app/src/components/advanced/countdown-timer'
import { ShareModal } from 'app/src/components/advanced/share-modal'
import { ActionLive } from 'app/src/components/advanced/live/action-live'

//libs
import { images } from 'app/src/libs/images'

type Props = {
  visibleShareModal: boolean
  setVisibleShareModal: (boolean) => void
}

export const LiveBeforeViewer: React.FC = () => {
  const [visibleShareModal, setVisibleShareModal] = useState<boolean>(false)
  return <UI visibleShareModal={visibleShareModal} setVisibleShareModal={setVisibleShareModal} />
}

const UI: React.FC<Props> = ({ visibleShareModal, setVisibleShareModal }) => {
  return (
    <View style={styles.container}>
      <ImageBackground style={styles.imageContainer} source={images['liveWait']} imageStyle={{ resizeMode: 'cover' }}>
        <ActionLive setVisibleShareModal={setVisibleShareModal} />

        <View style={styles.countdownContainer}>
          <Text type={'medium'}>始まるまで</Text>
          <View style={styles.timerContent}>
            <CountdownTimer>
              <Text style={styles.countdownText}>7:21</Text>
            </CountdownTimer>
          </View>
          <Text type={'large'}>今しばらくお待ちください</Text>
        </View>
      </ImageBackground>
      <ShareModal visibleShareModal={visibleShareModal} setVisibleShareModal={setVisibleShareModal} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  imageContainer: {
    width: windowWidth,
    height: windowHeight - 97,
  },
  countdownContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  timerContent: {
    marginTop: 18,
    marginBottom: 33,
  },
  countdownText: {
    fontSize: 48,
    color: primaryColor,
    fontWeight: '500',
    fontFamily: 'quicksand-medium',
  },
})
