import React, { useState, useContext } from 'react'
import { StyleSheet, View, ScrollView, Image, ImageBackground, TouchableOpacity, TextInput } from 'react-native'
import firebase from 'firebase'

// contexts
import { LayoutContext, LayoutContextType } from 'app/src/contexts/layout'

// libs
import { images } from 'app/src/libs/images'
import { firebaseAuthError } from 'app/src/libs/firebase'
import { goToSignup, goToActionDetail, goToMypage } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'
import { useParams } from 'app/src/hooks/use-params'

// constants
import { isWeb } from 'app/src/constants/platform'
import { white, darkGray } from 'app/src/constants/colors'
import { windowWidth, windowHeight } from 'app/src/constants/layout'

// components
import { Text, Button } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'

type ContainerProps = {}

type Props = {
  navigation: any
  isPc: boolean
  signin: () => void
  setEmail: (string) => void
  setPassword: (string) => void
}

export const SigninScreen: React.FC<ContainerProps> = () => {
  const { isPc } = useContext<LayoutContextType>(LayoutContext)
  const navigation = useNavigation()
  const params = useParams()

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { id: actionId } = params

  const _signinValidate = () => {
    if (!email || !password) {
      alert('未入力の項目があります。')
      return false
    }
    return true
  }

  const _signin = async () => {
    if (await _signinValidate()) {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => {
          if (actionId) {
            goToActionDetail(navigation)
          } else {
            goToMypage(navigation)
          }
        })
        .catch(async err => {
          alert(firebaseAuthError(err))
        })
    }
  }

  return <UI navigation={navigation} isPc={isPc} setEmail={setEmail} setPassword={setPassword} signin={_signin} />
}

const UI: React.FC<Props> = ({ navigation, isPc, setEmail, setPassword, signin }) => {
  return (
    <View style={styles.container}>
      {isWeb && <Header />}
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <ImageBackground style={styles.bgSignin} source={isPc ? '' : images['bgSignin']} imageStyle={{ resizeMode: 'cover' }}>
          <Text type={'xlarge'} style={[styles.pageName, isPc && { color: darkGray }]}>
            ログイン
          </Text>

          <View style={styles.textInputContainer}>
            <View style={styles.textInputView}>
              <TextInput
                style={[styles.textInput, isPc && { color: darkGray }]}
                onChange={v => setEmail(v.nativeEvent.text)}
                placeholder={'メールアドレス'}
                placeholderTextColor={isPc ? darkGray : white}
              />
            </View>

            <View style={styles.textInputView}>
              <TextInput
                style={[styles.textInput, isPc && { color: darkGray }]}
                onChange={v => setPassword(v.nativeEvent.text)}
                secureTextEntry
                placeholder={'パスワード'}
                placeholderTextColor={isPc ? darkGray : white}
              />
            </View>
            <View style={styles.signinButton}>
              <Button buttonType={4} onPress={() => signin()}>
                ログイン
              </Button>
            </View>
          </View>
          <TouchableOpacity onPress={() => goToSignup(navigation)}>
            <Text style={styles.link}>新規登録はこちら</Text>
          </TouchableOpacity>
          <Image style={styles.earthSigninImage} source={images['earthSignin']} />
        </ImageBackground>
        {isPc && <Footer />}
      </ScrollView>
      {/* {isWeb && <TabBar />} */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  scrollContainer: {
    flex: 1,
  },
  scrollContents: {
    justifyContent: 'center',
  },
  bgSignin: {
    width: '100%',
    height: windowHeight,
    position: 'relative',
  },
  earthSigninImage: {
    width: windowWidth,
    height: windowWidth * 0.7,
  },
  pageName: {
    fontSize: 16,
    color: white,
    marginTop: 82,
    marginBottom: 40,
    textAlign: 'center',
  },
  textInputContainer: {
    marginHorizontal: 'auto',
  },
  textInputView: {
    backgroundColor: 'rgba(221, 221, 221, 0.4)',
    width: 325,
    height: 48,
    marginBottom: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInput: {
    color: white,
    paddingLeft: 17,
    width: 325,
    height: 48,
    zIndex: 10,
  },
  signinButton: {
    marginTop: 50,
    marginBottom: 36,
    width: 260,
    height: 50,
    fontWeight: 'bold',
    marginHorizontal: 'auto',
  },
  link: {
    textDecorationLine: 'underline',
    textAlign: 'center',
  },
})
