import React from 'react'
import { StyleSheet, ImageBackground, View, Image } from 'react-native'

// constants
import { lightGray, borderLightGrayColor, black } from 'app/src/constants/colors'

//components
import { Text } from '.'
import RNPickerSelect from 'react-native-picker-select'

//libs
import { images } from 'app/src/libs/images'

type Props = {
  label: string
  value: string
  onValueChange: (value: string) => void
  items: {
    label: string
    value: string | undefined
  }[]
  placeholder?: {
    label: string
    value: string | undefined
  }
}

export const SelectBox: React.FC<Props> = ({
  label,
  onValueChange,
  value,
  items,
  placeholder = { label: '選択してください', value: undefined },
}) => {
  return (
    <View>
      {value !== '選択してください' ? (
        <ImageBackground style={styles.inputImage} source={images['input']}>
          <Text type={'small'} style={styles.label}>
            {label}
          </Text>
          <RNPickerSelect
            style={{ ...pickerSelectStyles }}
            onValueChange={onValueChange}
            value={value}
            placeholder={placeholder}
            items={items}
            useNativeAndroidPickerStyle={false}
          />
          <Image style={styles.arrowIcon} source={images['iconArrowDown']} />
        </ImageBackground>
      ) : (
        <View style={styles.inputBlank}>
          <Text type={'small'} style={styles.label}>
            {label}
          </Text>
          <View style={styles.blank}>
            <RNPickerSelect
              style={{ ...pickerSelectStyles }}
              onValueChange={onValueChange}
              value={value}
              placeholder={placeholder}
              items={items}
              useNativeAndroidPickerStyle={false}
            />
          </View>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  inputImage: {
    justifyContent: 'flex-end',
    paddingBottom: 11,
    width: 313,
    height: 46,
    position: 'relative',
  },
  inputBlank: {
    justifyContent: 'flex-end',
    width: 313,
    height: 46,
    position: 'relative',
  },
  label: {
    color: lightGray,
    zIndex: 1,
    position: 'absolute',
    top: 3,
    left: 10,
  },
  blank: {
    width: 313,
    height: 46,
    backgroundColor: borderLightGrayColor,
  },
  arrowIcon: {
    width: 11,
    height: 11,
    position: 'absolute',
    right: 10,
    bottom: 15,
    resizeMode: 'contain',
  },
})

const pickerSelectStyles = StyleSheet.create({
  inputIOS: {
    backgroundColor: 'transparent',
    color: black,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 10,
    paddingTop: 22,
  },
  inputAndroid: {
    backgroundColor: 'transparent',
    color: black,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 10,
    paddingTop: 22,
  },
  inputWeb: {
    backgroundColor: 'transparent',
    color: black,
    textAlign: 'center',
    fontSize: 12,
    lineHeight: 10,
    paddingTop: 22,
  },
})
