import React from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'

// constants
import { isWeb } from 'app/src/constants/platform'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'

type ContainerProps = {}

type Props = {}

export const PrivacyScreen: React.FC<ContainerProps> = () => {
  return <UI />
}

const UI: React.FC<Props & ContainerProps> = () => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.headerContent}>
            <Text type={'xxlarge'} align={'center'} style={styles.titleWrapper}>
              個人情報保護方針
            </Text>
            <View style={styles.content}>
              <Text type={'medium'}>
                株式会社beero（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおり個人情報保護方針（以下，「本ポリシー」といいます。）を定めます。
              </Text>
            </View>
          </View>
          <View style={styles.mainContent}>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                1.個人情報
              </Text>
              <Text type={'medium'}>
                「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，メールアドレスその他の記述等により特定の個人を識別できる情報及び容貌から特定の個人を識別できる情報（個人識別情報）を指します。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                2.個人情報の収集方法
              </Text>
              <Text type={'medium'}>
                当社は，ユーザーが本サービスの利用登録をする際に氏名，生年月日，メールアドレス，クレジットカード番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                3.個人情報を収集・利用する目的
              </Text>
              <Text type={'medium'}>当社が個人情報を収集・利用する目的は，以下のとおりです。</Text>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>当社サービス提供・運営のため</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>メンテナンス，重要なお知らせなど必要に応じたご連絡のため</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>有料サービスにおいて，ユーザーに利用料金を請求するため</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>上記の利用目的に付随する目的</Text>
              </View>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                4.利用目的の変更
              </Text>
              <Text type={'medium'}>
                当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとし、利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。
              </Text>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                5.個人情報の第三者提供
              </Text>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  5.1.
                </Text>
                <Text type={'medium'}>
                  当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき</Text>
              </View>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  5.2.
                </Text>
                <Text type={'medium'}>
                  当社は前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>合併その他の事由による事業の承継に伴って個人情報が提供される場合</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合
                </Text>
              </View>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                6.個人情報の開示
              </Text>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  6.1.
                </Text>
                <Text type={'medium'}>
                  当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>その他法令に違反することとなる場合</Text>
              </View>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  6.2.
                </Text>
                <Text type={'medium'}>
                  前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。
                </Text>
              </View>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                7.個人情報の訂正及び削除
              </Text>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>
                  当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。
                </Text>
              </View>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                8.個人情報の利用停止等
              </Text>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  8.1.
                </Text>
                <Text type={'medium'}>
                  当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  8.2.
                </Text>
                <Text type={'medium'}>
                  前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  8.3.
                </Text>
                <Text type={'medium'}>
                  当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  8.4.
                </Text>
                <Text type={'medium'}>
                  前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。
                </Text>
              </View>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                9.プライバシーポリシーの変更
              </Text>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  9.1.
                </Text>
                <Text type={'medium'}>
                  本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。
                </Text>
              </View>
              <View style={styles.listItem}>
                <Text type={'medium'} style={styles.numberBulletPoint}>
                  9.2.
                </Text>
                <Text type={'medium'}>
                  当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。
                </Text>
              </View>
            </View>
            <View style={styles.content}>
              <Text type={'xlarge'} style={styles.titleWrapper}>
                10.お問い合わせ窓口
              </Text>
              <Text type={'medium'}>本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。</Text>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>社名：株式会社beero</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>住所：東京都渋谷区東1-13-1-410</Text>
              </View>
              <View style={styles.listItem}>
                <Text style={styles.bulletPoint}>・</Text>
                <Text type={'medium'}>連絡先：contact@beero.co.jp</Text>
              </View>
            </View>
          </View>
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  headerContent: {
    marginTop: 20,
    marginHorizontal: 28,
  },
  mainContent: {
    marginHorizontal: 28,
    fontSize: 14,
  },
  content: {
    paddingBottom: 40,
  },
  titleWrapper: {
    marginBottom: 10,
  },
  listItem: {
    flexDirection: 'row',
  },
  bulletPoint: {
    fontSize: 30,
  },
  numberBulletPoint: {
    marginRight: 10,
  },
})
