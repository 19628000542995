import React, { useEffect } from 'react'
import { StyleSheet, View, ScrollView, TouchableOpacity } from 'react-native'
import firebase from 'firebase/app'
import { MaterialIcons } from '@expo/vector-icons'

// libs
import {
  goToSignin,
  goToAccountSetting,
  goToPaymentSetting,
  goToQa,
  goToContact,
  goToPrivacy,
  goToTerms,
  goToCompany,
} from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { Footer } from 'app/src/components/advanced/footer'

type ContainerProps = {}

type Props = {
  settingItems: listItem[]
  QuestionItems: listItem[]
  otherItems: listItem[]
  renderList: (label: string, screenTransition: any) => JSX.Element
  signOut: () => void
}

type listItem = {
  label: string
  screenTransition: (navigation: any, id?: string) => void
}

export const SettingListScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  const settingItems = [
    {
      label: 'アカウント設定',
      screenTransition: goToAccountSetting,
    },
    {
      label: '決済設定',
      screenTransition: goToPaymentSetting,
    },
  ]

  const QuestionItems = [
    {
      label: 'よくある質問',
      screenTransition: goToQa,
    },
    {
      label: 'お問い合わせ',
      screenTransition: goToContact,
    },
  ]

  const otherItems = [
    {
      label: '個人情報保護方針',
      screenTransition: goToPrivacy,
    },
    {
      label: '利用規約',
      screenTransition: goToTerms,
    },
    {
      label: '運営会社',
      screenTransition: goToCompany,
    },
  ]

  const _signOut = async () => {
    await firebase.auth().signOut()
  }

  const _renderList = (label, screenTransition) => (
    <TouchableOpacity onPress={() => screenTransition(navigation)} style={styles.listItemContent}>
      <Text>{label}</Text>
      <MaterialIcons name="keyboard-arrow-right" size={18} style={styles.arrowRight} />
    </TouchableOpacity>
  )

  useEffect(() => {
    const fetchSettingList = async () => {
      await firebase.auth().onAuthStateChanged(user => {
        if (!user) goToSignin(navigation)
      })
    }
    fetchSettingList()
  }, [])

  return (
    <UI settingItems={settingItems} QuestionItems={QuestionItems} otherItems={otherItems} renderList={_renderList} signOut={_signOut} />
  )
}

const UI: React.FC<Props & ContainerProps> = ({ settingItems, QuestionItems, otherItems, renderList, signOut }) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.headerContent}>
            <Text type={'xxlarge'} align={'center'}>
              設定
            </Text>
          </View>
          <View style={styles.line} />
          {settingItems.map((item, index) => (
            <View style={styles.listItem} key={index}>
              {renderList(item.label, item.screenTransition)}
            </View>
          ))}
          <View style={styles.line} />
          {QuestionItems.map((item, index) => (
            <View style={styles.listItem} key={index}>
              {renderList(item.label, item.screenTransition)}
            </View>
          ))}
          <View style={styles.line} />
          {otherItems.map((item, index) => (
            <View style={styles.listItem} key={index}>
              {renderList(item.label, item.screenTransition)}
            </View>
          ))}
          <View style={styles.listItem}>
            <TouchableOpacity onPress={signOut} style={styles.listItemContent}>
              <Text style={styles.signOutText}>ログアウト</Text>
            </TouchableOpacity>
          </View>
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
    paddingBottom: 100,
  },
  scrollContainer: {
    flex: 1,
  },
  headerContent: {
    marginVertical: 24,
    marginHorizontal: 20,
  },
  line: {
    height: 6,
    width: '100%',
    backgroundColor: '#F4F4F4',
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: '#F4F4F4',
  },
  listItemContent: {
    height: 65,
    marginLeft: 40,
    marginRight: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  arrowRight: {
    color: '#cccccc',
  },
  signOutText: {
    color: '#B00020',
  },
})
