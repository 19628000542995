import React from 'react'
import { StyleSheet, ImageBackground, TextInput as RNTextInput, View, KeyboardType, Platform } from 'react-native'

// constants
import { lightGray } from 'app/src/constants/colors'

//components
import { Text } from './index'

//libs
import { images } from 'app/src/libs/images'

type Props = {
  label: string
  onChangeText: (value: string) => void
  value: string
  validation?: string
  placeholder?: string
  translucentGrayBackground?: boolean
  secureTextEntry?: boolean
  keyboardType?: KeyboardType
  isRequired?: boolean
  numberOfLines?: number
}

export const TextInput: React.FC<Props> = ({
  label,
  onChangeText,
  value = '',
  validation,
  placeholder,
  translucentGrayBackground = false,
  secureTextEntry = false,
  keyboardType,
  isRequired = false,
  numberOfLines = 0,
}) => {
  return (
    <View style={styles.formInput}>
      <Text type={'small'} style={styles.validation}>
        {validation}
      </Text>
      <ImageBackground
        style={styles.inputImage}
        source={images[`${translucentGrayBackground ? 'inputBackground' : 'input'}`]}
        resizeMode={'stretch'}
      >
        <View style={styles.labelWrapper}>
          <Text type={'small'} style={styles.label}>
            {label}
          </Text>
          {isRequired && <Text style={styles.labelRequired}>●</Text>}
        </View>
        <RNTextInput
          style={numberOfLines ? styles.textArea : styles.input}
          multiline={numberOfLines ? true : false}
          numberOfLines={numberOfLines && numberOfLines}
          onChangeText={text => onChangeText(text)}
          value={value}
          placeholder={placeholder}
          secureTextEntry={secureTextEntry}
          keyboardType={keyboardType}
        />
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  formInput: {
    width: '100%',
    maxWidth: 310,
    marginHorizontal: 32,
    marginBottom: 20,
  },
  inputImage: {
    width: '100%',
    minHeight: 46,
  },
  labelWrapper: {
    flexDirection: 'row',
    marginBottom: 1,
    position: 'absolute',
    top: 5,
    left: 10,
  },
  label: {
    fontSize: 8,
    color: lightGray,
    marginRight: 2,
  },
  labelRequired: {
    marginTop: -1,
    marginLeft: 2,
    fontSize: 8,
    color: '#cc0000',
  },
  input: {
    fontSize: 12,
    flex: 1,
    paddingHorizontal: 10,
    paddingTop: 16,
    textAlign: 'center',
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  textArea: {
    fontSize: 12,
    flex: 1,
    paddingHorizontal: 10,
    paddingTop: 26,
    ...Platform.select({
      web: {
        outlineWidth: 0,
      },
    }),
  },
  validation: {
    color: '#CC0000',
    position: 'absolute',
    left: 0,
    top: -18,
  },
})
