import React, { useState } from 'react'
import { StyleSheet, View, ScrollView, TouchableOpacity, ImageBackground, Image } from 'react-native'
import { TabView, TabBar as PortfolioTabBar, SceneMap, SceneRendererProps } from 'react-native-tab-view'

// libs
import { images } from 'app/src/libs/images'

// constants
import { isWeb } from 'app/src/constants/platform'
import { windowWidth } from 'app/src/constants/layout'
import { primaryColor, borderLightGrayColor, white, darkGray, lightGray } from 'app/src/constants/colors'

// components
import { Text, Button } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ShareList } from 'app/src/components/advanced/share-list'
import { PortfolioReturnHistoryList } from 'app/src/components/advanced/portfolio/return-history-list'
import { PortfolioWithdrawalHistoryList } from 'app/src/components/advanced/portfolio/withdrawal-history-list'
import { DonationAreaList } from 'app/src/components/advanced/portfolio/donation-area-list'
import { DonationSelectView } from 'app/src/components/advanced/donation-select'
import { PortfolioModal } from 'app/src/components/advanced/portfolio-modal'

type ContainerProps = {}

type Props = {
  renderTabBar: (props) => any
  renderScene: any
  index: number
  setIndex: (number) => void
  routes: routesType[]
}

type routesType = {
  key: string
}

export const PortfolioScreen: React.FC<ContainerProps> = props => {
  const [index, setIndex] = useState<number>(0)
  const [routes] = useState<routesType[]>([{ key: 'areaConfig' }, { key: 'returnHistory' }, { key: 'withdrawalHistory' }])

  const AreaConfigRouteView = () => {
    return <DonationAreaList />
  }

  const ReturnHistoryRouteView = () => {
    return <PortfolioReturnHistoryList />
  }

  const WithdrawalHistoryRouteView = () => {
    return <PortfolioWithdrawalHistoryList />
  }
  const renderScene = SceneMap({
    areaConfig: AreaConfigRouteView,
    returnHistory: ReturnHistoryRouteView,
    withdrawalHistory: WithdrawalHistoryRouteView,
  })

  const getTabBar = ({ route, focused }) => {
    if (route.key === 'areaConfig') {
      return (
        <View style={styles.areaConfig}>
          <View>
            <Image style={styles.activeTabIcon} source={images['soundMix']} />
          </View>
          <View>
            <Text type={'small'} style={[focused ? styles.activeTabText : {}]}>
              寄付エリア設定
            </Text>
          </View>
        </View>
      )
    } else if (route.key === 'returnHistory') {
      return (
        <Text type={'small'} style={[focused ? styles.activeTabText : {}]}>
          寄付実績一覧
        </Text>
      )
    } else if (route.key === 'withdrawalHistory') {
      return (
        <Text type={'small'} style={[focused ? styles.activeTabText : {}]}>
          出金履歴
        </Text>
      )
    }
  }

  const renderTabBar = (props: SceneRendererProps & { navigationState: any }) => (
    <PortfolioTabBar
      {...props}
      renderIcon={props => getTabBar(props)}
      indicatorStyle={styles.indicator}
      style={styles.tabbar}
      labelStyle={styles.label}
      tabStyle={styles.tab}
    />
  )

  return (
    <>
      <UI renderTabBar={renderTabBar} renderScene={renderScene} index={index} setIndex={setIndex} routes={routes} />
    </>
  )
}

const UI: React.FC<Props & ContainerProps> = ({ renderTabBar, renderScene, index, setIndex, routes }) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
          <View style={styles.totalDonation}>
            <Text>今月の寄付総額</Text>
            <Text style={styles.numberText} font={'quick-r'}>
              ¥50,000
            </Text>
          </View>
          <View style={styles.donationButton}>
            <TouchableOpacity>
              <Image style={styles.donationButtonImage} source={images['btnSmalWithdrawal']} />
            </TouchableOpacity>
          </View>
          <View style={styles.actionProfit}>
            <Text>アクション収益</Text>
            <Text style={styles.numberText} font={'quick-r'}>
              ¥150,000
            </Text>
          </View>

          <Button
            buttonType={2}
            onPress={() => {
              setOpen(true)
            }}
          >
            今すぐ寄付する
          </Button>
          {open && <PortfolioModal setOpen={setOpen} />}

          <ImageBackground style={styles.donationGraphBg} source={images['earthPortfolio']} imageStyle={{ resizeMode: 'cover' }}>
            <Image style={styles.graphType1BgImage} source={images['graphType1Bg']} />
          </ImageBackground>

          <View style={styles.changeViewBar}>
            <TouchableOpacity>
              <Image style={styles.changeViewButton} source={images['btnViewAccumulation']} />
            </TouchableOpacity>
            <TouchableOpacity>
              <Image style={styles.changeViewButton} source={images['btnViewList']} />
            </TouchableOpacity>
          </View>

          <ShareList />

          <Text style={styles.smallText} type={'small'}>
            あなたの実績をシェア
          </Text>
          <TabView
            style={styles.tabContainer}
            renderTabBar={renderTabBar}
            navigationState={{ index, routes }}
            renderScene={renderScene}
            onIndexChange={setIndex}
          />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: white,
    position: 'relative',
  },
  scrollContents: {
    alignItems: 'center',
  },
  totalDonation: {
    width: 345,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 32,
    paddingHorizontal: 24,
    borderBottomColor: borderLightGrayColor,
    borderBottomWidth: 1,
  },
  numberText: {
    fontSize: 24,
    lineHeight: 24,
  },
  donationButton: {
    width: 345,
    justifyContent: 'flex-end',
    marginTop: 20,
    marginBottom: 30,
    paddingHorizontal: 24,
    alignItems: 'flex-end',
  },
  donationButtonImage: {
    width: 70,
    height: 28,
  },
  actionProfit: {
    width: 345,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 38,
    paddingHorizontal: 24,
  },
  donationGraphBg: {
    marginTop: 222,
    width: windowWidth,
    height: windowWidth * 0.7,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  graphType1BgImage: {
    alignItems: 'center',
    marginTop: -199,
    width: 350,
    height: 350,
  },
  changeViewBar: {
    width: 208,
    height: 28,
    marginTop: -80,
    marginBottom: 80,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  changeViewButton: {
    width: 98,
    height: 28,
    resizeMode: 'contain',
  },
  smallText: {
    marginTop: 12,
    marginBottom: 30,
  },
  tabContainer: {
    width: windowWidth,
  },
  areaConfig: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  activeTabText: {
    color: darkGray,
  },
  activeTabIcon: {
    width: 14,
    height: 15,
    marginRight: 9,
  },
  indicator: {
    backgroundColor: primaryColor,
    color: lightGray,
    height: 3,
  },
  tabbar: {
    marginHorizontal: 10,
    backgroundColor: white,
  },
  label: {
    color: darkGray,
  },
  tab: {},
})
