import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

//libs
import { images } from 'app/src/libs/images'

type Props = {
  liveUsers?: { userImage: string }[]
}

export const LiveUserList: React.FC<Props> = ({ liveUsers }) => {
  return (
    <View style={styles.listContainer}>
      {liveUsers &&
        liveUsers.map((_item, index) => {
          return (
            <View key={index} style={{ left: -4 * index }}>
              <Image style={styles.liveUserImage} source={images['liveUserSample']} />
            </View>
          )
        })}
    </View>
  )
}

const styles = StyleSheet.create({
  listContainer: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5,
  },
  liveUserImage: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
  },
})
