import React from 'react'
import { StyleSheet, View, Image } from 'react-native'

// libs
import { images } from 'app/src/libs/images'

// components
import { Text } from 'app/src/components/basics'
import { lightGray } from 'app/src/constants/colors'

type Props = {
  last4: number
}

export const CreditCard: React.FC<Props> = ({ last4 }) => {
  return (
    <View style={styles.cardContainer}>
      <Image style={styles.cardImage} source={images['creditCard']} />
      <View style={styles.cardInfo}>
        <Text type={'medium'} style={styles.card}>
          クレジットカード
        </Text>
        <Text type={'small'} style={styles.cardNumber}>
          xxxx - xxxx - xxxx - {last4}
        </Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardImage: {
    width: 64,
    height: 42.37,
  },
  cardInfo: {
    marginLeft: 12,
  },
  card: {
    fontWeight: '400',
  },
  cardNumber: {
    color: lightGray,
  },
})
