import React, { useState } from 'react'
import { StyleSheet, View, Image, TouchableOpacity, ImageBackground, ScrollView, CheckBox } from 'react-native'
import SelectBox from 'react-native-picker-select'
import { RadioButton } from 'react-native-paper'
import firebase from 'firebase/app'
import * as Linking from 'expo-linking'

// libs
import { images } from 'app/src/libs/images'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'
import { white, lightGray, gray, blue, darkGray, borderLightGrayColor } from 'app/src/constants/colors'

// components
import { Header } from 'app/src/components/advanced/header'
import { ActionCard } from 'app/src/components/advanced/action-card'
import { ActionCreateHeader } from 'app/src/components/advanced/action-create/header'
import { Button, Text } from 'app/src/components/basics'
import { TextInput } from 'app/src/components/basics/text-input'
import { TabBar } from 'app/src/components/advanced/tab-bar'
// import Slider from 'react-native-slider'
import { ShareList } from 'app/src/components/advanced/share-list'
import { ScrollToTop } from 'app/src/components/apps/scroll-to-top'
import { v4 as uuidv4 } from 'uuid'

type ContainerProps = {}

type Props = {
  navigation: any
  createAction: () => void
  nextPage: () => void
  backPage: () => void
  // uploadImage: (event: any) => void
  amountCalculation: (entryAmount: number, ratioAmount: number) => void
  title: string
  setTitle: any
  deliveryTime: string
  setDeliveryTime: any
  deliveryDate: string
  setDeliveryDate: any
  userName: string
  setUserName: any
  possibleViewerNumber: number
  setPossibleViewerNumber: any
  createIndex: number
  setCreateIndex: any
  imageFile: any
  setImageFile: any
  imageFileUrl: string
  setImageFileUrl: any
  entryAmount: number
  setEntryAmount: any
  serviceAmount: number
  setServiceAmount: any
  ratioAmount: number
  setRatioAmount: any
  donationAmount: number
  setDonationAmount: any
  profitAmount: number
  setProfitAmount: any
  deliveryEvent: string
  setDeliveryEvent: any
  donationArea: string
  setDonationArea: any
  deliveryPostDate: string
  setDeliveryPostDate: any
  youtubePermission: boolean
  setYoutubePermission: any
}

export const ActionCreateScreen: React.FC<ContainerProps> = props => {
  const navigation = useNavigation()

  const [title, setTitle] = useState<string>('')
  const [deliveryTime, setDeliveryTime] = useState<string>('')
  const [deliveryDate, setDeliveryDate] = useState<string>('')
  const [userName, setUserName] = useState<string>('')
  const [possibleViewerNumber, setPossibleViewerNumber] = useState<number>(0)
  const [createIndex, setCreateIndex] = useState<number>(1)
  const [imageFile, setImageFile] = useState<File>()
  const [imageFileUrl, setImageFileUrl] = useState<string>('')
  const [entryAmount, setEntryAmount] = useState<number>(0)
  const [serviceAmount, setServiceAmount] = useState<number>(0)
  const [ratioAmount, setRatioAmount] = useState<number>(0)
  const [donationAmount, setDonationAmount] = useState<number>(0)
  const [profitAmount, setProfitAmount] = useState<number>(0)
  const [deliveryEvent, setDeliveryEvent] = useState<string>('')
  const [donationArea, setDonationArea] = useState<string>('')
  const [youtubePermission, setYoutubePermission] = useState<boolean>(true)
  const [deliveryPostDate, setDeliveryPostDate] = useState<string>('')

  if (isWeb) {
    // ブラウザバック時の処理を戻るボタンと同じ処理に
    window.history.pushState(null, '', null)
    window.addEventListener('popstate', e => {
      if (createIndex < 6) {
        setCreateIndex(createIndex - 1)
      }
    })
  }

  const _createAction = async () => {
    await firebase
      .firestore()
      .collection(`actions`)
      .add({
        title,
        deliveryDate,
        deliveryTime,
        userName,
        possibleViewerNumber,
        entryAmount,
        serviceAmount,
        ratioAmount,
        donationAmount,
        profitAmount,
        deliveryEvent,
        donationArea,
        deliveryPostDate,
        imageFileUrl,
      })
    setCreateIndex(createIndex + 1)
  }

  const _nextPage = async () => {
    if (createIndex < 5) {
      setCreateIndex(createIndex + 1)
    }
  }

  const _backPage = async () => {
    if (createIndex < 6) {
      setCreateIndex(createIndex - 1)
    }
  }

  const _uploadImage = event => {
    console.log('=============', event)
    // const imageFile = event.target.files[0]
    // const imageFileName = uuidv4()
    // let storageRef = firebase
    //   .storage()
    //   .ref()
    //   .child(`actions/${imageFileName}.png`)
    // storageRef.put(imageFile).then(() => {
    //   setImageFile(imageFile)
    //   storageRef.getDownloadURL().then(function(url) {
    //     setImageFileUrl(url)
    //   })
    // })
  }

  const _amountCalculation = async (entryAmount, ratioAmount) => {
    // サービス利用料
    const serviceAmount = entryAmount * 0.1 + 110
    setServiceAmount(Math.floor(serviceAmount))

    // 参加費の残額
    const participationBalance = entryAmount - serviceAmount

    // 比率
    const ratioRemaining = 100 - ratioAmount
    setRatioAmount(ratioAmount)

    // 収益
    const profitAmount = (participationBalance * ratioAmount) / 100
    setProfitAmount(Math.floor(profitAmount))

    // 寄付額
    const donationAmount = (participationBalance * ratioRemaining) / 100
    setDonationAmount(Math.floor(donationAmount))
  }
  return (
    <UI
      navigation={navigation}
      createAction={_createAction}
      nextPage={_nextPage}
      backPage={_backPage}
      // uploadImage={() => _uploadImage(event)}
      amountCalculation={() => _amountCalculation(entryAmount, ratioAmount)}
      title={title}
      deliveryTime={deliveryTime}
      deliveryDate={deliveryDate}
      userName={userName}
      possibleViewerNumber={possibleViewerNumber}
      createIndex={createIndex}
      imageFile={imageFile}
      imageFileUrl={imageFileUrl}
      entryAmount={entryAmount}
      serviceAmount={serviceAmount}
      ratioAmount={ratioAmount}
      donationAmount={donationAmount}
      profitAmount={profitAmount}
      deliveryEvent={deliveryEvent}
      donationArea={donationArea}
      deliveryPostDate={deliveryPostDate}
      youtubePermission={youtubePermission}
      setTitle={setTitle}
      setDeliveryTime={setDeliveryTime}
      setDeliveryDate={setDeliveryDate}
      setUserName={setUserName}
      setPossibleViewerNumber={setPossibleViewerNumber}
      setCreateIndex={setCreateIndex}
      setImageFile={setImageFile}
      setImageFileUrl={setImageFileUrl}
      setEntryAmount={setEntryAmount}
      setServiceAmount={setServiceAmount}
      setRatioAmount={setRatioAmount}
      setDonationAmount={setDonationAmount}
      setProfitAmount={setProfitAmount}
      setDeliveryEvent={setDeliveryEvent}
      setDonationArea={setDonationArea}
      setDeliveryPostDate={setDeliveryPostDate}
      setYoutubePermission={setYoutubePermission}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  navigation,
  createAction,
  nextPage,
  backPage,
  // uploadImage,
  amountCalculation,
  title,
  deliveryTime,
  deliveryDate,
  userName,
  possibleViewerNumber,
  createIndex,
  imageFile,
  imageFileUrl,
  entryAmount,
  serviceAmount,
  ratioAmount,
  donationAmount,
  profitAmount,
  deliveryEvent,
  donationArea,
  deliveryPostDate,
  youtubePermission,
  setTitle,
  setDeliveryTime,
  setDeliveryDate,
  setUserName,
  setPossibleViewerNumber,
  setCreateIndex,
  setImageFile,
  setImageFileUrl,
  setEntryAmount,
  setServiceAmount,
  setRatioAmount,
  setDonationAmount,
  setProfitAmount,
  setDeliveryEvent,
  setDonationArea,
  setDeliveryPostDate,
  setYoutubePermission,
}) => {
  return (
    <View style={styles.actionCreateContainer}>
      <Header />
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <ActionCreateHeader createIndex={createIndex} />

        {createIndex === 1 && (
          <>
            <View style={styles.requiredWrapper}>
              <Text style={styles.required}>●</Text>
              <Text style={styles.requiredLabel}>必須</Text>
            </View>

            <TextInput label={'タイトル'} onChangeText={v => setTitle(v)} value={title} isRequired />
            <TextInput label={'配信日'} onChangeText={v => setDeliveryDate(v)} value={deliveryDate} isRequired />
            <TextInput label={'配信時間'} onChangeText={v => setDeliveryTime(v)} value={deliveryTime} isRequired />
          </>
        )}

        {createIndex === 2 && (
          <>
            <ScrollToTop />
            <View style={styles.requiredWrapper}>
              <Text style={styles.required}>●</Text>
              <Text style={styles.requiredLabel}>必須</Text>
            </View>
            <View style={styles.formInput}>
              <View style={styles.formLabel}>
                <Text style={styles.formTitle}>ユーザー名</Text>
                <Text style={styles.required}>●</Text>
              </View>
              <View>
                <TextInput style={styles.textInput} onChange={v => setUserName(v.nativeEvent.text)} />
              </View>
            </View>
          </>
        )}

        {createIndex === 3 && (
          <>
            <ScrollToTop />
            <Text style={styles.formGuide}>視聴できる人数を制限しますか？</Text>
            <View style={styles.requiredWrapper}>
              <Text style={styles.required}>●</Text>
              <Text style={styles.requiredLabel}>必須</Text>
            </View>
            <View style={styles.formInput}>
              <View style={styles.formLabel}>
                <Text style={styles.formTitle}>視聴可能人数</Text>
                <Text style={styles.required}>●</Text>
              </View>
              <View>
                <TextInput style={styles.textInput} onChange={v => setPossibleViewerNumber(Number(v.nativeEvent.text))} />
              </View>
            </View>
            <View style={styles.formInput}>
              <View style={styles.formLabel}>
                <Text style={styles.formTitle}>参加費</Text>
                <Text style={styles.required}>●</Text>
              </View>
              <View>
                <TextInput style={styles.textInput} onChange={v => setEntryAmount(Number(v.nativeEvent.text))} />
              </View>
            </View>
            <Text style={styles.formGuide}>
              {`
                このうち、収益としていくら手元に残したいですか？
                0%の場合は全額が寄付金となります。
              `}
            </Text>
            <View style={styles.sliderWrapper}>
              {/* <Slider
                style={styles.horizontalSlider}
                thumbStyle={styles.thumbSlider}
                minimumValue={0}
                maximumValue={100}
                minimumTrackTintColor="#cccccc"
                maximumTrackTintColor="#cccccc"
                onValueChange={v => amountCalculation(entryAmount, Math.floor(v))}
              /> */}
              <Text style={styles.sliderValue}>{ratioAmount}%</Text>
            </View>
            <View style={styles.serviceAmountArea}>
              <Text style={styles.serviceAmountLabel}>サービス利用料</Text>
              <Text style={styles.serviceAmount}>¥110 + 10% / 一人あたり</Text>
            </View>
            <View style={styles.detailsAmount}>
              <View style={styles.donationArea}>
                <View style={styles.donationAreaLabel}>
                  <Text style={styles.donationLabel}>寄付可能額（リターン）</Text>
                </View>
                <View style={styles.donationDetail}>
                  <Text style={styles.donationAmount}>¥{donationAmount}</Text>
                  <Text style={styles.donationSlash}>/</Text>
                  <Text style={styles.donationStandard}>一人あたり</Text>
                </View>
              </View>
              <View style={styles.profitArea}>
                <View style={styles.profitAreaLabel}>
                  <Text style={styles.profitLabel}>収益</Text>
                </View>
                <View style={styles.profitDetail}>
                  <Text style={styles.profitAmount}>¥{profitAmount}</Text>
                  <Text style={styles.profitSlash}>/</Text>
                  <Text style={styles.profitStandard}>一人あたり</Text>
                </View>
              </View>
            </View>
            <View style={styles.donationMeans}>
              <Text style={styles.donationMeansLabel}>最後に、寄付の仕方を決めてください</Text>
              <View style={styles.freeDesignation}>
                <View style={styles.radiobutton}>
                  <RadioButton value="" status="checked" color="#036EB8" />
                </View>
                <View style={styles.detailArea}>
                  <Text style={styles.detail}>参加者自由指定</Text>
                  <Text style={styles.subDetail}>視聴者それぞれが自身の寄付したいところに寄付できます</Text>
                </View>
              </View>
              <View style={styles.designateDorganizer}>
                <View style={styles.radiobutton}>
                  <RadioButton value="" status="checked" color="#036EB8" />
                </View>
                <View style={styles.detailArea}>
                  <Text style={styles.detail}>主催者指定</Text>
                  <Text style={styles.subDetail}>寄付先を主催者が指定することができます</Text>
                </View>
              </View>
            </View>
            <View style={styles.selectArea}>
              <Text style={styles.selectLabel}>エリア指定</Text>
              <SelectBox
                onValueChange={value => setDonationArea(value)}
                items={[
                  { label: '森林保護', value: '森林保護' },
                  {
                    label: '日本沙漠緑化実践協会',
                    value: '日本沙漠緑化実践協会',
                  },
                  { label: '日本自然保護協会', value: '日本自然保護協会' },
                  { label: '海洋保護', value: '海洋保護' },
                  { label: '動物保護', value: '動物保護' },
                  { label: 'WWF', value: 'WWF' },
                  { label: '貧困問題', value: '貧困問題' },
                  { label: '教育', value: '教育' },
                  { label: '医療・災害', value: '医療・災害' },
                  { label: 'クリーンエネルギー', value: 'クリーンエネルギー' },
                  { label: '研究開発', value: '研究開発' },
                ]}
                placeholder={{ label: '指定なし', value: '' }}
              />
            </View>
          </>
        )}

        {createIndex === 4 && (
          <>
            <ScrollToTop />
            <View style={styles.youtubePermission}>
              <View style={styles.checkBox}>
                <CheckBox value={youtubePermission} style={styles.checkBox} onValueChange={setYoutubePermission} />
                <Text style={styles.permissionLabel}>Youtube掲載許可</Text>
              </View>
              <View style={styles.permissionDetail}>
                <Text style={styles.permissionSubLabel}>
                  meguをより多くの人にしってもらうためにアクション終了後、一定期間後に一部または全てを
                  <Text style={styles.youtubeLinkText} onPress={() => Linking.openURL('https://www.youtube.com/?gl=JP')}>
                    meguのYoutubeチャンネル
                  </Text>
                  に掲載いたします。
                </Text>
              </View>
            </View>
            <View style={styles.expectedDeliveryDateArea}>
              <Text style={styles.expectedDeliveryDateLabel}>配信後</Text>
              <SelectBox
                onValueChange={value => setDeliveryPostDate(value)}
                items={[
                  { label: '翌日', value: '翌日' },
                  { label: '3日後', value: '3日後' },
                  { label: '2週間後', value: '2週間後' },
                  { label: '1ヶ月後', value: '1ヶ月後' },
                  { label: '3ヶ月後', value: '3ヶ月後' },
                  { label: '半年後', value: '半年後' },
                  { label: '1年後', value: '1年後' },
                ]}
                placeholder={{ label: '1週間後', value: '1週間後' }}
              />
            </View>
            <View style={styles.textAreaContainer}>
              <Text style={styles.textAreaLabel}>アクション詳細</Text>
              <TextInput
                style={styles.textArea}
                underlineColorAndroid="transparent"
                placeholder={'どんなイベントか簡単にご説明ください。\n\nハッシュタグを記載しておくと検索されやすくなります。'}
                placeholderTextColor="#999999"
                numberOfLines={10}
                multiline={true}
                onChange={v => setDeliveryEvent(v.nativeEvent.text)}
              />
            </View>
            <Text style={styles.formTextareaGuide}>※ハッシュタグを記載しておくと検索されやすくなります</Text>
          </>
        )}

        {createIndex === 5 && (
          <>
            <ScrollToTop />
            <View style={styles.row}>
              <View style={styles.eventCard}>
                <ActionCard action={{ title, deliveryDate, deliveryTime }} />
              </View>
              <View style={styles.uploadButton}>
                <TouchableOpacity onPress={event => console.log(event)}>
                  <Image style={styles.btaCameraImage} source={images['btnCamera']} />
                </TouchableOpacity>
              </View>
            </View>
            <Text style={styles.explanation}>画像が未設定の場合は運営によって画像が当て込まれます。</Text>
          </>
        )}

        {createIndex === 6 && (
          <>
            <ScrollToTop />
            <View style={styles.actionCardWrapper}>
              <ActionCard action={{ title, deliveryDate, deliveryTime }} />
            </View>
            <View style={styles.completedTextWrapper}>
              <Text type={'medium'}>
                アクション作成いただき、ありがとうございます。配信予定時刻10分前にメールにて通知いたします。
                また10分前から会場にもお入りいただけます。
              </Text>
            </View>
            <ShareList />
            <Text style={styles.shareText} type={'small'}>
              シェアして見に来てもらおう！
            </Text>
          </>
        )}

        {createIndex !== 5 && createIndex !== 6 && (
          <Button style={styles.btnNext} buttonType={5} onPress={nextPage}>
            次へ
          </Button>
        )}
        {createIndex === 5 && (
          <Button style={styles.btnFinish} buttonType={1} onPress={createAction}>
            完了
          </Button>
        )}
        {createIndex !== 1 && createIndex !== 6 && (
          <Button style={styles.btnBack} buttonType={5} onPress={backPage}>
            戻る
          </Button>
        )}
      </ScrollView>
      {/* {isWeb && <TabBar />} */}
    </View>
  )
}

const styles = StyleSheet.create({
  // 全体
  actionCreateContainer: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  scrollContainer: {
    backgroundColor: white,
  },
  scrollContents: {
    alignItems: 'center',
  },

  // フォーム
  requiredWrapper: {
    width: 310,
    flexDirection: 'row',
    marginBottom: 5,
    justifyContent: 'flex-end',
  },
  required: {
    marginRight: 2,
    fontSize: 8,
    color: '#cc0000',
  },
  requiredLabel: {
    fontSize: 11,
    color: lightGray,
  },
  formInput: {
    width: 370,
    height: 58,
    backgroundColor: white,
    borderStyle: 'solid',
    borderColor: blue,
    borderWidth: 1,
    marginHorizontal: 25,
    marginBottom: 20,
    paddingTop: 3,
    paddingHorizontal: 10,
  },
  formLabel: {
    flexDirection: 'row',
  },
  formTitle: {
    fontSize: 8,
    color: lightGray,
    marginRight: 2,
    marginBottom: 3,
  },
  textInput: {
    textAlign: 'center',
    borderBottomColor: white,
  },

  // ステップ3
  formGuide: {
    textAlign: 'center',
    marginTop: 7,
    marginBottom: 10,
    fontSize: 12,
    color: gray,
  },
  sliderWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  horizontalSlider: {
    width: 200,
    marginRight: 10,
  },
  sliderValue: {},
  serviceAmountArea: {
    marginTop: 20,
    marginBottom: 30,
    flexDirection: 'row',
    alignItems: 'center',
  },
  serviceAmountLabel: {
    marginHorizontal: 20,
    fontSize: 12,
    color: gray,
  },
  serviceAmount: {
    fontSize: 12,
    color: gray,
  },
  thumbSlider: {
    backgroundColor: white,
    shadowRadius: 10,
    shadowColor: 'rgba(0,0,0,0.25)',
  },
  detailsAmount: {
    flexDirection: 'row',
    marginBottom: 50,
  },
  donationArea: {
    borderStyle: 'solid',
    borderColor: blue,
    borderWidth: 1,
    paddingHorizontal: 8,
    flexDirection: 'column',
    width: 155,
    height: 50,
    marginRight: 25,
  },
  donationAreaLabel: {},
  donationLabel: {
    fontSize: 10,
    color: lightGray,
  },
  donationDetail: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  donationAmount: {
    fontWeight: '500',
    fontSize: 12,
    color: darkGray,
  },
  donationSlash: {
    marginHorizontal: 7,
    fontSize: 10,
    color: darkGray,
  },
  donationStandard: {
    fontSize: 10,
    color: darkGray,
  },
  profitArea: {
    borderStyle: 'solid',
    borderColor: blue,
    borderWidth: 1,
    paddingHorizontal: 8,
    flexDirection: 'column',
    width: 160,
    height: 50,
  },
  profitAreaLabel: {},
  profitLabel: {
    fontSize: 10,
    color: lightGray,
  },
  profitDetail: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  profitAmount: {
    fontSize: 12,
    fontWeight: '500',
    color: darkGray,
  },
  profitSlash: {
    marginHorizontal: 7,
    fontSize: 10,
    color: darkGray,
  },
  profitStandard: {
    fontSize: 10,
    color: darkGray,
  },
  donationMeans: {
    marginBottom: 25,
  },
  donationMeansLabel: {
    textAlign: 'center',
    fontSize: 12,
    color: gray,
  },
  freeDesignation: {
    flexDirection: 'row',
    marginTop: 25,
  },
  designateDorganizer: {
    marginTop: 20,
    marginBottom: 10,
    flexDirection: 'row',
  },
  radiobutton: {
    width: 35,
    height: 30,
    marginRight: 10,
  },
  detailArea: {},
  detail: {
    fontSize: 13,
    color: darkGray,
    marginBottom: 5,
  },
  subDetail: {
    fontSize: 12,
    color: gray,
  },
  selectArea: {
    backgroundColor: borderLightGrayColor,
    width: 275,
    height: 50,
    padding: 10,
  },
  selectLabel: {
    marginBottom: 6,
    fontSize: 9,
    color: lightGray,
  },

  // ステップ4
  youtubePermission: {
    width: 300,
    marginBottom: 12,
  },
  checkBox: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    borderColor: blue,
  },
  permissionLabel: {
    fontSize: 13,
  },
  permissionDetail: {},
  permissionSubLabel: {
    marginTop: 7,
    marginLeft: 27,
    fontSize: 12,
    color: gray,
    lineHeight: 17,
  },
  youtubeLinkText: {
    color: blue,
    borderBottomWidth: 1,
    borderBottomColor: blue,
  },
  expectedDeliveryDateArea: {
    marginLeft: 35,
    marginBottom: 30,
    borderStyle: 'solid',
    borderColor: blue,
    borderWidth: 1,
    width: 290,
    height: 50,
    padding: 10,
  },
  expectedDeliveryDateLabel: {
    marginBottom: 6,
    fontSize: 9,
    color: lightGray,
  },
  textAreaContainer: {
    backgroundColor: borderLightGrayColor,
    borderColor: borderLightGrayColor,
    borderWidth: 1,
    padding: 10,
    marginBottom: 11,
  },
  textAreaLabel: {
    marginBottom: 15,
    fontSize: 9,
    color: lightGray,
  },
  textArea: {
    width: 315,
    height: 250,
    justifyContent: 'flex-start',
  },
  formTextareaGuide: {
    fontSize: 12,
    color: gray,
  },

  // ステップ5
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  eventCard: {
    marginRight: 30,
  },
  uploadButton: {},
  btaCameraImage: {
    marginRight: 20,
    width: 60,
    height: 60,
  },
  explanation: {
    fontSize: 12,
    color: gray,
  },

  //ステップ6
  actionCardWrapper: {
    marginTop: 30,
  },
  completedTextWrapper: {
    width: 320,
    marginBottom: 40,
  },
  shareText: {
    marginTop: 12,
    marginBottom: 100,
  },

  // ボタン
  btnNext: {
    marginTop: 30,
  },
  btnFinish: {
    marginTop: 30,
  },
  btnBack: {
    marginTop: 20,
    marginBottom: 50,
  },
})
