import React, { useState } from 'react'
import { View, StyleSheet, Image, TouchableOpacity, CheckBox } from 'react-native'

// libs
import { images } from 'app/src/libs/images'
import { goToPaymentFinish } from 'app/src/libs/screen-transition'

// constants
import { borderLightGrayColor, blue } from 'app/src/constants/colors'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'
import { useParams } from 'app/src/hooks/use-params'

// components
import { Text } from 'app/src/components/basics'
import { Button } from 'app/src/components/basics'
import { TextInput } from 'app/src/components/basics/text-input'
import { windowHeight, windowWidth } from 'app/src/constants/layout'

interface Props {
  setVisiblePaymentModal: (boolean) => void
  cardNumber: number | undefined
  email: string
  goodThru: number | undefined
  securityCode: number | undefined
  setEmail: (email: string) => void
  setCardNumber: (cardNumber: number) => void
  setGoodThru: (goodThru: number) => void
  setSecurityCode: (securityCode: number) => void
}

export const PaymentModal: React.FC<Props> = ({
  setVisiblePaymentModal,
  cardNumber,
  setCardNumber,
  email,
  setEmail,
  goodThru,
  setGoodThru,
  securityCode,
  setSecurityCode,
}) => {
  const navigation = useNavigation()
  const params = useParams()

  const [paymentPermission, setPaymentPermission] = useState<boolean>(false)

  const { id: actionId } = params

  return (
    <View style={styles.modalContent}>
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.closeBtn}
          onPress={() => {
            setVisiblePaymentModal(false)
          }}
        >
          <Image style={styles.closeIcon} source={images['iconClose']} />
        </TouchableOpacity>
        <View style={styles.titleWrapper}>
          <Text type={'xxlarge'} align={'center'}>
            課金サンプルCheckout
          </Text>
          <Text type={'small'}>クレジットカードを入力してください</Text>
        </View>
        <View style={styles.formContent}>
          <TextInput label={'email'} onChangeText={text => setEmail(text)} value={email} />
          <TextInput
            label={'カート番号'}
            onChangeText={text => setCardNumber(Number(text))}
            value={cardNumber ? cardNumber.toString() : ''}
            keyboardType={'numeric'}
          />
          <View style={styles.smallFormWrapper}>
            <View style={styles.smallForm}>
              <TextInput
                label={'有効期限'}
                onChangeText={text => setGoodThru(Number(text))}
                value={goodThru ? goodThru.toString() : ''}
                keyboardType={'numeric'}
              />
            </View>
            <View style={styles.smallForm}>
              <TextInput
                label={'セキュリティコード'}
                onChangeText={text => setSecurityCode(Number(text))}
                value={securityCode ? securityCode.toString() : ''}
                keyboardType={'numeric'}
                secureTextEntry
                translucentGrayBackground
              />
            </View>
          </View>
        </View>
        <View style={styles.checkBox}>
          <CheckBox value={paymentPermission} style={styles.checkBox} onValueChange={setPaymentPermission} />
          <Text style={styles.permissionLabel}>決済情報を保存する</Text>
        </View>
        <Button
          style={styles.btn}
          buttonType={3}
          onPress={() => {
            setVisiblePaymentModal(false)
            goToPaymentFinish(navigation, actionId)
          }}
        >
          を支払う
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  modalContent: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.15)',
    height: windowHeight * 2,
    width: windowWidth,
    borderRadius: 10,
  },
  container: {
    flex: 1,
    position: 'absolute',
    top: 100,
    backgroundColor: '#f5f5f7',
    // webのみ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
    marginVertical: 10,
    marginHorizontal: 20,
  },
  closeBtn: {
    position: 'absolute',
    top: 18,
    right: 14,
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  inputContainer: {
    alignItems: 'center',
  },
  titleWrapper: {
    marginTop: 50,
    marginBottom: 27,
  },
  selectWrapper: {
    marginTop: 23,
  },
  item: {
    borderWidth: 1,
    borderColor: borderLightGrayColor,
    borderRadius: 10,
    marginVertical: 10,
    marginHorizontal: 5,
    padding: 15,
  },
  itemBtns: {
    flexDirection: 'row',
    marginVertical: 20,
  },
  line: {
    height: 1,
    width: 345,
    backgroundColor: borderLightGrayColor,
    marginVertical: 20,
  },
  btn: {
    marginVertical: 20,
    marginHorizontal: 15,
  },
  checkBox: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    borderColor: blue,
  },
  permissionLabel: {
    fontSize: 13,
  },
  formContent: {
    margin: 20,
    alignItems: 'center',
    width: 315,
  },
  formTitle: {
    alignSelf: 'flex-start',
    marginBottom: 10,
  },
  smallFormWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 310,
    left: -32,
  },
  smallForm: {
    width: 150,
  },
})
