import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'
import firebase from 'firebase/app'

// constants
import { isWeb } from 'app/src/constants/platform'
import { windowWidth } from 'app/src/constants/layout'
import { grayBgColor, blue, white } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ActionCard } from 'app/src/components/advanced/action-card'

// models
import { ActionCard as ActionCardModel } from 'app/src/models/action-card'

type ContainerProps = {}

type Props = {
  actions: ActionCardModel[]
}

export const ActionListScreen: React.FC<ContainerProps> = props => {
  const [actions, setActions] = useState<any>([])

  const _fetchActions = async () => {
    const actions = await firebase
      .firestore()
      .collection(`actions`)
      .get()
      .then(qs =>
        qs.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        }),
      )

    setActions(actions)

    // 聖奈さんとSahoさんのアクションのみ取得
    //   await firebase
    //     .firestore()
    //     .collection(`actions`)
    //     .get()
    //     .then(qs =>
    //       qs.docs.map(doc => {
    //         if (doc.id === 'rbcLQkJkwLg8Vu3PwszV') return setActions([{ id: doc.id, ...doc.data() }])
    //       }),
    //     )
  }

  useEffect(() => {
    _fetchActions()
  }, [])
  return <UI actions={actions} />
}

const UI: React.FC<Props & ContainerProps> = ({ actions }) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
          <View style={styles.searchBar}>
            <View style={styles.searchBarTitle}>
              <Text style={styles.actionTotalText}>{actions.length}</Text>
              <Text>アクションが開催予定</Text>
            </View>
          </View>
          {actions &&
            actions.map((item: ActionCardModel, index) => {
              return <ActionCard action={item} key={index} />
            })}
          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: white,
    marginBottom: 50,
  },
  scrollContents: {
    alignItems: 'center',
  },
  searchBar: {
    width: windowWidth,
    height: 40,
    marginBottom: 27,
    backgroundColor: grayBgColor,
    paddingVertical: 10,
    paddingHorizontal: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchBarTitle: {
    flexDirection: 'row',
  },
  actionTotalText: {
    flexDirection: 'row',
    fontWeight: 'bold',
    color: blue,
    marginRight: 2,
  },
})
