import React from 'react'
import { View, StyleSheet, TouchableOpacity } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'

// constants
import { primaryColor } from 'app/src/constants/colors'

// components
import { Text, Button } from 'app/src/components/basics'
import { DonationSelectView } from 'app/src/components/advanced/donation-select'
import { Footer } from 'app/src/components/advanced/footer'

type Props = {
  history: any
}

export const DonationAreaList: React.FC<Props> = () => {
  return (
    <LinearGradient colors={['#FAFAFA', '#F4F4F4']} style={styles.portfolioListContainer}>
      <Text style={styles.description}>
        設定をしておくと、上記の総寄付可能額は以下の割合で月末に寄付されます。自分の関心あるエリアを設定してください。
      </Text>
      <DonationSelectView area={1} />
      <DonationSelectView area={2} />
      <DonationSelectView area={3} />
      <DonationSelectView area={4} />
      <DonationSelectView area={5} />
      <DonationSelectView area={6} />
      <DonationSelectView area={7} />
      <DonationSelectView area={8} />
      <Button style={styles.saveButton} buttonType={4} onPress={() => {}}>
        この比率で設定する
      </Button>
      <Text style={styles.description}>
        皆様の想いが届くよう、日々努力しておりますが、寄付先についてご意見・ご要望がある場合は
        <TouchableOpacity>
          <Text style={styles.link}>お問い合わせ</Text>
        </TouchableOpacity>
        よりご連絡ください。
      </Text>
      <Footer />
    </LinearGradient>
  )
}
const styles = StyleSheet.create({
  portfolioListContainer: {
    alignItems: 'center',
    paddingVertical: 15,
  },
  description: {
    width: 320,
    justifyContent: 'center',
    marginBottom: 21,
  },
  saveButton: {
    marginTop: 18,
    marginBottom: 30,
  },
  link: {
    color: primaryColor,
    textDecorationLine: 'underline',
  },
})
