import React, { useState } from 'react'
import { StyleSheet, View, Image, ImageBackground, TouchableOpacity } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'

// libs
import { images } from 'app/src/libs/images'

// constants
import { borderLightGrayColor, lightGray, white } from 'app/src/constants/colors'
import { windowHeight, windowWidth } from 'app/src/constants/layout'

// components
import { Button, Text } from 'app/src/components/basics'

const amountItems = [10, 100, 1000, 10000]

type LiveViewerModalProps = {
  totalDonation: number
  setTotalDonation: (n: number) => void
  myDonation: number
  setMyDonation: (n: number) => void
  setVisibleDonateModal: (boolean) => void
}

export const LiveViewerModal: React.FC<LiveViewerModalProps> = ({
  totalDonation,
  setTotalDonation,
  myDonation,
  setMyDonation,
  setVisibleDonateModal,
}) => {
  const [donation, setDonation] = useState<number>(0)

  const handleAmount = (amount: number): void => {
    setDonation(prevDonation => {
      if (prevDonation) {
        const newDonation = prevDonation + amount
        return newDonation
      } else {
        return amount
      }
    })
  }

  return (
    <>
      <View style={styles.container}>
        <Text type={'xxlarge'} font={'quick-r'} style={styles.title}>
          Donate
        </Text>
        <View style={styles.itemBtns}>
          {amountItems.map((amount, index) => (
            <Text type={'xlarge'} font={'quick-r'} onPress={() => handleAmount(amount)} key={index} style={styles.item}>
              +{amount.toLocaleString()}
            </Text>
          ))}
        </View>
        <View style={styles.line} />
        <View style={styles.cardContainer}>
          <Image style={styles.cardImage} source={images['creditCard']} />
          <View style={styles.cardInfo}>
            <Text type={'medium'} style={styles.card}>
              クレジットカード
            </Text>
            <Text type={'small'} style={styles.cardNumber}>
              xxxx- xxxx - xxxx - 1105
            </Text>
          </View>
          <MaterialIcons name="keyboard-arrow-right" size={18} style={styles.arrowRight} />
        </View>
        <TouchableOpacity
          style={styles.donationButton}
          onPress={() => {
            setTotalDonation(totalDonation + donation)
            setMyDonation(myDonation + donation)
            setVisibleDonateModal(false)
          }}
        >
          <ImageBackground style={styles.buttonImage} source={images['btnType4Wide']} imageStyle={{ resizeMode: 'cover' }}>
            <Text type={'large'} style={styles.donateText}>
              ドネイトする
            </Text>
            <Text type={'xlarge'} style={styles.donation}>
              ¥{donation.toLocaleString()}
            </Text>
          </ImageBackground>
        </TouchableOpacity>
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    height: windowHeight / 3,
    width: windowWidth,
    bottom: 0,
    paddingHorizontal: 20,
    backgroundColor: white,
    zIndex: 1,
  },
  title: {
    fontWeight: '400',
    marginTop: 15,
  },
  itemBtns: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  item: {
    borderWidth: 1,
    borderColor: borderLightGrayColor,
    borderRadius: 10,
    marginVertical: 10,
    marginHorizontal: 5,
    paddingVertical: 16,
    paddingHorizontal: 14,
    width: windowWidth,
    textAlign: 'center',
    fontWeight: '400',
  },
  line: {
    height: 1,
    backgroundColor: borderLightGrayColor,
    marginTop: 8,
  },
  cardContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 6,
    marginVertical: 16,
  },
  cardImage: {
    width: 64,
    height: 42.37,
  },
  cardInfo: {
    marginLeft: 12,
  },
  card: {
    fontWeight: '400',
  },
  cardNumber: {
    color: lightGray,
  },
  arrowRight: {
    color: '#cccccc',
    marginLeft: 'auto',
  },
  donationButton: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  buttonImage: {
    width: 335,
    height: 50,
    alignItems: 'center',
    flexDirection: 'row',
  },
  donateText: {
    fontWeight: '400',
    marginLeft: 39.94,
  },
  donation: {
    fontWeight: '500',
    marginRight: 31.27,
    marginLeft: 'auto',
  },
})
