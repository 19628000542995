import React from 'react'
import { StyleSheet, TextStyle, Text as RNText, TextProps } from 'react-native'

// constants
import { lightGray, black, darkGray, headlineTextColor } from 'app/src/constants/colors'

type Props = {
  style?: TextStyle
  type?: string
  font?: string
  align?: TextStyle['textAlign']
}
export const Text: React.FC<Props & TextProps> = (props: Props) => {
  const { style, type = 'medium', font, align = 'left' } = props

  const textStyle = () => {
    if (type === 'small') return styles.small
    if (type === 'medium') return styles.medium
    if (type === 'large') return styles.large
    if (type === 'xlarge') return styles.xlarge
    if (type === 'xxlarge') return styles.xxlarge
    if (type === 'quickRTitle') return styles.quickRTitle
  }

  const fontStyle = () => {
    let font = 'noto-sans-regular'
    if (font === 'quick-r') font = 'quicksand-regular'
    if (font === 'quick-m') font = 'quicksand-medium'

    return {
      fontFamily: font,
    }
  }

  // デフォルトで、OS文字サイズ変更を適用しないようにする
  return <RNText allowFontScaling={false} {...props} style={[textStyle(), { textAlign: align }, font ? fontStyle() : {}, style]} />
}
const styles = StyleSheet.create({
  small: {
    fontSize: 11,
    lineHeight: 20,
    color: lightGray,
    fontFamily: 'noto-sans-regular',
  },
  medium: {
    fontSize: 12,
    lineHeight: 22,
    color: black,
    fontFamily: 'noto-sans-regular',
  },
  large: {
    fontSize: 14,
    lineHeight: 24,
    color: darkGray,
    fontFamily: 'noto-sans-regular',
  },
  xlarge: {
    fontSize: 14,
    lineHeight: 24,
    color: darkGray,
    fontWeight: 'bold',
    fontFamily: 'noto-sans-bold',
  },
  xxlarge: {
    fontSize: 16,
    lineHeight: 26,
    color: darkGray,
    fontFamily: 'noto-sans-regular',
  },
  quickRTitle: {
    fontSize: 30,
    lineHeight: 30,
    color: headlineTextColor,
    fontFamily: 'quicksand-regular',
  },
})
