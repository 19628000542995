import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView, ImageBackground, TouchableOpacity, Image } from 'react-native'
import firebase from 'firebase/app'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'

// libs
import { images } from 'app/src/libs/images'
import { stripeCreateCustomer, fetchUserInfo, savePaymentMethod, fetchUserCreditCards, deleteCreditCard } from 'app/src/libs/firebase'
import { goToSignin, goToPayment } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'
import { useParams } from 'app/src/hooks/use-params'

// constants
import { isWeb } from 'app/src/constants/platform'
import { black, lightGray } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Button } from 'app/src/components/basics/button'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { CreditCard } from 'app/src/components/advanced/credit-card'
import { Footer } from 'app/src/components/advanced/footer'

type ContainerProps = {}

type Props = {
  creditCards: any
  saveCreditCard: () => void
  uid: string
  deleteCreditCard: (uid: string, id: string) => void
  canClick: boolean
}

export const PaymentSettingScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()
  const params = useParams()
  const stripe = useStripe()
  const elements = useElements()

  const [uid, setUid] = useState<string>('')
  const [userInfo, setUserInfo] = useState<any>()
  const [creditCards, setCreditCards] = useState<any>([])
  const [canClick, setCanClick] = useState<boolean>(true)
  const { actionId } = params

  useEffect(() => {
    const setup = async () => {
      await firebase.auth().onAuthStateChanged(user => {
        if (user) {
          setUid(user.uid)
        } else {
          goToSignin(navigation)
        }
      })
    }
    setup()
  }, [])

  useEffect(() => {
    if (uid) {
      firebase
        .auth()
        .currentUser?.getIdToken(true)
        .then(() => stripeCreateCustomer())
      _fetchUserInfo()
    }
  }, [uid])

  useEffect(() => {
    if (userInfo) {
      _setupStripe()
      _fetchUserCreditCards()
    }
  }, [userInfo])

  const _fetchUserInfo = async () => {
    const userInfo = await fetchUserInfo(uid)
    setUserInfo(userInfo)
  }

  const _setupStripe = () => {
    stripeCreateCustomer()
  }

  const _fetchUserCreditCards = async () => {
    const creditCards = await fetchUserCreditCards(uid)
    setCreditCards(creditCards)
  }

  const _saveCreditCard = async () => {
    if (!stripe || !elements || !canClick) {
      return
    }
    setCanClick(false)

    await stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: userInfo.name,
          email: userInfo.email,
        },
      })
      .then(async res => {
        const { error, paymentMethod } = res
        if (error) return alert(error.message)

        // @ts-ignore
        const { id } = paymentMethod
        // カード情報の保存
        await savePaymentMethod(id).then(v => {
          const cardNumberElement = elements.getElement(CardNumberElement)
          const cardExpiryElement = elements.getElement(CardExpiryElement)
          const cardCvcElement = elements.getElement(CardCvcElement)
          cardNumberElement && cardNumberElement.clear()
          cardExpiryElement && cardExpiryElement.clear()
          cardCvcElement && cardCvcElement.clear()
          _fetchUserCreditCards()
          setCanClick(true)
          alert('クレジットカードを保存しました')
          if (actionId) {
            goToPayment(navigation, actionId)
          }
        })
      })
  }

  const _deleteCreditCard = async (uid: string, id: string) => {
    await deleteCreditCard(uid, id).then(v => {
      _fetchUserCreditCards()
      alert('クレジットカードを削除しました')
    })
  }

  return (
    <UI creditCards={creditCards} saveCreditCard={_saveCreditCard} uid={uid} deleteCreditCard={_deleteCreditCard} canClick={canClick} />
  )
}

const UI: React.FC<Props & ContainerProps> = ({ creditCards, saveCreditCard, uid, deleteCreditCard, canClick }) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.headerContent}>
            <Text type={'xxlarge'} align={'center'}>
              決済設定
            </Text>
            {creditCards.map(item => (
              <View style={styles.listItem} key={item.fingerprint}>
                <CreditCard last4={item.last4} />
                <TouchableOpacity onPress={() => deleteCreditCard(uid, item.id)}>
                  <Image style={styles.deleteIcon} source={images['btnClose']} />
                </TouchableOpacity>
              </View>
            ))}
          </View>
          <View style={styles.line} />

          <View style={styles.formContent}>
            <View style={styles.formTitle}>
              <Text>クレジットカード追加</Text>
            </View>
            <ImageBackground style={styles.inputImage} source={images['input']} resizeMode={'stretch'}>
              <View style={styles.label}>
                <Text type={'small'} style={styles.labelText}>
                  カード番号
                </Text>
              </View>
              <View style={styles.input}>
                <CardNumberElement options={{ style: cardElementStyle }} />
              </View>
            </ImageBackground>

            <View style={styles.smallFormWrapper}>
              <ImageBackground style={styles.smallForm} source={images['input']} resizeMode={'stretch'}>
                <View style={styles.label}>
                  <Text type={'small'} style={styles.labelText}>
                    有効期限
                  </Text>
                </View>
                <View style={styles.input}>
                  <CardExpiryElement options={{ style: cardElementStyle }} />
                </View>
              </ImageBackground>

              <ImageBackground style={styles.smallForm} source={images['inputBackground']} resizeMode={'stretch'}>
                <View style={styles.label}>
                  <Text type={'small'} style={styles.labelText}>
                    セキュリティコード
                  </Text>
                </View>
                <View style={styles.input}>
                  <CardCvcElement options={{ style: cardElementStyle, placeholder: '123' }} />
                </View>
              </ImageBackground>
            </View>
          </View>

          <View style={styles.btnWrapper}>
            <Button
              buttonType={canClick ? 3 : 7}
              onPress={() => {
                saveCreditCard()
              }}
            >
              {canClick ? `追加する` : `保存中...`}
            </Button>
          </View>
        </ScrollView>
        <Footer />
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const cardElementStyle = {
  base: {
    color: black,
    textAlign: 'center',
    '::placeholder': {
      color: lightGray,
    },
  },
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  headerContent: {
    marginVertical: 24,
    marginHorizontal: 20,
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 16,
  },
  deleteIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  line: {
    height: 6,
    width: '100%',
    backgroundColor: '#F4F4F4',
    marginBottom: 20,
  },
  formContent: {
    marginHorizontal: 'auto',
    alignItems: 'center',
    marginVertical: 20,
    width: 315,
  },
  formTitle: {
    alignSelf: 'flex-start',
    marginBottom: 10,
  },
  inputImage: {
    width: '100%',
    height: 46,
    marginBottom: 15,
  },
  input: {
    position: 'relative',
    flex: 1,
    paddingTop: 23,
  },
  label: {
    marginBottom: 1,
    position: 'absolute',
    top: 3,
    left: 10,
  },
  labelText: {
    fontSize: 8,
    color: lightGray,
  },
  smallFormWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 315,
  },
  smallForm: {
    width: 150,
    height: 46,
    marginBottom: 15,
  },
  btnWrapper: {
    marginBottom: 240,
  },
})
