import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView, Image, TouchableOpacity } from 'react-native'

// libs
import { images } from 'app/src/libs/images'

// constants
import { isWeb } from 'app/src/constants/platform'
import { windowHeight, windowWidth } from 'app/src/constants/layout'
import { white } from 'app/src/constants/colors'

// components
import { Header } from 'app/src/components/advanced/header'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { LiveBeforeViewer } from 'app/src/components/advanced/live/before-viewer'
import { LiveCommentList } from 'app/src/components/advanced/live/comment-list'
import { Text } from 'app/src/components/basics'
import { ShareModal } from 'app/src/components/advanced/share-modal'
import { ActionLive } from 'app/src/components/advanced/live/action-live'
import { LiveViewerModal } from 'app/src/components/advanced/live/live-viewer-modal'

// models
import { CommentList as CommentListModel } from 'app/src/models/comment-list'

type ContainerProps = {}

type Props = {
  isLoading: boolean
  commentData: CommentListModel[]
  visibleDonateModal: boolean
  setVisibleDonateModal: (boolean) => void
  visibleShareModal: boolean
  setVisibleShareModal: (boolean) => void
  totalDonation: number
  setTotalDonation: (n: number) => void
  myDonation: number
  setMyDonation: (n: number) => void
}

const sampleData = index => {
  return {
    userName: 'aaaaaa',
    userIcon: 'userIcon2',
    comment: `${index}回目のコメント`,
  }
}

export const LiveViewerScreen: React.FC<ContainerProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [commentData, setCommentData] = useState<CommentListModel[]>([])
  const [visibleDonateModal, setVisibleDonateModal] = useState<boolean>(false)
  const [visibleShareModal, setVisibleShareModal] = useState<boolean>(false)
  const [totalDonation, setTotalDonation] = useState(0)
  const [myDonation, setMyDonation] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])

  useEffect(() => {
    // 2秒ごとにサンプルデータを追加
    const interval = setInterval(() => {
      const length = commentData.length
      commentData.unshift(sampleData(length))
      setCommentData([])
      setCommentData(commentData)
    }, 2000)
    return () => clearInterval(interval)
  }, [commentData.length])

  return (
    <UI
      isLoading={isLoading}
      commentData={commentData}
      visibleDonateModal={visibleDonateModal}
      setVisibleDonateModal={setVisibleDonateModal}
      visibleShareModal={visibleShareModal}
      setVisibleShareModal={setVisibleShareModal}
      totalDonation={totalDonation}
      myDonation={myDonation}
      setTotalDonation={setTotalDonation}
      setMyDonation={setMyDonation}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  isLoading,
  commentData,
  visibleDonateModal,
  setVisibleDonateModal,
  visibleShareModal,
  setVisibleShareModal,
  totalDonation,
  setTotalDonation,
  myDonation,
  setMyDonation,
}) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
          {isLoading ? (
            <LiveBeforeViewer />
          ) : (
            <>
              <iframe
                width={windowWidth}
                height={windowHeight - 48 - 56}
                allow="autoplay; encrypted-media"
                frameBorder="0"
                src="https://dist.bambuser.net/player/?resourceUri=https%3A%2F%2Fcdn.bambuser.net%2Fgroups%2F95927%2Fbroadcasts%3Fby_authors%3D%26title_contains%3D%26has_any_tags%3D%26has_all_tags%3D%26da_id%3Dc676d039-ebc7-fca4-5947-0290d3c33427%26da_timestamp%3D1597099957%26da_signature_method%3DHMAC-SHA256%26da_ttl%3D0%26da_static%3D1%26da_signature%3D5494cd986d9a8716849cc621ebed5fede2a220fee1e7827f3eb37a9bd1d7746a"
              />
              <ActionLive setVisibleShareModal={setVisibleShareModal} />
              <View style={styles.LiveDonationBar}>
                <Text type={'small'} style={styles.donation}>
                  総ドネイト数
                </Text>
                <Text type={'xxlarge'} font={'quick-r'} style={styles.donationText}>
                  ¥{totalDonation.toLocaleString()}
                </Text>
              </View>
              <View style={styles.LiveMyDonationBar}>
                <Text type={'small'} style={styles.myDonation}>
                  あなたのドネイト
                </Text>
                <Text type={'xlarge'} font={'quick-r'} style={styles.myDonationText}>
                  ¥{myDonation.toLocaleString()}
                </Text>
              </View>
              <View style={styles.commentListContainer}>
                <LiveCommentList commentData={commentData} />
              </View>
              {visibleDonateModal ? (
                <>
                  <View style={styles.top}>
                    <Image style={styles.logo} source={images['btnLogo']} />
                  </View>
                  <LiveViewerModal
                    setVisibleDonateModal={setVisibleDonateModal}
                    totalDonation={totalDonation}
                    setTotalDonation={setTotalDonation}
                    myDonation={myDonation}
                    setMyDonation={setMyDonation}
                  />
                </>
              ) : (
                <View style={styles.buttonContainer}>
                  <Text type={'small'} style={styles.buttonSide}>
                    ドネイトする
                  </Text>
                  <TouchableOpacity onPress={() => setVisibleDonateModal(true)}>
                    <Image style={styles.logo} source={images['btnLogo']} />
                  </TouchableOpacity>
                </View>
              )}
            </>
          )}
        </ScrollView>
        <ShareModal visibleShareModal={visibleShareModal} setVisibleShareModal={setVisibleShareModal} />
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
  },
  LiveDonationBar: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    height: 30,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    position: 'absolute',
    top: 68,
    right: 0,
    zIndex: 10,
    paddingVertical: 8,
  },
  donation: {
    color: '#656565',
    fontWeight: '500',
    fontSize: 8,
    marginLeft: 18,
  },
  donationText: {
    color: '#656565',
    fontWeight: '500',
    fontSize: 18,
    marginLeft: 13,
    marginRight: 10,
  },
  LiveMyDonationBar: {
    flexDirection: 'row',
    alignItems: 'center',
    width: 'fit-content',
    height: 24,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    position: 'absolute',
    top: 108,
    right: 0,
    zIndex: 10,
    paddingVertical: 5,
  },
  myDonation: {
    color: '#656565',
    fontWeight: '500',
    fontSize: 8,
    marginLeft: 15,
  },
  myDonationText: {
    color: '#656565',
    fontWeight: '500',
    marginLeft: 7,
    marginRight: 10,
  },

  scrollContents: {
    justifyContent: 'center',
  },
  commentListContainer: {
    position: 'absolute',
    marginLeft: 8,
    width: windowWidth - 16,
    bottom: 65,
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 16,
    right: 16,
  },
  buttonSide: {
    fontSize: 8,
    color: white,
    textAlign: 'center',
  },
  top: {
    position: 'absolute',
    right: 16,
    bottom: windowHeight / 3 - 35,
    zIndex: 2,
  },
  logo: {
    height: 70,
    width: 70,
  },
})
