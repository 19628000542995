import React, { useContext } from 'react'
import { StyleSheet, View, Image, TouchableOpacity } from 'react-native'

// contexts
import { LayoutContext, LayoutContextType } from 'app/src/contexts/layout'

// libs
import { images } from 'app/src/libs/images'
import { goToTerms, goToHowTo, goToPrivacy, goToQa, goToCompany, goToContact, goToTransactions } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { windowWidth } from 'app/src/constants/layout'
import { isWeb } from 'app/src/constants/platform'
import { darkGray, white } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'

type Props = {}

export const Footer: React.FC<Props> = () => {
  const { isPc, width } = useContext<LayoutContextType>(LayoutContext)
  const navigation = useNavigation()

  return (
    <View style={styles.otherBackground}>
      <View style={styles.line} />
      <View style={styles.footerLogo}>
        <Image style={styles.footerLogoImage} source={images['logo']} />
      </View>
      <View style={styles.footerContent}>
        <View style={[{ justifyContent: 'center' }, isPc && styles.contentInner]}>
          <View style={styles.links}>
            <TouchableOpacity style={[styles.link, isPc && { width: 950 / 2 }]} onPress={() => goToTerms(navigation)}>
              <Text style={styles.linkText}>利用規約</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity style={[styles.link, isPc && { width: 950 / 2 }]} onPress={() => goToHowTo(navigation)}>
            <Text style={styles.linkText}>meguの寄付の仕組み</Text>
          </TouchableOpacity> */}
            <TouchableOpacity style={[styles.link, isPc && { width: 950 / 2 }]} onPress={() => goToPrivacy(navigation)}>
              <Text style={styles.linkText}>個人情報保護方針</Text>
            </TouchableOpacity>
            {/* <TouchableOpacity style={[styles.link, isPc && { width: 950 / 2 }]} onPress={() => goToQa(navigation)}>
            <Text style={styles.linkText}>よくある質問</Text>
          </TouchableOpacity> */}
            <TouchableOpacity style={[styles.link, isPc && { width: 950 / 2 }]} onPress={() => goToCompany(navigation)}>
              <Text style={styles.linkText}>運営会社</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.link, isPc && { width: 950 / 2 }]} onPress={() => goToContact(navigation)}>
              <Text style={styles.linkText}>お問い合わせ</Text>
            </TouchableOpacity>
            <TouchableOpacity style={[styles.link, isPc && { width: 950 }]} onPress={() => goToTransactions(navigation)}>
              <Text style={styles.linkText}>特定商取引に基づく表記</Text>
            </TouchableOpacity>
          </View>
          <Text style={styles.copyright}>© All rights reserved by beero Inc. since 2020</Text>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  contentInner: {
    width: 950,
    marginHorizontal: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footer: {
    width: windowWidth,
  },
  footerContent: {
    paddingTop: 60,
    paddingBottom: 15,
  },
  otherBackground: {
    backgroundColor: white,
  },
  links: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  link: {
    marginBottom: 46,
    alignItems: 'center',
    width: windowWidth / 2,
  },
  linkText: {
    fontSize: 11,
    color: darkGray,
  },
  footerLogo: {
    marginTop: 13,
    marginBottom: 34,
    alignItems: 'center',
  },
  footerLogoImage: {
    width: 80,
    height: 126.5,
  },
  copyright: {
    textAlign: 'center',
    fontSize: 11,
    color: '#C4C4C4',
    marginBottom: 70,
  },
  line: {
    height: 4,
    width: windowWidth,
    backgroundColor: '#EBEBEB',
    marginBottom: 54,
  },
})
