import React, { useState, useEffect, useContext } from 'react'
import { StyleSheet, View, Image, ScrollView, ImageBackground } from 'react-native'
import firebase from 'firebase/app'

// contexts
import { LayoutContext, LayoutContextType } from 'app/src/contexts/layout'

// libs
import { images } from 'app/src/libs/images'
import { goToSignup, goToSignin, goToHowTo } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'
import { windowWidth } from 'app/src/constants/layout'
import { black } from 'app/src/constants/colors'

// components
import { Button, Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ActionCard } from 'app/src/components/advanced/action-card'
import { Issue } from 'app/src/components/advanced/home/issue'

// models
import { ActionCard as ActionCardModel } from 'app/src/models/action-card'

type ContainerProps = {}

type Props = {
  navigation: any
  isPc: boolean
  innerWidth: number
  actions: ActionCardModel[]
  isLogin: boolean
}

export const HomeScreen: React.FC<ContainerProps> = () => {
  const { isPc, innerWidth } = useContext<LayoutContextType>(LayoutContext)
  const navigation = useNavigation()
  const [actions, setActions] = useState<any>([])
  const [isLogin, setIsLogin] = useState<boolean>(false)

  useEffect(() => {
    const setup = async () => {
      await firebase.auth().onAuthStateChanged(user => {
        if (user) {
          setIsLogin(true)
        }
      })
    }
    setup()
  }, [])

  const _fetchActions = async () => {
    const actions = await firebase
      .firestore()
      .collection(`actions`)
      .get()
      .then(qs =>
        qs.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        }),
      )
    setActions(actions)
  }

  useEffect(() => {
    _fetchActions()
  }, [])
  return <UI navigation={navigation} isPc={isPc} innerWidth={innerWidth} isLogin={isLogin} actions={actions} />
}

const UI: React.FC<Props & ContainerProps> = ({ navigation, isPc, innerWidth, isLogin, actions }) => {
  return (
    <>
      <Header />
      <View style={styles.container}>
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.logo}>
            <Image style={styles.logoImage} source={images['logo']} />
          </View>
          <View style={styles.beta}>
            <Image style={styles.betaImage} source={images['beta']} />
          </View>
          <View style={isPc && styles.contentInner}>
            <ImageBackground
              style={[styles.section1, isPc && { width: innerWidth, height: innerWidth, marginHorizontal: 'auto' }]}
              source={isPc ? '' : images['earthTop']}
              imageStyle={{ resizeMode: 'cover' }}
            >
              <Text type={'large'} align={'center'}>
                みんなの楽しむが誰かの為になる
                {'\n'}
                ドネーション・ネットワーク・サービス
              </Text>
              {!isLogin && (
                <>
                  <View style={styles.singupButton}>
                    <Button buttonType={4} onPress={() => goToSignup(navigation)}>
                      会員登録
                    </Button>
                  </View>
                  <View style={styles.signinButton}>
                    <Button buttonType={4} onPress={() => goToSignin(navigation)}>
                      ログイン
                    </Button>
                  </View>
                </>
              )}
              <View style={styles.section1Catchphrase}>
                <Image style={styles.section1CatchphraseImage} source={images['section1Catchphrase']} />
              </View>
              <Text type={'medium'} align={'center'}>
                meguはアクションに参加して楽しむだけで
                {'\n'}
                社会貢献になる全く新しいサービスです！
                {'\n\n\n'}
                スポーツイベント、オンラインセミナー、
                {'\n'}
                写真展、映像展、音楽コンサートなど、
                {'\n'}
                様々なアクションが開催予定です。
                {'\n\n\n'}
                さっそく、
                {'\n'}
                あなたの好みのアクションに参加してみましょう！
              </Text>
            </ImageBackground>
            <View style={[styles.actionCard, isLogin && { marginTop: -420 }]}>
              {actions &&
                actions.map((item: ActionCardModel, index) => {
                  return (
                    <View style={styles.actionImage} key={index}>
                      <ActionCard action={item} boxShadow />
                    </View>
                  )
                })}
            </View>
          </View>

          <View style={isPc && styles.contentInner}>
            <Text type={'xlarge'} align={'center'}>
              meguとは？
            </Text>
            <View style={styles.section2Image5Wrapper}>
              <Image
                style={[styles.section2Image5Image, isWeb && { width: innerWidth, height: innerWidth }]}
                source={images['section2Image5']}
              />
            </View>
            <Text type={'medium'} align={'center'}>
              地球の環境問題が日に日に深刻になっていく中で、少し生活様
              {'\n'}
              式を改める以上の行動はなかなかハードルが高く、多くの人が
              {'\n'}
              取れていないと思います。
              {'\n\n'}
              ただ、それ自体が悪いということではなく、みんなが「集まる」
              {'\n'}
              「楽しむ」「学ぶ」といったような自ら取りたくなる行動
              {'\n'}
              を取っていれば、それが自然と社会貢献に繋がる「仕組み」が
              {'\n'}
              あれば、私達の未来は変えられるのではないかという想い
              {'\n'}
              で、meguは設立されました。
              {'\n\n'}
              今後のmeguの展開については以下よりご確認ください。
            </Text>
            <View style={styles.moreButton}>
              <Button buttonType={4} onPress={() => goToHowTo(navigation)}>
                もっと詳しく見る
              </Button>
            </View>
          </View>

          <View style={isPc && styles.contentInner}>
            <Text type={'xlarge'} align={'center'}>
              現状の地球環境課題
            </Text>
            <View style={styles.issueList}>
              <Issue area={1} />
              <Issue area={2} />
              <Issue area={3} />
              <Issue area={4} />
              <Issue area={5} />
              <Issue area={6} />
              <Issue area={7} />
              <Issue area={8} />
            </View>
          </View>

          <View style={isPc && styles.contentInner}>
            <Text type={'xlarge'} align={'center'}>
              提携NPO団体
            </Text>
            <View style={[styles.npoList, , isWeb && { width: innerWidth }]}>
              <Image style={styles.npoImage} source={images['iconNpo1']} />
              <Image style={styles.npoImage} source={images['iconNpo2']} />
              <Image style={styles.npoImage} source={images['iconNpo3']} />
              <Image style={styles.npoImage} source={images['iconNpo4']} />
            </View>
          </View>

          {/* <ImageBackground
            style={[styles.section2, isWeb && { width: innerWidth, height: innerWidth * 1.8, marginHorizontal: 'auto' }]}
            source={images['section2Bg']}
            imageStyle={{ resizeMode: 'cover' }}
          >
            <View style={isPc && styles.contentInner}>
              <View style={styles.section2Catchphrase}>
                <Image style={styles.section2CatchphraseImage} source={images['section2Catchphrase']} />
              </View>
              <Text type={'medium'} align={'center'}>
                あなたもアクションを起こそう！
              </Text>
              <View style={styles.section2Text2Wrapper}>
                <Text type={'medium'}>
                  別々の場所でみんなでランニング、それぞれ自分の家から料理教室、そしてトークイベントやオンラインセミナーも！
                  {'\n\n'}
                  開催日時を指定して配信することはもちろん、思い立ったらすぐに始めることもできます！
                </Text>
              </View>
            </View>
          </ImageBackground>
          <View style={isPc && styles.contentInner}>
            <Text type={'xlarge'} align={'center'}>
              開催日時と参加費を決めよう！
            </Text>
            <Image style={styles.section2Image1Image} source={images['section2Image1']} />

            <View style={styles.section2Title2Image2Wrapper}>
              <Text type={'xlarge'} align={'center'} style={styles.section2Title2}>
                配信開始！
                {'\n'}
                参加費＆ドネーションが集まります！
              </Text>
              <Image
                style={[styles.section2Image2Image, isWeb && { width: innerWidth, height: innerWidth * 2.4, marginHorizontal: 'auto' }]}
                source={images['section2Image2']}
              />
            </View>
            <Text type={'xlarge'} align={'center'}>
              アクション後は思いを届けたいところに
              {'\n'}
              贈るだけ！
            </Text>
            <View style={styles.section2Image3}>
              <Image style={styles.section2Image3Image} source={images['section2Image3']} />
            </View>

            <Button buttonType={1} style={styles.actionStartButton} onPress={() => goToActionCreate(navigation)}>
              アクションを始める
            </Button>
            <Text type={'medium'} align={'center'}>
              meguは、ソーシャルグッドな活動の輪を広げ、
              {'\n'}
              持続可能な社会の発展に繋がるウェブサービスです。
            </Text>
            <Text type={'xlarge'} align={'center'} style={styles.section2Title4Wrapper}>
              そして、想いが形になる
            </Text>
            <Image
              style={[styles.section2Image4Image, isWeb && { width: innerWidth, height: innerWidth * 1.2, marginHorizontal: 'auto' }]}
              source={images['section2Image4']}
            />
            <Text type={'medium'} align={'center'} style={styles.section2Text4Wrapper}>
              meguでドネイトすればするほど、
              {'\n'}
              あなたの想いが花を咲かせます。
              {'\n\n'}
              どんどん寄付の花を咲かせて
              {'\n'}
              みんなにシェアしよう！
            </Text>
            <Button buttonType={4} style={styles.bottomBtnWrapper} onPress={() => goToActionList(navigation)}>
              アクションを見る
            </Button>
          </View>*/}

          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  scrollContainer: {
    flex: 1,
  },
  contentInner: {
    width: 950,
    marginHorizontal: 'auto',
  },
  singupButton: {
    marginTop: 47,
  },
  signinButton: {
    marginTop: 15,
  },
  moreButton: {
    marginTop: 36,
    marginBottom: 87,
  },
  logo: {
    marginTop: 137,
    alignItems: 'center',
  },
  logoImage: {
    resizeMode: 'contain',
    width: 100,
    height: 100,
  },
  beta: {
    alignItems: 'center',
    marginVertical: 15,
  },
  betaImage: {
    width: 24,
    height: 24,
  },
  section1: {
    marginBottom: 40,
    paddingTop: 40,
    paddingBottom: 80,
    width: windowWidth,
    height: windowWidth * 2.48,
  },
  section1Catchphrase: {
    alignItems: 'center',
    marginTop: 55,
    marginBottom: 40,
  },
  section1CatchphraseImage: {
    width: 210,
    height: 85,
    resizeMode: 'contain',
  },
  ttlActions: {
    marginTop: 40,
    marginBottom: 10,
    marginLeft: 20,
  },
  ttlPopular: {
    marginTop: 40,
    marginBottom: 10,
    marginLeft: 20,
  },
  ttlPopularText: {
    color: black,
    fontSize: 12,
    marginLeft: 20,
    textAlign: 'left',
    lineHeight: 28,
    opacity: 0.4,
  },
  actionCard: {
    alignItems: 'center',
    marginTop: -280,
    marginBottom: 87,
  },
  actionImage: {
    // width: 225,
    height: 420,
    // marginHorizontal: 25,
    marginTop: 20,
    marginBottom: 50,
  },
  section2: {
    marginTop: 87,
    paddingTop: 94,
    marginBottom: -120,
    width: windowWidth,
    height: windowWidth * 1.8,
  },
  section2Catchphrase: {
    marginBottom: 20,
    marginHorizontal: 'auto',
    alignItems: 'center',
  },
  section2CatchphraseImage: {
    width: 235.5,
    height: 91.5,
    resizeMode: 'contain',
  },
  section2Button1: {
    marginHorizontal: 'auto',
    marginVertical: 27,
  },
  section2Text2Wrapper: {
    width: 320,
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 65,
  },
  section2Image1Image: {
    width: 331,
    height: 309,
    resizeMode: 'cover',
  },
  section2Title2Image2Wrapper: {
    marginTop: 70,
    marginBottom: 52,
  },
  section2Title2: {
    marginBottom: -80,
    zIndex: 10,
  },
  section2Image2Image: {
    width: windowWidth,
    height: windowWidth * 2.4,
  },
  section2Image3: {
    marginBottom: 40,
  },
  section2Image3Image: {
    width: 325,
    height: 276,
  },
  section2Image5Wrapper: {
    alignItems: 'center',
  },
  section2Image5Image: {
    width: windowWidth,
    height: windowWidth,
    resizeMode: 'contain',
  },
  actionStartButton: {
    marginBottom: 40,
  },
  section2Title4Wrapper: {
    marginTop: 95,
    marginBottom: 12,
  },
  section2Image4Image: {
    width: windowWidth,
    height: windowWidth * 1.2,
  },
  section2Text4Wrapper: {
    marginTop: 28,
    marginBottom: 45,
  },
  issueList: {
    marginTop: 32,
    marginBottom: 55,
  },
  npoList: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 32,
    marginBottom: 84,
    width: windowWidth,
    justifyContent: 'space-around',
  },
  npoImage: {
    margin: 12,
    width: 80,
    height: 80,
    resizeMode: 'contain',
  },

  // 簡易認証画面
  authContainer: {
    flex: 1,
    marginTop: 20,
    alignItems: 'center',
  },
  authText: {
    marginVertical: 15,
  },
})
