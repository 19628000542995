import React from 'react'
import { StyleSheet, View, ScrollView, Image } from 'react-native'

// libs
import { images } from 'app/src/libs/images'
import { goToHome } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'
import { black } from 'app/src/constants/colors'

// components
import { Text, Button } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { TabBar } from 'app/src/components/advanced/tab-bar'

type ContainerProps = {}

type Props = {
  navigation: any
}

export const SignupFinishScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  return <UI navigation={navigation} />
}

const UI: React.FC<Props & ContainerProps> = ({ navigation }) => {
  return (
    <View style={styles.container}>
      {isWeb && <Header />}
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <Text type={'xlarge'} style={styles.pageName}>
          会員登録のメールをお送りしました
        </Text>
        <View style={styles.ImageContainer}>
          <Image style={styles.mailImage} source={images['mail']} />
          <Image style={styles.logo} source={images['logoSmall']} />
        </View>
        <View style={styles.topButton}>
          <Button buttonType={4} onPress={() => goToHome(navigation)}>
            TOPへ戻る
          </Button>
        </View>
      </ScrollView>
      {/* {isWeb && <TabBar />} */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  scrollContainer: {
    flex: 1,
  },
  scrollContents: {
    justifyContent: 'center',
  },
  pageName: {
    fontSize: 16,
    color: black,
    marginTop: 109,
    marginBottom: 40,
    textAlign: 'center',
  },
  ImageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 66,
    marginBottom: 33.65,
    position: 'relative',
  },
  mailImage: {
    width: 120,
    height: 137.35,
  },
  logo: {
    width: 38.98,
    height: 48,
    position: 'absolute',
    top: 23,
  },
  topButton: {
    marginTop: 50,
    marginBottom: 36,
    width: 260,
    height: 50,
    fontWeight: 'bold',
    marginHorizontal: 'auto',
  },
})
