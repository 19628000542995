import React, { useState, useEffect, useContext } from 'react'
import { StyleSheet, View, Image, ScrollView, ImageBackground, TouchableOpacity } from 'react-native'
import firebase from 'firebase/app'
import { AntDesign } from '@expo/vector-icons'
import { LinearGradient } from 'expo-linear-gradient'
import { useLocation } from 'react-router-dom'

// contexts
import { LayoutContext, LayoutContextType } from 'app/src/contexts/layout'

// libs
import { images } from 'app/src/libs/images'
import { isWeb } from 'app/src/constants/platform'
import { fetchUserInfo } from 'app/src/libs/firebase'
import { goToPayment, goToLiveDelivery, goToLiveViewer, goToSignup, goToActionCreate } from 'app/src/libs/screen-transition'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'
import { useParams } from 'app/src/hooks/use-params'

// constants
import { windowWidth } from 'app/src/constants/layout'
import { lightGrayBgColor, gray, white, borderLightGrayColor, black } from 'app/src/constants/colors'

// components
import { Text, Button } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ShareList } from 'app/src/components/advanced/share-list'
import { ShareModal } from 'app/src/components/advanced/share-modal'

// models
import { ActionCard as ActionCardModel } from 'app/src/models/action-card'

type ContainerProps = {
  visibleShareModal: boolean
  setVisibleShareModal: (boolean) => void
  isActiveQuestion: boolean
  setIsActiveQuestion: (boolean) => void
  isShownInfo: boolean
  setIsShownInfo: (boolean) => void
}

type Props = {
  navigation: any
  isPc: boolean
  actions: ActionCardModel[]
  joinAction: () => void
  title: string
  deliveryDate: string
  deliveryTime: string
  possibleViewerNumber: number | string
  // capacity: number | string
  entryAmount: number
  donation: number
  donationArea: string
  profitAmount: number
  actionId: string
  imageFileUrl: string
  organizer: any
  userInfo: any
  info: string
  likeCount: number
  isJoinAction: boolean
  loading: boolean
  updateLikeAction: () => void
}

export const ActionDetailScreen: React.FC<ContainerProps> = () => {
  const { isPc } = useContext<LayoutContextType>(LayoutContext)
  const navigation = useNavigation()
  const params = useParams()
  const location = useLocation()

  const [actions, setActions] = useState<any>([])
  const [title, setTitle] = useState<string>('')
  const [deliveryDate, setDeliveryDate] = useState<string>('')
  const [deliveryTime, setDeliveryTime] = useState<string>('')
  const [possibleViewerNumber, setPossibleViewerNumber] = useState<number | string>(0)
  const [entryAmount, setEntryAmount] = useState<number>(0)
  const [donation, setDonation] = useState<number>(0)
  const [donationArea, setDonationArea] = useState<string>('')
  const [profitAmount, setProfitAmount] = useState<number>(0)
  const [imageFileUrl, setImageFileUrl] = useState<string>('')
  const [organizer, setOrganizer] = useState<any>()
  const [likeCount, setLikeCount] = useState<number>(0)
  const [isJoinAction, setIsJoinAction] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<any>()
  const [info, setInfo] = useState<string>('')

  const [visibleShareModal, setVisibleShareModal] = useState<boolean>(false)
  const [isActiveQuestion, setIsActiveQuestion] = useState<boolean>(false)
  const [isShownInfo, setIsShownInfo] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const { id: actionId } = params

  const _fetchActions = async () => {
    const actions = await firebase
      .firestore()
      .collection(`actions`)
      .get()
      .then(qs =>
        qs.docs.map(doc => {
          return { id: doc.id, ...doc.data() }
        }),
      )

    setActions(actions)

    actions.map((item: ActionCardModel) => {
      if (item.id === actionId) {
        setTitle(String(item.title))
        setDeliveryDate(String(item.deliveryDate))
        setDeliveryTime(String(item.deliveryTime))
        setEntryAmount(Number(item.entryAmount))
        item.possibleViewerNumber && setPossibleViewerNumber(item.possibleViewerNumber)
        setProfitAmount(Number(item.profitAmount))
        setImageFileUrl(String(item.imageFileUrl))
        setInfo(String(item.info))
        setDonation(Number(item.profitAmount))
        setDonationArea(String(item.donationArea))
        item.likeCount && setLikeCount(Number(item.likeCount))

        _fetchOrganizer(item.organizer)
      }
    })
  }

  const _fetchOrganizer = async organizer => {
    await firebase
      .firestore()
      .collection(`users`)
      .doc(organizer)
      .get()
      .then(qs => setOrganizer(qs.data()))
  }

  const _isJoin = () => {
    const joinActions = userInfo.joinActions ? userInfo.joinActions : []
    if (joinActions.includes(actionId)) {
      setIsJoinAction(true)
    }
  }

  const _joinAction = () => {
    if (userInfo) {
      goToPayment(navigation, actionId)
    } else {
      const fromPathname = location
      goToSignup(navigation, actionId, fromPathname)
    }
  }

  const _updateLikeAction = async () => {
    setLoading(true)
    if (!userInfo) {
      const fromPathname = location
      return goToSignup(navigation, actionId, fromPathname)
    }

    const likeActions = userInfo.likeActions ? userInfo.likeActions : []
    // 現在のlike数取得
    const currentActionData = await firebase
      .firestore()
      .collection(`actions`)
      .doc(actionId)
      .get()
      .then(qs => qs.data())
    const currentLikeCount = currentActionData && currentActionData.likeCount ? currentActionData.likeCount : 0

    const isIncludeLikeAction = likeActions.includes(actionId)
    const editLikeAction = isIncludeLikeAction ? likeActions.filter(n => n !== actionId) : [...likeActions, actionId]
    const editLikeCount = isIncludeLikeAction ? currentLikeCount - 1 : currentLikeCount + 1

    await firebase
      .firestore()
      .collection(`users`)
      .doc(userInfo.id)
      .set(
        {
          likeActions: editLikeAction,
        },
        { merge: true },
      )
    await firebase
      .firestore()
      .collection(`actions`)
      .doc(actionId)
      .set(
        {
          likeCount: editLikeCount,
        },
        { merge: true },
      )

    // 現在の画面表示の変更
    userInfo.likeActions = editLikeAction
    setUserInfo(userInfo)
    setLikeCount(isIncludeLikeAction ? likeCount - 1 : likeCount + 1)
    setLoading(false)
  }

  const _fetchUserInfo = async (uid: string) => {
    const userInfo = await fetchUserInfo(uid)
    setUserInfo(userInfo)
  }

  useEffect(() => {
    if (userInfo) {
      _isJoin()
    }
  }, [userInfo])

  useEffect(() => {
    const fetchActionDetail = async () => {
      await firebase.auth().onAuthStateChanged(user => {
        if (user) {
          _fetchUserInfo(user.uid)
        }
        _fetchActions()
      })
    }
    fetchActionDetail()
  }, [])

  return (
    <UI
      navigation={navigation}
      isPc={isPc}
      actions={actions}
      joinAction={_joinAction}
      title={title}
      deliveryDate={deliveryDate}
      deliveryTime={deliveryTime}
      // capacity={capacity}
      entryAmount={entryAmount}
      donation={donation}
      possibleViewerNumber={possibleViewerNumber}
      profitAmount={profitAmount}
      donationArea={donationArea}
      imageFileUrl={imageFileUrl}
      organizer={organizer}
      actionId={actionId}
      userInfo={userInfo}
      info={info}
      likeCount={likeCount}
      isJoinAction={isJoinAction}
      visibleShareModal={visibleShareModal}
      setVisibleShareModal={setVisibleShareModal}
      isActiveQuestion={isActiveQuestion}
      setIsActiveQuestion={setIsActiveQuestion}
      isShownInfo={isShownInfo}
      setIsShownInfo={setIsShownInfo}
      updateLikeAction={_updateLikeAction}
      loading={loading}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  navigation,
  isPc,
  actions,
  actionId,
  joinAction,
  title,
  deliveryDate,
  deliveryTime,
  // capacity,
  entryAmount,
  donation,
  possibleViewerNumber,
  profitAmount,
  donationArea,
  imageFileUrl,
  organizer,
  userInfo,
  isJoinAction,
  info,
  likeCount,
  visibleShareModal,
  setVisibleShareModal,
  isActiveQuestion,
  setIsActiveQuestion,
  isShownInfo,
  setIsShownInfo,
  updateLikeAction,
  loading,
}) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={[styles.container, isPc && { paddingTop: 106 }]}>
        <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
          <View style={isPc && styles.contentInner}>
            <View style={isPc && styles.contentInnerLeft}>
              <ImageBackground
                style={[styles.actionImage, isPc && { width: 600, height: 600 * 1.5 }]}
                source={{ uri: imageFileUrl }}
                imageStyle={{ resizeMode: 'cover' }}
              >
                <View style={styles.topButton}>
                  <TouchableOpacity style={styles.likeButton} onPress={updateLikeAction}>
                    <AntDesign
                      name={userInfo && userInfo.likeActions && userInfo.likeActions.includes(actionId) ? 'heart' : 'hearto'}
                      size={18}
                      style={styles.heart}
                    />
                    <Text font={'quick-m'} style={styles.likeCount}>
                      {likeCount}
                    </Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.shareButton} onPress={() => setVisibleShareModal(true)}>
                    <Image style={styles.shareImage} source={images['iconShare']} />
                  </TouchableOpacity>
                </View>
                <Image style={[styles.detailImageMask, isPc && { width: 600, height: 600 * 0.65 }]} source={images['detailImageMask']} />
                <View style={[styles.topInfo, isPc && { width: 600, height: 600 * 0.65 }]}>
                  <View style={styles.userIconRow}>
                    <Image style={styles.userIcon} source={{ uri: organizer ? organizer.imageUrl : null }} />
                  </View>
                  <Text type={'xlarge'} style={styles.actionTopTitle}>
                    {title}
                  </Text>
                  <Text>{deliveryDate + deliveryTime}</Text>
                </View>
              </ImageBackground>
              <View style={[styles.actionInfo, isPc && { width: 600 }]}>
                <View style={styles.actionInfoLeftItem}>
                  <Text type={'medium'}>人数</Text>
                  <Text type={'xxlarge'} font={'quick-r'}>
                    {possibleViewerNumber === '制限なし' ? '∞' : possibleViewerNumber}
                  </Text>
                </View>
                <View style={styles.actionInfoBorder} />
                <View style={styles.actionInfoRightItem}>
                  <Text type={'medium'}>参加費</Text>
                  <View style={styles.amountTextWrapper}>
                    <Text type={'xxlarge'} font={'quick-r'}>
                      ¥{entryAmount.toLocaleString()}
                    </Text>
                    <Text font={'quick-r'}>（内、支援¥{profitAmount.toLocaleString()}）</Text>
                  </View>
                </View>
              </View>
              {!isJoinAction && (
                <View style={styles.actionButton}>
                  <Button buttonType={4} onPress={() => joinAction()}>
                    アクションに参加する
                  </Button>
                </View>
              )}
              <View style={styles.shareListWrapper}>
                <ShareList title={title} actionId={actionId} description={info} imageFileUrl={imageFileUrl} />
              </View>
              <Text style={styles.smallText} type={'small'}>
                一緒に参加できる人にシェア！
              </Text>
              {isJoinAction && (
                // <>
                //   <View style={styles.actionButton}>
                //     <Button buttonType={4} onPress={() => goToLiveViewer(navigation)}>
                //       [テスト] ライブViewerへ
                //     </Button>
                //   </View>
                //   <View style={styles.actionButton}>
                //     <Button buttonType={4} onPress={() => goToLiveDelivery(navigation)}>
                //       [テスト] ライブ配信へ
                //     </Button>
                //   </View>
                // </>

                // ラインコード表示
                <View style={styles.lineContent}>
                  <Text style={styles.lineText}>お申し込みいただきありがとうございます。</Text>
                  {organizer && organizer.name === '角田聖奈' && (
                    <Image style={styles.lineQrCodeImageImage} source={images['seinaLineQrCode']} />
                  )}
                  {organizer && organizer.name === 'saho' && (
                    <Image style={styles.lineQrCodeImageImage} source={images['sahoLineQrCode']} />
                  )}
                  {organizer && organizer.name === '角田聖奈' ? <Text style={styles.lineText}>LINE ID : Seina3928</Text> : <></>}
                  <Text type={'small'}>こちらからご連絡ください</Text>
                </View>
              )}
              {!isShownInfo ? (
                <View>
                  <Text style={[styles.actionDetailText, isPc && { width: 600 }]}>{info.replace(/\\n/g, '\n').slice(0, 118)}</Text>
                  <LinearGradient style={styles.gradient} colors={['white', '#ffffff00']} />
                </View>
              ) : (
                <Text style={[styles.actionDetailText, isPc && { width: 600 }]}>{info.replace(/\\n/g, '\n')}</Text>
              )}
              <TouchableOpacity
                style={styles.readMore}
                onPress={() => {
                  setIsShownInfo(!isShownInfo)
                }}
              >
                <Text align={'center'}>{!isShownInfo ? 'さらに読む' : '閉じる'}</Text>
              </TouchableOpacity>
              <Text style={styles.ttlDetail} type={'quickRTitle'}>
                Detail
              </Text>
              <View style={styles.detailUserIconList}>
                <View style={styles.detailUserContent}>
                  <View style={styles.detailUserIconWrapper}>
                    <Image style={styles.userIcon} source={{ uri: organizer ? organizer.imageUrl : null }} />
                    <Text style={styles.detailUserName}>{organizer && organizer.name}</Text>
                  </View>
                  <Text>{organizer && organizer.info.replace(/\\n/g, '\n')}</Text>
                </View>
              </View>
              <View style={[styles.actionDetailList, isPc && { width: 600 }]}>
                <View style={styles.detailItem}>
                  <Text style={styles.detailItemTitle}>日時：</Text>
                  <Text font={'quick-m'} style={styles.detailItemData}>
                    {deliveryDate + deliveryTime}
                  </Text>
                </View>
                <View style={styles.detailItem}>
                  <Text style={styles.detailItemTitle}>参加費：</Text>
                  <Text font={'quick-m'} style={styles.detailItemData}>
                    ¥{entryAmount.toLocaleString()}
                  </Text>
                </View>
                <View style={[styles.detailItem, styles.questionOverlay]}>
                  <Text style={styles.detailItemTitle}>寄付先指定：</Text>
                  <Text font={'quick-m'} style={styles.detailItemData}>
                    {donationArea}
                  </Text>
                  {!isActiveQuestion ? (
                    <TouchableOpacity style={styles.questionItem} onPress={() => setIsActiveQuestion(true)}>
                      <Image style={styles.question} source={images['question']} />
                    </TouchableOpacity>
                  ) : (
                    <View style={styles.questionContainer}>
                      <TouchableOpacity style={styles.questionItem} onPress={() => setIsActiveQuestion(false)}>
                        <Image style={styles.question} source={images['questionActive']} />
                      </TouchableOpacity>
                      <View style={styles.balloonTop}>
                        <View style={styles.balloonContainer}>
                          <View style={[styles.balloonTriangleBase, styles.balloonTriangleOuter]} />
                          <View style={[styles.balloonTriangleBase, styles.balloonTriangleInner]} />
                          <Text style={styles.textContainer}>
                            <Text style={styles.text}>エリア指定が「あり」の場合</Text>
                            、主催者が指定しているエリアにリターン分とドネイト分が寄付されます。
                            <br />
                            <Text style={styles.text}>エリア指定が「なし」の場合</Text>
                            、リターン分とドネイト分があなたのウォレットに戻りますので、ご自身で寄付することができます。
                          </Text>
                        </View>
                      </View>
                    </View>
                  )}
                </View>
                <View style={styles.detailItem}>
                  <Text style={styles.detailItemTitle}>支援金：</Text>
                  <Text font={'quick-m'} style={styles.detailItemData}>
                    ¥{profitAmount.toLocaleString()}
                  </Text>
                </View>
                <View style={styles.detailItem}>
                  <Text style={styles.detailItemTitle}>人数：</Text>
                  <Text font={'quick-m'} style={styles.detailItemData}>
                    {possibleViewerNumber}
                  </Text>
                </View>
              </View>
              {!isJoinAction && (
                <View style={styles.actionButton}>
                  <Button buttonType={4} onPress={() => joinAction()}>
                    アクションに参加する
                  </Button>
                </View>
              )}
              <View style={styles.shareListWrapper}>
                <ShareList title={title} actionId={actionId} description={info} imageFileUrl={imageFileUrl} />
              </View>
              <Text style={styles.smallText} type={'small'}>
                一緒に参加できる人にシェア！
              </Text>
            </View>
            {isPc && !isJoinAction && (
              <View style={styles.contentInnerRight}>
                {/* <Button buttonType={1} style={styles.actionStartButton} onPress={() => goToActionCreate(navigation)}>
                  アクションを始める
                </Button> */}
                <Button buttonType={4} onPress={() => joinAction()}>
                  アクションに参加する
                </Button>
              </View>
            )}
          </View>
          <ShareModal
            visibleShareModal={visibleShareModal}
            setVisibleShareModal={setVisibleShareModal}
            title={title}
            actionId={actionId}
            imageFileUrl={imageFileUrl}
            description={info}
          />

          <Footer />
        </ScrollView>
      </View>
      {/* {isWeb && <TabBar />} */}
      {/* いいね連打防止のロード画面 */}
      {loading && <View style={styles.loading} />}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
  },
  scrollContainer: {
    flex: 1,
    backgroundColor: white,
    marginBottom: 50,
  },
  scrollContents: {
    alignItems: 'center',
  },
  contentInner: {
    width: 950,
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  contentInnerLeft: {
    width: 600,
  },
  contentInnerRight: {
    width: 300,
  },
  actionImage: {
    width: windowWidth,
    height: windowWidth * 1.5,
    position: 'relative',
  },
  detailImageMask: {
    width: windowWidth,
    height: windowWidth * 0.65,
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 1,
  },
  topInfo: {
    width: windowWidth,
    height: windowWidth * 0.65,
    position: 'absolute',
    zIndex: 2,
    left: 0,
    bottom: 0,
    paddingHorizontal: 20,
  },
  topButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 35,
  },
  likeButton: {
    borderRadius: 45,
    paddingVertical: 7,
    paddingHorizontal: 10.5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 55,
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    position: 'absolute',
    left: 10,
  },
  heart: {
    color: white,
    marginRight: 7.5,
  },
  likeCount: {
    fontSize: 18,
    color: white,
  },
  shareButton: {
    backgroundColor: 'transparent',
    width: 39,
    height: 39,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
  },
  shareImage: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  userIconRow: {
    marginTop: 56,
    marginBottom: 25,
    width: 335,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  userIcon: {
    width: 90,
    height: 90,
    borderRadius: 90 / 2,
  },
  userName: {
    fontSize: 10,
    fontWeight: 'bold',
  },
  actionTopTitle: {
    width: 335,
  },
  actionInfo: {
    width: windowWidth,
    flexDirection: 'row',
    marginTop: 20,
    backgroundColor: lightGrayBgColor,
  },
  actionInfoLeftItem: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionInfoRightItem: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionInfoBorder: {
    width: 1,
    height: 50,
    marginVertical: 11,
    backgroundColor: white,
  },
  amountTextWrapper: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  actionButton: {
    marginTop: 35,
    zIndex: -1,
  },
  shareListWrapper: {
    marginTop: 30,
  },
  ttlDetail: {
    marginLeft: 23,
  },
  actionDetailList: {
    width: 328,
    marginTop: 46,
    marginHorizontal: 23,
    borderTopColor: borderLightGrayColor,
    borderTopWidth: 1,
    zIndex: 1,
  },
  detailUserIconList: {
    flexDirection: 'row',
    marginHorizontal: 23,
  },
  detailUserContent: {
    width: '100%',
    flexDirection: 'column',
  },
  detailUserIconWrapper: {
    marginTop: 20,
    marginBottom: 15,
    width: 70,
    alignItems: 'center',
  },
  detailUserIcon: {
    width: 70,
    height: 70,
    borderRadius: 70 / 2,
  },
  detailUserName: {
    justifyContent: 'center',
    fontSize: 10,
    fontWeight: '500',
  },
  detailItem: {
    borderBottomColor: borderLightGrayColor,
    borderBottomWidth: 1,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },
  detailItemTitle: {
    color: gray,
    marginRight: 5,
  },
  questionOverlay: {
    zIndex: 10,
  },
  questionContainer: {
    position: 'relative',
  },
  questionItem: {
    alignItems: 'center',
    marginLeft: 8,
    zIndex: 1,
  },
  question: {
    justifyContent: 'center',
    width: 16,
    height: 16,
  },
  balloonTop: {
    marginLeft: 6,
  },
  balloonContainer: {
    width: 220,
    backgroundColor: white,
    borderWidth: 1,
    borderColor: '#C4C4C4',
    borderRadius: 2,
    position: 'absolute',
    top: 18,
    left: -110 + 8,
  },
  balloonTriangleBase: {
    width: 0,
    height: 0,
    position: 'absolute',
    bottom: '100%',
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
  },
  balloonTriangleOuter: {
    left: (220 - 28) / 2 - 1,
    borderWidth: 15,
    borderBottomColor: '#C4C4C4',
  },
  balloonTriangleInner: {
    left: (220 - 28) / 2,
    borderWidth: 14,
    borderBottomColor: white,
  },
  textContainer: {
    paddingHorizontal: 18,
    paddingTop: 19.35,
    paddingBottom: 25.65,
    backgroundColor: white,
    lineHeight: 18,
  },
  text: {
    fontWeight: '700',
  },
  detailItemData: {
    color: black,
  },
  smallText: {
    marginTop: 22,
    marginBottom: 40,
    textAlign: 'center',
  },
  actionDetailText: {
    marginHorizontal: 28,
    width: 320,
  },
  actionStartButton: {
    marginTop: 20,
    marginBottom: 30,
  },
  readMore: {
    marginTop: 10,
    marginBottom: 62,
  },
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: -20,
    height: 40,
  },
  lineContent: {
    marginTop: 25,
    marginBottom: 55,
    alignItems: 'center',
  },
  lineText: {
    fontSize: 11,
  },
  lineQrCodeImageImage: {
    width: 275,
    height: 260,
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
})
