import React from 'react'
import { StyleSheet, View, Image, ScrollView } from 'react-native'
import { useLocation } from 'react-router-dom'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

//libs
import { images } from 'app/src/libs/images'
import { goToMypage } from 'app/src/libs/screen-transition'

// constants
import { isWeb } from 'app/src/constants/platform'
import { black } from 'app/src/constants/colors'

// components
import { Text, Button } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'

type ContainerProps = {}

type Props = {
  navigation: any
  state: any
}

export const ContactFinishScreen: React.FC<ContainerProps> = () => {
  const { state } = useLocation<any>()
  const navigation = useNavigation()

  return <UI navigation={navigation} state={state} />
}

const UI: React.FC<Props & ContainerProps> = ({ navigation, state }) => {
  return (
    <View style={styles.container}>
      {isWeb && <Header />}
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <View style={styles.logo}>
          <Image style={styles.logoImage} source={images['logo']} />
        </View>
        <Text type={'xlarge'} style={styles.pageName} align={'center'}>
          お問い合わせいただきありがとうございます
        </Text>
        <Text>
          以下の内容でお問い合わせを承りました。2営業日中を目処になるべく早めにご連絡させていただきますので、今しばらくお待ち下さい。
        </Text>
        <View style={styles.contactContainer}>
          <Text style={styles.bold}>お問い合わせ内容</Text>
          <Text>{state?.subject}</Text>
          <Text style={styles.bold}>本文</Text>
          <Text>{state?.message}</Text>
        </View>
        <View style={styles.actionButton}>
          <Button buttonType={1} onPress={() => goToMypage(navigation)}>
            マイページへ
          </Button>
        </View>
        <View style={styles.thankYouImageContainer}>
          <Image style={styles.thankYouImage} source={images['thankYou']} />
        </View>
      </ScrollView>
      <Footer />
      {/* {isWeb && <TabBar />} */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  scrollContainer: {
    flex: 1,
    marginBottom: 110,
  },
  scrollContents: {
    justifyContent: 'center',
    marginHorizontal: 28,
  },
  pageName: {
    fontSize: 16,
    color: black,
    marginTop: 72,
    marginBottom: 24,
  },
  logo: {
    marginTop: 137,
    alignItems: 'center',
  },
  logoImage: {
    resizeMode: 'contain',
    width: 100,
    height: 100,
  },
  contactContainer: {
    backgroundColor: '#F4F4F4',
    marginTop: 36,
    paddingHorizontal: 40,
    paddingVertical: 25,
  },
  bold: {
    fontWeight: 'bold',
  },
  actionButton: {
    marginTop: 35,
    zIndex: -1,
  },
  thankYouImageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 64,
  },
  thankYouImage: {
    width: 375,
    height: 133,
    resizeMode: 'contain',
  },
})
