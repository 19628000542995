import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ScrollView, Image, TouchableOpacity, ImageBackground } from 'react-native'
import firebase from 'firebase/app'
import { TabView, TabBar as MypageTabBar, SceneMap, NavigationState, SceneRendererProps } from 'react-native-tab-view'
import { AntDesign, FontAwesome5 } from '@expo/vector-icons'
import { useLocation } from 'react-router-dom'

//libs
import { images } from 'app/src/libs/images'
import { fetchUserInfo } from 'app/src/libs/firebase'
import { goToSignupFinish, goToActionList, goToSettingList, goToAccountSetting, goToSignup } from 'app/src/libs/screen-transition'
import { LinkingOpenURL } from 'app/src/libs/linking'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

//constants
import { isWeb } from 'app/src/constants/platform'
import { white, darkGray, lightGray, primaryColor } from 'app/src/constants/colors'

//components
import { Text, Button } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { ActionCard } from 'app/src/components/advanced/action-card'
import { UserIconEdit } from 'app/src/components/advanced/user-icon-edit'

//models
import { ActionCard as ActionCardModel } from 'app/src/models/action-card'

type ContainerProps = {}

type Props = {
  navigation: any
  isMyPageLoading: boolean
  userInfo: any
  myActions: ActionCardModel[]
  joinActions: ActionCardModel[]
  likeActions: ActionCardModel[]
  visibleWorkspaceInfo: boolean
  setVisibleWorkspaceInfo: (boolean) => void
}

const lineOpenChatUrl = 'https://line.me/ti/g2/eiauWp0ZrRdFhGMQgJ6_Gw?utm_source=invitation&utm_medium=link_copy&utm_campaign=default'

export const MypageScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()
  const location = useLocation()

  const [joinActions, setJoinActions] = useState<ActionCardModel[]>([])
  const [likeActions, setLikeActions] = useState<ActionCardModel[]>([])
  const [myActions, setMyActions] = useState<ActionCardModel[]>([])
  const [isMyPageLoading, setIsMyPageLoading] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<any>()
  const [visibleWorkspaceInfo, setVisibleWorkspaceInfo] = useState<boolean>(true)

  const _fetchActions = () => {
    const userJoinActions = userInfo.joinActions ? userInfo.joinActions : []
    const userLikeActions = userInfo.likeActions ? userInfo.likeActions : []

    userJoinActions.map(async actionId => {
      await firebase
        .firestore()
        .collection(`actions`)
        .doc(actionId)
        .get()
        .then(doc => {
          joinActions.push({ id: doc.id, ...doc.data() })
          setJoinActions([])
          setJoinActions(joinActions)
        })
    })

    userLikeActions.map(async actionId => {
      await firebase
        .firestore()
        .collection(`actions`)
        .doc(actionId)
        .get()
        .then(doc => {
          likeActions.push({ id: doc.id, ...doc.data() })
          setLikeActions([])
          setLikeActions(likeActions)
        })
    })
  }

  const _fetchUserInfo = async (uid: string) => {
    setIsMyPageLoading(true)
    const userInfo = await fetchUserInfo(uid)
    setUserInfo(userInfo)
  }

  useEffect(() => {
    if (userInfo) {
      _fetchActions()
    }
  }, [userInfo])

  useEffect(() => {
    const fetchMyPage = async () => {
      await firebase.auth().onAuthStateChanged(user => {
        if (user) {
          //会員登録後、メール認証したか
          if (user.emailVerified === true) {
            _fetchUserInfo(user.uid)
          } else {
            goToSignupFinish(navigation)
          }
        } else {
          const fromPathname = location
          goToSignup(navigation, fromPathname)
        }
      })
    }
    fetchMyPage()
  }, [])

  return (
    <UI
      navigation={navigation}
      joinActions={joinActions}
      myActions={myActions}
      likeActions={likeActions}
      isMyPageLoading={isMyPageLoading}
      userInfo={userInfo}
      visibleWorkspaceInfo={visibleWorkspaceInfo}
      setVisibleWorkspaceInfo={setVisibleWorkspaceInfo}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  navigation,
  joinActions,
  myActions,
  likeActions,
  isMyPageLoading,
  userInfo,
  visibleWorkspaceInfo,
  setVisibleWorkspaceInfo,
}) => {
  const [index, setIndex] = useState(0)
  const [routes] = useState([{ key: 'join' }, { key: 'action' }, { key: 'like' }, { key: 'mail' }])

  const JoinRoute = () => (
    <ScrollView>
      <View style={styles.actionContainer}>
        {joinActions.length !== 0 ? (
          joinActions.map((item: ActionCardModel, index) => (
            <View key={index} style={styles.actionCardContainer}>
              <ActionCard action={item} key={index} isHorizontal />
            </View>
          ))
        ) : (
          <>
            <View style={styles.actionImageContainer}>
              <Image style={styles.actionImage} source={images['mypageNoJoin']} />
            </View>
            <Text type={'medium'} style={styles.actionText}>
              まだアクションに参加しておりません。参加して楽しむことで誰かを助けてみませんか？
            </Text>
            <View style={styles.btnContainer}>
              <Button buttonType={4} onPress={() => goToActionList(navigation)}>
                アクションを見る
              </Button>
            </View>
          </>
        )}
      </View>
      <Footer />
    </ScrollView>
  )

  const ActionRoute = () => (
    <ScrollView>
      {/* {myActions.length !== 0 ? (
        myActions.map((item: ActionCardModel, index) => (
          <View key={index} style={styles.actionCardContainer}>
            <ActionCard action={item} key={index} isHorizontal />
          </View>
        ))
      ) : (
          <View style={styles.actionContainer}>
            <View style={styles.actionImageContainer}>
              <Image style={styles.actionImage} source={images['mypageNoAction']} />
            </View>
            <Text type={'medium'} style={styles.actionText}>
              まだアクションがありません。あなたも仲間と楽しむことで社会課題を解決してみませんか？
          </Text>
            <View style={styles.btnContainer}>
              <Button onPress={() => goToActionCreate(navigation)}>アクションを始める</Button>
            </View>
          </View>
        )} */}
      <View style={styles.comingSoon}>
        <Image style={styles.comingSoonImage} source={images['comingSoon']} />
      </View>
      <Footer />
    </ScrollView>
  )

  const LikeRoute = () => (
    <ScrollView>
      <View style={styles.actionContainer}>
        {likeActions.length !== 0 &&
          likeActions.map((item: ActionCardModel, index) => (
            <View key={index} style={styles.actionCardContainer}>
              <ActionCard action={item} key={index} isHorizontal />
            </View>
          ))}
      </View>
      <Footer />
    </ScrollView>
  )

  const MailRoute = () => (
    <ScrollView>
      {/* {actions &&
        actions.map((item: ActionCardModel, index) => (
          <View key={index} style={styles.actionCardContainer}>
            <ActionCard   action={item} key={index} />
          </View>
        ))} */}
      <Footer />
    </ScrollView>
  )

  const renderScene = SceneMap({
    join: JoinRoute,
    action: ActionRoute,
    like: LikeRoute,
    mail: MailRoute,
  })

  const getTabBar = ({ route, focused }) => {
    if (route.key === 'action') {
      return (
        <Text type={'small'} style={[focused ? styles.activeTabText : {}]}>
          主催アクション
        </Text>
      )
    } else if (route.key === 'join') {
      return (
        <Text type={'small'} style={[focused ? styles.activeTabText : {}]}>
          参加アクション
        </Text>
      )
    } else if (route.key === 'like') {
      return <AntDesign name={focused ? 'heart' : 'hearto'} size={18} style={[focused ? styles.activeTabIcon : styles.inactiveTabIcon]} />
    } else if (route.key === 'mail') {
      return <FontAwesome5 name="envelope" size={18} style={[focused ? styles.activeTabIcon : styles.inactiveTabIcon]} />
    }
  }

  type State = NavigationState<{
    key: string
  }>

  const renderTabBar = (props: SceneRendererProps & { navigationState: State }) => (
    <MypageTabBar
      {...props}
      renderIcon={props => getTabBar(props)}
      indicatorStyle={styles.indicator}
      style={styles.tabbar}
      labelStyle={styles.label}
      tabStyle={styles.tab}
    />
  )

  if (!isMyPageLoading) {
    return <View style={styles.container} />
  }
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <TouchableOpacity style={styles.settingBtn} onPress={() => goToSettingList(navigation)}>
          <AntDesign name="setting" size={20} style={styles.settingIcon} />
        </TouchableOpacity>
        <View style={styles.backgroundImageContent}>
          <ImageBackground style={styles.backgroundImage} source={{ uri: userInfo ? userInfo.imageUrl : null }} blurRadius={3}>
            <UserIconEdit userInfo={userInfo} onPress={() => goToAccountSetting(navigation, userInfo.id)} />
          </ImageBackground>
        </View>
        {visibleWorkspaceInfo ? (
          <View style={styles.workspaceInfoContent}>
            <Text type={'medium'} style={styles.welcomeText}>
              こんにちは、{userInfo && userInfo.nickname}さん{`\n`}いつもご利用ありがとうございます！
            </Text>
            <Text type={'small'} style={styles.workspaceInfoText}>
              meguは皆様に気持ちよく使っていただけるよう、日々改善中です。{'\n'}
              皆様にmeguを育てていただき、より社会に貢献できるサービスにできるようSlackのワークスペースを作りました。{'\n'}
              是非、参加して声を聞かせてください！
            </Text>
            <Button buttonType={1} onPress={() => LinkingOpenURL(lineOpenChatUrl)}>
              参加する！
            </Button>
            <TouchableOpacity onPress={() => setVisibleWorkspaceInfo(false)} style={styles.upArrowIcon}>
              <FontAwesome5 name="chevron-up" size={13} color={lightGray} />
            </TouchableOpacity>
          </View>
        ) : (
          <View style={styles.downArrowIconWrapper}>
            <TouchableOpacity onPress={() => setVisibleWorkspaceInfo(true)} style={styles.downArrowIcon}>
              <FontAwesome5 name="chevron-down" size={13} color={lightGray} />
            </TouchableOpacity>
          </View>
        )}
        <TabView renderTabBar={renderTabBar} navigationState={{ index, routes }} renderScene={renderScene} onIndexChange={setIndex} />
      </View>
      {/* {isWeb && <TabBar />} */}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    paddingTop: 48,
    marginBottom: 74,
  },
  settingBtn: {
    position: 'absolute',
    top: 55,
    right: 10,
    zIndex: 10,
  },
  settingIcon: {
    color: lightGray,
  },
  backgroundImageContent: {
    width: '100%',
    height: 220,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 23,
    alignItems: 'center',
  },
  backgroundImage: {
    width: '110%',
    height: '100%',
    alignItems: 'center',
  },
  workspaceInfoContent: {
    marginHorizontal: '3%',
    paddingHorizontal: 18,
    paddingVertical: 23,
    backgroundColor: '#F4F4F4',
    alignItems: 'center',
  },
  welcomeText: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 18,
  },
  workspaceInfoText: {
    textAlign: 'center',
    color: darkGray,
    marginTop: 10,
    marginBottom: 27,
    fontSize: 10,
  },
  upArrowIcon: {
    marginTop: 18,
    width: 30,
    alignItems: 'center',
  },
  downArrowIconWrapper: {
    alignItems: 'center',
  },
  downArrowIcon: {
    marginBottom: 18,
    width: 30,
    alignItems: 'center',
  },
  actionCardContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  tabbar: {
    marginRight: 10,
    marginLeft: 10,
    backgroundColor: white,
  },
  tab: {
    padding: 0,
  },
  indicator: {
    backgroundColor: primaryColor,
    height: 3,
  },
  label: {
    color: darkGray,
  },
  activeTabText: {
    color: darkGray,
  },
  activeTabIcon: {
    color: primaryColor,
  },
  inactiveTabIcon: {
    color: lightGray,
  },
  actionContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 25,
  },
  actionText: {
    marginLeft: 26,
    marginRight: 26,
  },
  btnContainer: {
    marginTop: 43,
    marginBottom: 15,
  },
  actionImageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 88.56,
    marginBottom: 40.56,
  },
  actionImage: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
  },

  comingSoon: {
    marginHorizontal: 120,
    justifyContent: 'center',
    alignItems: 'center',
  },
  comingSoonImage: {
    width: 255,
    height: 255,
    marginBottom: 160,
    resizeMode: 'contain',
  },
})
