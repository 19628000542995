import React from 'react'
import { View, StyleSheet, Image, FlatList } from 'react-native'
import { BlurView } from 'expo-blur'

//libs
import { images } from 'app/src/libs/images'

// components
import { Text } from 'app/src/components/basics'
import { white } from 'app/src/constants/colors'
import { windowHeight, windowWidth } from 'app/src/constants/layout'

// models
import { CommentList as CommentListModel } from 'app/src/models/comment-list'

type Props = {
  commentData: CommentListModel[]
}

export const LiveCommentList: React.FC<Props> = ({ commentData }) => {
  return (
    <View style={styles.listContainer}>
      <FlatList data={commentData} renderItem={renderItem} keyExtractor={(item, index) => `${index}`} inverted={true} />
    </View>
  )
}

const renderItem = ({ item }) => (
  <BlurView tint="default" intensity={50} style={styles.itemContainer}>
    <Image style={styles.userIcon} source={images[item.userIcon]} />
    <View style={styles.textContent}>
      <Text style={styles.nameText}>@{item.userName}</Text>
      <View style={styles.commentContent}>
        <Text style={styles.commentText}>{item.comment}</Text>
      </View>
    </View>
  </BlurView>
)

const styles = StyleSheet.create({
  listContainer: {
    position: 'absolute',
    height: windowHeight / 4,
    width: windowWidth,
    bottom: 0,
  },
  itemContainer: {
    flexDirection: 'row',
    width: 'fit-content',
    padding: 8,
    borderRadius: 4,
    marginTop: 7,
  },
  userIcon: {
    width: 28,
    height: 28,
  },
  textContent: {
    flexDirection: 'column',
    flex: 1,
    marginLeft: 10,
  },
  nameText: {
    fontSize: 10,
    lineHeight: 10,
    fontWeight: '500',
    color: white,
  },
  commentContent: {
    marginTop: 3,
  },
  commentText: {
    fontSize: 12,
    lineHeight: 12,
    color: white,
  },
})
