import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, ImageBackground, Image, TouchableOpacity, View } from 'react-native'
import firebase from 'firebase/app'

// contexts
import { LayoutContext, LayoutContextType } from 'app/src/contexts/layout'

// libs
import { images } from 'app/src/libs/images'
import { goToHome, goToPortfolio, goToMypage } from 'app/src/libs/screen-transition'
import { fetchUserInfo } from 'app/src/libs/firebase'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constant
import { isWeb } from 'app/src/constants/platform'
import { borderGrayColor } from 'app/src/constants/colors'
import { Text } from 'app/src/components/basics'

type Props = {}

export const Header: React.FC<Props> = () => {
  const { isPc, innerWidth } = useContext<LayoutContextType>(LayoutContext)
  const navigation = useNavigation()

  const [userImageUrl, setUserImageUrl] = useState<string>('')

  const _fetchUserImageUrl = async (uid: string) => {
    const userInfo: any = await fetchUserInfo(uid)
    setUserImageUrl(userInfo.imageUrl)
  }

  useEffect(() => {
    const fetchUserImage = async () => {
      await firebase.auth().onAuthStateChanged(user => {
        if (user) {
          _fetchUserImageUrl(user.uid)
        }
      })
    }
    fetchUserImage()
  }, [])

  if (isWeb && isPc) {
    return (
      <>
        <View style={styles.pcBar}>
          <View style={[styles.contentInner, { width: innerWidth }]}>
            <TouchableOpacity onPress={() => goToHome(navigation)}>
              <Image style={styles.pcLogoImage} source={images['logoPc']} />
            </TouchableOpacity>
            <View style={styles.buttons}>
              <TouchableOpacity onPress={() => goToHome(navigation)}>
                <Image style={styles.imageHome} source={images['pcIconHome']} />
              </TouchableOpacity>

              <TouchableOpacity onPress={() => goToPortfolio(navigation)}>
                <Image style={styles.image} source={images['pcIconPortfolio']} />
              </TouchableOpacity>

              <TouchableOpacity onPress={() => goToMypage(navigation)}>
                <Image style={styles.image} source={images['pcIconMypage']} />
              </TouchableOpacity>
            </View>
          </View>
        </View>
        <View style={styles.warningContainer}>
          <View style={styles.warningContent}>
            <Image style={styles.warningIcon} source={images['pcIconWarning']} />
            <Text align={'center'}>
              meguは現在スマホでの閲覧を推奨しています。{'\n'}お手数をおかけいたしますが、{'\n'}スマホのブラウザからお楽しみください。
            </Text>
          </View>
        </View>
      </>
    )
  }

  return (
    <ImageBackground style={styles.bar} source={images['headerBg']} imageStyle={{ resizeMode: 'cover' }}>
      <TouchableOpacity onPress={() => goToHome(navigation)}>
        <Image style={styles.logoImage} source={images['logoPc']} />
      </TouchableOpacity>

      <View style={styles.buttonWrapper}>
        <TouchableOpacity onPress={() => goToHome(navigation)}>
          <Image style={styles.iconImage} source={images['iconHome']} />
        </TouchableOpacity>
        <TouchableOpacity onPress={() => goToMypage(navigation)}>
          {userImageUrl ? (
            <Image style={styles.userIcon} source={{ uri: userImageUrl }} />
          ) : (
            <Image style={styles.iconImage} source={images['pcIconMypage']} />
          )}
        </TouchableOpacity>
      </View>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  pcBar: {
    position: isWeb ? 'fixed' : 'absolute',
    flex: 1,
    width: '100%',
    height: 56,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderBottomWidth: 1,
    borderBottomColor: borderGrayColor,
    zIndex: 10,
  },
  contentInner: {
    marginHorizontal: 'auto',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  pcLogoImage: {
    marginTop: 16,
    resizeMode: 'contain',
    width: 116,
    height: 26,
  },
  buttons: {
    marginTop: 6,
    flexDirection: 'row',
  },
  imageHome: {
    marginHorizontal: 17.5,
    width: 41,
    height: 41,
    resizeMode: 'contain',
  },
  image: {
    marginHorizontal: 17.5,
    marginTop: 9,
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  bar: {
    position: isWeb ? 'fixed' : 'absolute',
    borderBottomColor: '#dddddd',
    width: '100%',
    height: 48,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: '5%',
    zIndex: 10,
  },
  logoImage: {
    width: 100,
    height: 20,
    resizeMode: 'contain',
  },
  buttonWrapper: {
    width: 90,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconImage: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  userIcon: {
    width: 29,
    height: 29,
    borderRadius: 29 / 2,
    borderColor: '#dddddd',
    borderWidth: 1,
  },
  warningContainer: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 235,
  },
  warningContent: {
    paddingHorizontal: 26,
    paddingBottom: 26,
    borderWidth: 1,
    borderColor: '#B00020',
    alignItems: 'center',
  },
  warningIcon: {
    marginTop: 17,
    marginBottom: 19,
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
})
