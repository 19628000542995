import React from 'react'
import { StyleSheet, View, ScrollView } from 'react-native'

// constants
import { isWeb } from 'app/src/constants/platform'
import { black } from 'app/src/constants/colors'

// components
import { Text } from 'app/src/components/basics'
import { Header } from 'app/src/components/advanced/header'
import { TabBar } from 'app/src/components/advanced/tab-bar'

type ContainerProps = {}

type Props = {}

export const RegisterScreen: React.FC<ContainerProps> = () => {
  return <UI />
}

const UI: React.FC<Props & ContainerProps> = () => {
  return (
    <View style={styles.container}>
      {isWeb && <Header />}
      <ScrollView style={styles.scrollContainer} contentContainerStyle={styles.scrollContents}>
        <Text type={'xlarge'} style={styles.pageName}>
          寄付の比率を決めてください
        </Text>
      </ScrollView>
      {/* {isWeb && <TabBar />} */}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  },
  scrollContainer: {
    flex: 1,
  },
  scrollContents: {
    justifyContent: 'center',
  },
  pageName: {
    fontSize: 16,
    color: black,
    marginTop: 109,
    marginBottom: 40,
    textAlign: 'center',
  },
})
