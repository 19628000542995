import { Config } from './config'

export const firebaseConfig = {
  apiKey: Config(process.env.APP_ENV)?.FIREBASE_API_KEY,
  authDomain: Config(process.env.APP_ENV)?.FIREBASE_AUTH_DOMAIN,
  projectId: Config(process.env.APP_ENV)?.FIREBASE_PROJECT_ID,
  storageBucket: Config(process.env.APP_ENV)?.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: Config(process.env.APP_ENV)?.FIREBASE_MESSAGING_SENDER_ID,
  appId: Config(process.env.APP_ENV)?.FIREBASE_APP_ID,
}
