import React from 'react'
import { StyleSheet, ImageBackground, TouchableOpacity, Text, StyleProp, TextStyle } from 'react-native'

// libs
import { images } from 'app/src/libs/images'

// constants
import { white, black } from 'app/src/constants/colors'

type Props = {
  buttonType?: number
  style?: StyleProp<TextStyle>
  textStyle?: StyleProp<TextStyle>
  onPress: () => void
}

export const Button: React.FC<Props> = ({ buttonType = 1, style, textStyle, onPress, children }) => {
  const buttonTextStyle = () => {
    if (buttonType === 1) return styles.btnType1Text
    if (buttonType === 2) return styles.btnType2Text
    if (buttonType === 3) return styles.btnType3Text
    if (buttonType === 4) return styles.btnType4Text
    if (buttonType === 5) return styles.btnType5Text
    if (buttonType === 6) return styles.btnType6Text
    if (buttonType === 7) return styles.btnType7Text
  }

  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <ImageBackground style={[styles.btn, style]} source={images[`btnType${buttonType}`]} imageStyle={{ resizeMode: 'cover' }}>
        <Text style={[styles.btnText, buttonTextStyle(), textStyle]}>{children}</Text>
      </ImageBackground>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  btn: {
    width: 260,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnText: {
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  btnType1Text: {
    color: white,
  },
  btnType2Text: {
    color: white,
  },
  btnType3Text: {
    color: white,
  },
  btnType4Text: {
    color: black,
  },
  btnType5Text: {
    color: black,
  },
  btnType6Text: {
    color: black,
  },
  btnType7Text: {
    color: '#dedede',
  },
})
