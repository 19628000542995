import React, { useState, useEffect } from 'react'
import { StyleSheet, View, Image, ActivityIndicator } from 'react-native'
import firebase from 'firebase/app'
import * as functions from 'firebase-functions'
import { FirebaseApp } from './src/components/apps/firebase'
import { firebaseConfig } from './src/components/apps/firebase-config'
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements
} from '@stripe/react-stripe-js';

import * as SplashScreen from 'expo-splash-screen'
import * as Font from 'expo-font'
import { Asset } from 'expo-asset'
import { activateKeepAwake } from 'expo-keep-awake'

// libs
import { Config } from './src/components/apps/config'

// contexts
import { LayoutProvider } from './src/contexts/layout'

// libs
import { images } from './src/libs/images'
import { fonts } from './src/libs/fonts'
import { HeadTag } from 'app/src/components/advanced/head-tag'

// constants
import { isWeb } from './src/constants/platform'
import { windowWidth } from './src/constants/layout'
import { primaryColor, white } from './src/constants/colors'

// components
import { Router } from './src/components/apps/router'

console.log('version v6_dev')
console.log('APP', process.env.APP_ENV)
console.log('firebaseConfig', firebaseConfig)
firebase.initializeApp(firebaseConfig)

const width = windowWidth > 950 ? 950 : '100%'

const App: React.FC = () => {
  // console.log('functions.config().stripe.secret_key', functions.config().stripe.secret_key)
  const [isSplashComplete, setIsSplashComplete] = useState(false)
  const [isLoadingComplete, setIsLoadingComplete] = useState(false)

  useEffect(() => {
    ; (async () => {
      try {
        await Asset.loadAsync(require('./src/assets/splash.png'))
        await SplashScreen.preventAutoHideAsync()
        setIsSplashComplete(true)
      } catch {
        setIsSplashComplete(true)
      }
    })()
  }, [])

  const _loadResourcesAsync = async () => {
    SplashScreen.hideAsync()
    try {
      if (!isWeb) {
        await Asset.loadAsync(Object.keys(images).map(key => images[key]))
      }
      await Font.loadAsync(fonts)

      // Analytics.init(Constants.deviceId || 'expo client')
      if (__DEV__) {
        activateKeepAwake()
      }
      setIsLoadingComplete(true)
    } catch {
      setIsLoadingComplete(true)
    }
  }

  if (!isLoadingComplete || !isSplashComplete) {
    return (
      <View style={{ flex: 1, backgroundColor: white, alignItems: 'center', justifyContent: 'center' }}>
        <Image source={require('./src/assets/splash.png')} onLoad={_loadResourcesAsync} style={[{ width, height: '100%' }]} />
        <ActivityIndicator style={styles.loading} size="large" color={primaryColor} />
      </View>
    )
  }
  const stripePromise = loadStripe(`${Config(process.env.APP_ENV)?.STRIPE_PUBLIC_KEY}`);
  return (
    <FirebaseApp>
      <Elements stripe={stripePromise}>
        <LayoutProvider>
          <HeadTag />
          <Router />
        </LayoutProvider>
      </Elements>
    </FirebaseApp>
  )
}

export default App

const styles = StyleSheet.create({
  loading: {
    margin: '0 auto',
    position: 'absolute',
    top: 120,
    width,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 10000,
  },
})
