import React, { useState, useEffect } from 'react'
import { StyleSheet, View, ImageBackground, TouchableOpacity } from 'react-native'
import * as ImagePicker from 'expo-image-picker'
import firebase from 'firebase/app'
// app datepicker
import DateTimePicker from '@react-native-community/datetimepicker'
// web datepicker
import DatePicker from 'react-mobile-datepicker'
import moment from 'moment'

//libs
import { images } from 'app/src/libs/images'
import { fetchUserInfo } from 'app/src/libs/firebase'
import { goToSignin, goToSignupFinish, goToSettingList } from 'app/src/libs/screen-transition'
import { isExistsNickname, firebaseAuthError } from 'app/src/libs/firebase'

// hooks
import { useNavigation } from 'app/src/hooks/use-navigation'

// constants
import { isWeb } from 'app/src/constants/platform'
import { black, lightGray } from 'app/src/constants/colors'

//components
import { TextInput } from 'app/src/components/basics/text-input'
import { Header } from 'app/src/components/advanced/header'
import { Footer } from 'app/src/components/advanced/footer'
import { TabBar } from 'app/src/components/advanced/tab-bar'
import { UserIconEdit } from 'app/src/components/advanced/user-icon-edit'
import { Text } from 'app/src/components/basics/text'
import { Button } from 'app/src/components/basics/button'
import { SelectBox } from 'app/src/components/basics/selectbox'

type ContainerProps = {}

type Props = {
  userInfo: any
  imageUrl: string
  userName: string
  name: string
  birthday: string
  visibleBirthdayModal: boolean
  gender: string
  email: string
  password: string
  passwordValidation: string
  confirmationPassword: string
  confirmationPasswordValidation: string
  currentPassword: string
  loading: boolean
  setUserName: (userName: string) => void
  setName: (name: string) => void
  setBirthday: (date: Date) => void
  setVisibleBirthdayModal: (visibleBirthdayModal: boolean) => void
  setGender: (gender: string) => void
  setEmail: (email: string) => void
  userIconUpload: () => void
  onChangePassword: (value: string) => void
  onChangeConfirmationPassword: (value: string) => void
  setCurrentPassword: (currentPassword: string) => void
  onUpdate: () => void
}

export const AccountSettingScreen: React.FC<ContainerProps> = () => {
  const navigation = useNavigation()

  const [uid, setUid] = useState<string>('')
  const [imageUrl, setImageUrl] = useState<string>('')
  const [userInfo, setUserInfo] = useState<any>()
  const [userName, setUserName] = useState<string>('')
  const [name, setName] = useState('')
  const [birthday, setBirthday] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [gender, setGender] = useState('')
  const [password, setPassword] = useState('')
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState<string>('')
  const [passwordValidation, setPasswordValidation] = useState('')
  const [confirmationPasswordValidation, setConfirmationPasswordValidation] = useState('')
  const [visibleBirthdayModal, setVisibleBirthdayModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const _fetchUserInfo = async (uid: string) => {
    const userInfo: any = await fetchUserInfo(uid)
    const currentEmail = firebase.auth().currentUser?.email

    setUid(uid)
    setUserInfo(userInfo)
    setImageUrl(userInfo.imageUrl)
    setUserName(userInfo.nickname)
    userInfo.name && setName(userInfo.name)
    userInfo.birthday && setBirthday(userInfo.birthday)
    userInfo.gender && setGender(userInfo.gender)
    currentEmail && setEmail(currentEmail)
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      await firebase.auth().onAuthStateChanged(user => {
        if (user) {
          _fetchUserInfo(user.uid)
        } else {
          goToSignin(navigation)
        }
      })
    }
    fetchUserInfo()
    ;(async () => {
      if (!isWeb) {
        const { status } = await ImagePicker.requestCameraRollPermissionsAsync()
        if (status !== 'granted') {
          alert('権限を設定してください。')
        }
      }
    })()
  }, [])

  const _setBirthday = (value: Date) => {
    setBirthday(moment(value).format('YYYY/MM/DD'))
    setVisibleBirthdayModal(false)
  }

  const _onChangePassword = (value: string) => {
    setPassword(value)
    setPasswordValidation(validPassword(value))
  }
  const validPassword = (password: string): string => {
    const regex = new RegExp(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/)
    return !regex.test(password) ? 'A~Z、a~z、0~9を8文字以上で入力してください。' : ''
  }

  const _onChangeConfirmationPassword = (value: string) => {
    setConfirmationPassword(value)
    setConfirmationPasswordValidation(validConfirmationPassword(password, value))
  }
  const validConfirmationPassword = (password: string, confirmationPassword: string): string => {
    return password !== confirmationPassword ? 'パスワードが一致しません。' : ''
  }

  const errorAlert = (errorMessage: string) => {
    setLoading(false)
    alert(errorMessage)
  }

  const _onUpdate = async () => {
    setLoading(true)
    const user = firebase.auth().currentUser
    const currentEmail = user?.email

    if (!userName || !email) return errorAlert('ユーザー名とメールアドレスは必須項目です。')
    if (await isExistsNickname(userName, userInfo.id)) return errorAlert('既に存在するユーザー名です。')
    if (password !== '' && validPassword(password) !== '') return errorAlert('パスワードはA~Z、a~z、0~9sを8文字以上で入力してください。')
    if (password !== confirmationPassword) return errorAlert('パスワードが確認と一致していません。')
    if ((email !== currentEmail || password) && !currentPassword)
      return errorAlert('メールアドレスまたはパスワードを変更する場合、現在のパスワードを入力する必要があります。')

    await firebase
      .firestore()
      .collection('users')
      .doc(userInfo.id)
      .update({
        nickname: userName,
        imageUrl,
        name,
        birthday,
        gender,
      })
      .then(() => {
        alert('アカウント情報を保存しました。')
      })

    if (email !== currentEmail || password) {
      const credential = currentEmail && firebase.auth.EmailAuthProvider.credential(currentEmail, currentPassword)
      // ユーザーの再認証
      if (credential) {
        user
          ?.reauthenticateWithCredential(credential)
          .then(() => {
            password ? updatePasswordAndEmail(currentEmail) : updateEmail()
          })
          .catch(error => {
            setLoading(false)
            console.log('error:', error)
            alert(`パスワード、メールアドレスを変更できませんでした。${firebaseAuthError(error)}`)
          })
      }
    } else {
      goToSettingList(navigation)
    }
  }

  const updatePasswordAndEmail = currentEmail => {
    firebase
      .auth()
      .currentUser?.updatePassword(password)
      .then(() => {
        alert('パスワードを変更しました。')
        // メールアドレスの変更または設定ページへ遷移
        email !== currentEmail ? updateEmail() : goToSettingList(navigation)
      })
      .catch(error => {
        setLoading(false)
        console.log('error:', error)
        email !== currentEmail ? alert('メールアドレスとパスワードを変更できませんでした。') : alert('パスワードを変更できませんでした。')
      })
  }

  const updateEmail = () => {
    firebase
      .auth()
      .currentUser?.updateEmail(email)
      .then(() => {
        firebase
          .firestore()
          .collection('users')
          .doc(userInfo.id)
          .update({
            email,
          })
        const actionCodeSettings = {
          url: `http://${window.location.host}/mypage`,
          handleCodeInApp: true,
        }
        firebase
          .auth()
          .currentUser?.sendEmailVerification(actionCodeSettings)
          .then(() => {
            goToSignupFinish(navigation)
          })
      })
      .catch(error => {
        setLoading(false)
        console.log('error:', error)
        alert(`メールアドレスを変更できませんでした。${firebaseAuthError(error)}`)
      })
  }

  const _userIconUpload = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    })

    if (!result.cancelled) {
      const localUri = await fetch(result.uri)
      //blobを取得
      const localBlob = await localUri.blob()
      // success
      firebase
        .storage()
        .ref()
        .child(`profile/${uid}.jpeg`)
        .put(localBlob)
        .then(snapshot => {
          snapshot.ref.getDownloadURL().then(url => {
            setImageUrl(url)
          })
        })
        .catch(error => {
          console.log('error', error)
        })
    }
  }

  return (
    <UI
      userInfo={userInfo}
      imageUrl={imageUrl}
      userName={userName}
      name={name}
      birthday={birthday}
      visibleBirthdayModal={visibleBirthdayModal}
      gender={gender}
      email={email}
      password={password}
      passwordValidation={passwordValidation}
      confirmationPassword={confirmationPassword}
      confirmationPasswordValidation={confirmationPasswordValidation}
      currentPassword={currentPassword}
      loading={loading}
      setUserName={setUserName}
      setName={setName}
      setVisibleBirthdayModal={setVisibleBirthdayModal}
      setGender={setGender}
      setEmail={setEmail}
      setBirthday={_setBirthday}
      userIconUpload={_userIconUpload}
      onChangePassword={_onChangePassword}
      onChangeConfirmationPassword={_onChangeConfirmationPassword}
      setCurrentPassword={setCurrentPassword}
      onUpdate={_onUpdate}
    />
  )
}

const UI: React.FC<Props & ContainerProps> = ({
  userInfo,
  imageUrl,
  userName,
  name,
  birthday,
  visibleBirthdayModal,
  gender,
  email,
  password,
  passwordValidation,
  confirmationPassword,
  confirmationPasswordValidation,
  currentPassword,
  loading,
  setUserName,
  setName,
  setVisibleBirthdayModal,
  setGender,
  setEmail,
  setBirthday,
  userIconUpload,
  onChangePassword,
  onChangeConfirmationPassword,
  setCurrentPassword,
  onUpdate,
}) => {
  return (
    <>
      {isWeb && <Header />}
      <View style={styles.container}>
        <View style={styles.backgroundImageContent}>
          <ImageBackground style={styles.backgroundImage} source={{ uri: imageUrl }} blurRadius={3}>
            <UserIconEdit
              isShowUserName={false}
              userInfo={userInfo}
              preImageUrl={imageUrl}
              editIconImage={'btnCamera'}
              onPress={() => userIconUpload()}
            />
            <View style={styles.translucence}>
              <TextInput label={'ユーザー名'} onChangeText={text => setUserName(text)} value={userName} translucentGrayBackground />
            </View>
          </ImageBackground>
        </View>
        <View style={styles.input}>
          <TextInput label={'氏名'} placeholder={'山田　太郎'} onChangeText={text => setName(text)} value={name} />
        </View>
        {isWeb && (
          <TouchableOpacity style={styles.birthdayInput} onPress={() => setVisibleBirthdayModal(true)}>
            <ImageBackground style={styles.inputImage} source={images['input']} resizeMode={'stretch'}>
              <View style={styles.labelWrapper}>
                <Text type={'small'} style={styles.birthdayLabel}>
                  生年月日
                </Text>
              </View>
              <View style={styles.birthdayTextWrapper}>
                <Text>{birthday ? birthday : '選択してください'}</Text>
              </View>
            </ImageBackground>
          </TouchableOpacity>
        )}
        <View style={styles.input}>
          <SelectBox
            label={'性別'}
            value={gender}
            onValueChange={text => setGender(text)}
            placeholder={{ label: '選択してください', value: undefined }}
            items={[
              { label: '女性', value: '女性' },
              { label: '男性', value: '男性' },
              { label: 'その他', value: 'その他' },
            ]}
          />
        </View>
        <View style={styles.line} />
        <View style={styles.wrapper}>
          <TextInput label={'メールアドレス'} onChangeText={text => setEmail(text)} value={email} keyboardType={'email-address'} />
        </View>
        <View style={styles.input}>
          <TextInput
            label={'現在のパスワード入力'}
            onChangeText={text => setCurrentPassword(text)}
            value={currentPassword}
            placeholder={'変更する場合は入力してください'}
            secureTextEntry
            translucentGrayBackground
          />
        </View>
        <View style={styles.input}>
          <TextInput
            label={'変更パスワード入力'}
            onChangeText={text => onChangePassword(text)}
            value={password}
            validation={passwordValidation}
            placeholder={'変更する場合は入力してください'}
            secureTextEntry
            translucentGrayBackground
          />
        </View>
        <Text type={'small'} style={styles.label}>
          A~Z、a~z、0~9を8文字以上
        </Text>
        <View style={styles.input}>
          <TextInput
            label={'変更パスワード確認'}
            onChangeText={text => onChangeConfirmationPassword(text)}
            value={confirmationPassword}
            validation={confirmationPasswordValidation}
            placeholder={'変更する場合は入力してください'}
            secureTextEntry
            translucentGrayBackground
          />
        </View>
        <View style={styles.btnWrapper}>
          <Button buttonType={3} onPress={() => onUpdate()}>
            保存する
          </Button>
        </View>
        <Footer />
      </View>
      {/* {isWeb && <TabBar />} */}
      {isWeb && visibleBirthdayModal && (
        <>
          <TouchableOpacity style={styles.datePickerOverlay} onPress={() => setVisibleBirthdayModal(false)} />
          <View style={styles.datePicker}>
            <DatePicker
              isOpen={visibleBirthdayModal}
              confirmText={'決定'}
              cancelText={'キャンセル'}
              min={new Date(1930, 0, 1)}
              onSelect={v => setBirthday(v)}
              onCancel={() => setVisibleBirthdayModal(false)}
              isPopup={false}
            />
          </View>
        </>
      )}
      {loading && <View style={styles.loading} />}
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 48,
    marginBottom: 74,
  },
  input: {
    width: '100%',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 7,
  },
  line: {
    height: 6,
    width: '100%',
    backgroundColor: '#F4F4F4',
    marginTop: 33,
    marginBottom: 33,
  },
  wrapper: {
    width: '100%',
    alignItems: 'center',
    marginTop: 7,
    marginBottom: 44,
  },
  label: {
    marginVertical: 5,
    color: black,
  },
  btnWrapper: {
    marginVertical: 35,
  },
  backgroundImageContent: {
    width: '100%',
    height: 260,
    overflow: 'hidden',
    position: 'relative',
    marginBottom: 23,
    alignItems: 'center',
  },
  backgroundImage: {
    width: '110%',
    height: '100%',
    alignItems: 'center',
  },
  translucence: {
    width: '100%',
    alignItems: 'center',
    margin: 'auto',
    position: 'absolute',
    bottom: 0,
    paddingHorizontal: 31,
  },
  // 誕生日フォーム
  birthdayInput: {
    width: '100%',
    maxWidth: 310,
    marginHorizontal: 32,
    marginBottom: 20,
  },
  inputImage: {
    width: '100%',
    minHeight: 46,
  },
  labelWrapper: {
    marginBottom: 1,
    position: 'absolute',
    top: 5,
    left: 10,
  },
  birthdayLabel: {
    fontSize: 8,
    color: lightGray,
  },
  birthdayTextWrapper: {
    alignItems: 'center',
    paddingTop: 16,
  },
  datePicker: {
    position: isWeb ? 'fixed' : 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 1,
  },
  datePickerOverlay: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    height: '100%',
    width: '100%',
    top: 0,
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
})
